import { useEffect, useState } from 'react';
import { updatePackageVariantIds } from '@/lib/admin';
import { useToast } from '@/components/ui/use-toast';

export function UpdateVariantIds() {
  const [isUpdating, setIsUpdating] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const updateVariants = async () => {
      try {
        setIsUpdating(true);
        await updatePackageVariantIds();
        toast({
          title: 'Success',
          description: 'Successfully updated package variant IDs',
        });
      } catch (error) {
        console.error('Error updating variant IDs:', error);
        toast({
          title: 'Error',
          description: 'Failed to update package variant IDs',
          variant: 'destructive',
        });
      } finally {
        setIsUpdating(false);
      }
    };

    updateVariants();
  }, []);

  return null; // This component doesn't render anything
}
