import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ValidationReport } from '@/components/validation/ValidationReport';
import { ValidationResult } from '@/lib/types';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';

export function ValidationDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [validation, setValidation] = useState<ValidationResult | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      toast({
        title: "Error",
        description: "Invalid validation ID",
        variant: "destructive",
      });
      navigate('/history');
      return;
    }
    loadValidation(id);
  }, [id]);

  const loadValidation = async (validationId: string) => {
    try {
      if (process.env.NODE_ENV === 'development') {
        // Load validation from localStorage in development
        const history = JSON.parse(localStorage.getItem('validationHistory') || '[]');
        const result = history.find((item: ValidationResult) => item.id === validationId);
        
        if (!result) {
          throw new Error('Validation not found');
        }

        setValidation(result);
      } else {
        // Load validation from Supabase in production
        const { data, error } = await supabase
          .from('email_validations')
          .select('*')
          .eq('id', validationId)
          .single();

        if (error) throw error;

        if (!data) {
          throw new Error('Validation not found');
        }

        // Transform the data to match ValidationResult type
        const result: ValidationResult = {
          id: data.id,
          date: new Date(data.created_at),
          fileName: 'upload.csv',
          totalEmails: data.total_emails,
          valid: data.valid_emails || [],
          invalid: data.invalid_emails || [],
          risky: data.risky_emails || [],
          disposable: [],
          spamTraps: [],
          creditsUsed: data.total_emails,
          status: 'completed' as const
        };

        setValidation(result);
      }
    } catch (error) {
      console.error('Error loading validation:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to load validation details",
        variant: "destructive",
      });
      navigate('/history');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-4">
        <Button
          variant="ghost"
          className="flex items-center text-muted-foreground hover:text-foreground w-fit"
          onClick={() => navigate('/history')}
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to History
        </Button>
        
      </div>

      {validation ? (
        <ValidationReport result={validation} isLoading={loading} />
      ) : (
        <div className="flex flex-col items-center justify-center min-h-[200px] space-y-4">
          <div className="text-center space-y-2">
            <p className="text-sm sm:text-base text-muted-foreground">
              No validation details found
            </p>
            <Button 
              variant="outline" 
              onClick={() => navigate('/history')}
              className="w-full sm:w-auto"
            >
              Return to History
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}