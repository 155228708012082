import { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  LayoutDashboard,
  History,
  CreditCard,
  Settings,
  ChevronLeft,
  ChevronRight,
  Upload,
  Code,
  Mail,
  LucideIcon,
  Workflow
} from 'lucide-react';

const menuItems = [
  { icon: LayoutDashboard, label: 'Dashboard', path: '/dashboard' },
  {
    icon: Upload,
    label: 'Validate Emails',
    path: '/validate',
    subItems: [
      { label: 'Cleaning History', path: '/history' }
    ]
  },
  {
    icon: Mail,
    label: 'Email Assistant',
    path: '/email-assistant',
    subItems: [
      { label: 'History', path: '/email-history' }
    ]
  },
  { icon: Workflow, label: 'Campaign Integrations', path: '/email-marketing' },
  { icon: CreditCard, label: 'Credits', path: '/credits' },
  { icon: History, label: 'Payment History', path: '/payment-history' },
  { icon: Code, label: 'API Integration', path: '/api' },
  { icon: Settings, label: 'Settings', path: '/settings' },
  
];

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

interface NavItemProps {
  to: string;
  icon?: LucideIcon;
  children: React.ReactNode;
  onClick?: () => void;
  subItems?: { label: string; path: string; }[];
  collapsed?: boolean;
}

function NavItem({ to, icon: Icon, children, onClick, subItems, collapsed }: NavItemProps) {
  const location = useLocation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const isActive = location.pathname === to || (subItems?.some(item => location.pathname === item.path));
  const hasSubItems = subItems && subItems.length > 0;

  const handleClick = (e: React.MouseEvent) => {
    if (hasSubItems) {
      setIsSubMenuOpen(!isSubMenuOpen);
    }
    if (!hasSubItems || e.target === e.currentTarget) {
      onClick?.();
    }
  };

  return (
    <div>
      <div
        onClick={handleClick}
        className={cn(
          'flex items-center py-2 px-3 rounded-lg text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground cursor-pointer',
          isActive ? 'bg-accent text-accent-foreground' : 'text-muted-foreground'
        )}
      >
        <Link
          to={to}
          className="flex items-center flex-1"
          onClick={(e) => {
            e.stopPropagation();
            if (!hasSubItems) {
              onClick?.();
            }
          }}
        >
          {Icon && <Icon className="h-4 w-4" />}
          {!collapsed && <span className="ml-2">{children}</span>}
        </Link>
        {hasSubItems && !collapsed && (
          <ChevronRight className={cn('h-4 w-4 ml-2 transition-transform', isSubMenuOpen && 'rotate-90')} />
        )}
      </div>
      {hasSubItems && isSubMenuOpen && !collapsed && (
        <div className="ml-6 mt-1 space-y-1">
          {subItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              onClick={onClick}
              className={cn(
                'flex items-center py-2 px-3 rounded-lg text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground',
                location.pathname === item.path ? 'bg-accent text-accent-foreground' : 'text-muted-foreground'
              )}
            >
              {item.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export function Sidebar({ isOpen, onClose }: SidebarProps) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      className={cn(
        'fixed inset-y-0 left-0 z-40 flex flex-col bg-background border-r transition-transform duration-200 ease-in-out',
        collapsed ? 'w-16' : 'w-64',
        !isOpen && '-translate-x-full md:translate-x-0'
      )}
    >
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setCollapsed(!collapsed)}
        className="absolute -right-4 top-4 h-8 w-8 rounded-full border bg-background md:flex"
      >
        {collapsed ? <ChevronRight className="h-4 w-4" /> : <ChevronLeft className="h-4 w-4" />}
      </Button>
      <nav className="flex-1 space-y-1 p-2 mt-20">
        {menuItems.map((item) => (
          <NavItem
            key={item.path}
            to={item.path}
            icon={item.icon}
            onClick={onClose}
            subItems={item.subItems}
            collapsed={collapsed}
          >
            {item.label}
          </NavItem>
        ))}
      </nav>
    </div>
  );
}