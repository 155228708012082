import { format } from 'date-fns';
import { FileText, Download, Eye, Upload } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useToast } from '@/hooks/use-toast';
import { getValidationHistory, downloadValidationReport } from '@/lib/api';
import { useState, useEffect } from 'react';
import { ValidationResult, ValidationFilters, ValidationStatus } from '@/lib/types';

type SortOption = NonNullable<ValidationFilters['sort']>;
type StatusOption = NonNullable<ValidationFilters['status']>;

export function ValidationHistory() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [history, setHistory] = useState<ValidationResult[]>([]);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<StatusOption>('all');
  const [sort, setSort] = useState<SortOption>('newest');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadHistory();
  }, [currentPage, search, status, sort]);

  const loadHistory = async () => {
    try {
      setLoading(true);
      const filters: ValidationFilters = {
        search: search || undefined,
        status: status || undefined,
        sort: sort || undefined,
        page: currentPage,
      };

      console.log('Fetching history with filters:', filters);
      const { results, pages, total } = await getValidationHistory(currentPage, filters);
      console.log('Received results:', results);
      
      if (results.length === 0 && currentPage > 1) {
        // If we get no results and we're not on the first page, go back to first page
        setCurrentPage(1);
        return;
      }

      setHistory(results);
      setTotalPages(pages);
    } catch (error) {
      console.error('Failed to load validation history:', error);
      toast({
        title: "Error",
        description: "Failed to load validation history",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = (value: string) => {
    setCurrentPage(1); // Reset to first page when changing filters
    setStatus(value as StatusOption);
  };

  const handleSortChange = (value: string) => {
    setCurrentPage(1); // Reset to first page when changing filters
    setSort(value as SortOption);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1); // Reset to first page when searching
    setSearch(e.target.value);
  };

  const handleDownload = async (id: string, fileName: string) => {
    try {
      const blob = await downloadValidationReport(id);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download validation report:', error);
      toast({
        title: "Download Failed",
        description: "Failed to download the validation report",
        variant: "destructive",
      });
    }
  };

  const handleViewDetails = (id: string) => {
    navigate(`/history/${id}`);
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row items-stretch sm:items-center justify-between gap-4">
        <div className="flex-1">
          <Input
            placeholder="Search validations..."
            className="w-full"
            type="search"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <Button 
          variant="default" 
          onClick={() => navigate('/validate')}
          className="w-full sm:w-auto"
        >
          <Upload className="mr-2 h-4 w-4" />
          New Validation
        </Button>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <Select value={status} onValueChange={handleStatusChange}>
          <SelectTrigger className="w-full sm:w-[150px]">
            <SelectValue placeholder="Filter by status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Status</SelectItem>
            <SelectItem value="completed">Completed</SelectItem>
            <SelectItem value="processing">Processing</SelectItem>
            <SelectItem value="failed">Failed</SelectItem>
          </SelectContent>
        </Select>
        <Select value={sort} onValueChange={handleSortChange}>
          <SelectTrigger className="w-full sm:w-[150px]">
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="newest">Newest First</SelectItem>
            <SelectItem value="oldest">Oldest First</SelectItem>
            <SelectItem value="largest">Largest Files</SelectItem>
            <SelectItem value="smallest">Smallest Files</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="overflow-x-auto -mx-4 sm:mx-0">
        <div className="min-w-full inline-block align-middle">
          <div className="border rounded-lg overflow-hidden">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead className="hidden sm:table-cell">File Name</TableHead>
                  <TableHead>Total</TableHead>
                  <TableHead className="hidden md:table-cell">Results</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center py-8">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : history.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center py-8">
                      No validation history found
                    </TableCell>
                  </TableRow>
                ) : (
                  history.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className="whitespace-nowrap">
                        {format(typeof item.date === 'string' ? new Date(item.date) : item.date, 'MMM d, yyyy')}
                      </TableCell>
                      <TableCell className="hidden sm:table-cell">
                        <div className="flex items-center space-x-2">
                          <FileText className="h-4 w-4 text-muted-foreground" />
                          <span className="truncate max-w-[200px]">
                            {item.fileName || 'Single Email Validation'}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>{item.totalEmails.toLocaleString()}</TableCell>
                      <TableCell className="hidden md:table-cell">
                        <div className="flex flex-wrap gap-1">
                          <Badge variant="secondary" className="bg-green-100 text-green-800 whitespace-nowrap">
                            {item.valid.length} valid
                          </Badge>
                          <Badge variant="secondary" className="bg-red-100 text-red-800 whitespace-nowrap">
                            {item.invalid.length} invalid
                          </Badge>
                          <Badge variant="secondary" className="bg-yellow-100 text-yellow-800 whitespace-nowrap">
                            {item.risky.length} risky
                          </Badge>
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex justify-end gap-1 sm:gap-2">
                          <Button
                            variant="ghost"
                            size="icon"
                            title="View Details"
                            onClick={() => handleViewDetails(item.id)}
                            className="h-8 w-8"
                          >
                            <Eye className="h-4 w-4" />
                            <span className="sr-only">View Details</span>
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            title="Download Report"
                            onClick={() => handleDownload(item.id, item.fileName || 'validation-report.csv')}
                            className="h-8 w-8"
                          >
                            <Download className="h-4 w-4" />
                            <span className="sr-only">Download Report</span>
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>

      {totalPages > 1 && (
        <div className="flex justify-center gap-2 mt-4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1 || loading}
          >
            Previous
          </Button>
          <span className="px-4 py-2">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages || loading}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}