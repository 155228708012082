import { useState } from 'react';
import { Mail, Loader2, Copy, History } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/lib/supabase';
import { Database } from '@/lib/database.types';
import { Link } from 'react-router-dom';

type AdminSettings = Database['public']['Tables']['admin_settings']['Row'];
type EmailHistoryInsert = Database['public']['Tables']['email_history']['Insert'];

interface Purpose {
  value: string;
  icon: string;
  title: string;
  description: string;
}

interface Tone {
  value: string;
  icon: string;
  title: string;
}

const purposes: Purpose[] = [
  { value: 'sales_pitch', icon: '💫', title: 'Sales Pitch', description: 'Compelling sales messages' },
  { value: 'networking', icon: '🌐', title: 'Networking', description: 'Professional networking' },
  { value: 'recommendation', icon: '⭐', title: 'Recommendation', description: 'Professional endorsements' },
  { value: 'announcement', icon: '📢', title: 'Announcement', description: 'Important updates' },
  { value: 'follow_up', icon: '🔄', title: 'Follow Up', description: 'Following up on previous interactions' },
  { value: 'introduction', icon: '👋', title: 'Introduction', description: 'Professional introductions' },
  { value: 'proposal', icon: '📝', title: 'Proposal', description: 'Business proposals' },
  { value: 'thank_you', icon: '🙏', title: 'Thank You', description: 'Gratitude messages' },
  { value: 'apology', icon: '🤝', title: 'Apology', description: 'Professional apologies' },
  { value: 'request', icon: '📋', title: 'Request', description: 'Making formal requests' }
];

const tones: Tone[] = [
  { value: 'professional', icon: '👔', title: 'Professional' },
  { value: 'friendly', icon: '😊', title: 'Friendly' },
  { value: 'formal', icon: '🎩', title: 'Formal' },
  { value: 'persuasive', icon: '🎯', title: 'Persuasive' },
  { value: 'confident', icon: '💪', title: 'Confident' },
  { value: 'empathetic', icon: '💝', title: 'Empathetic' },
  { value: 'urgent', icon: '⚡', title: 'Urgent' },
  { value: 'diplomatic', icon: '🤝', title: 'Diplomatic' },
  { value: 'enthusiastic', icon: '✨', title: 'Enthusiastic' },
  { value: 'respectful', icon: '🙌', title: 'Respectful' }
];

export const EmailAssistant: React.FC = () => {
  const { toast } = useToast();
  const [selectedPurpose, setSelectedPurpose] = useState('');
  const [selectedTone, setSelectedTone] = useState('');
  const [recipient, setRecipient] = useState('');
  const [keyPoints, setKeyPoints] = useState('');
  const [loading, setLoading] = useState(false);
  const [generatedEmail, setGeneratedEmail] = useState('');
  const [error, setError] = useState('');

  const generateEmail = async () => {
    if (!selectedPurpose || !recipient || !keyPoints || !selectedTone) {
      toast({
        title: "Missing Information",
        description: "Please fill in all required fields",
        variant: "destructive"
      });
      return;
    }

    try {
      setLoading(true);
      setError('');

      const { data: settings, error: settingsError } = await supabase
        .from('admin_settings')
        .select('*')
        .eq('key', 'gemini_api_key')
        .single();

      if (settingsError) {
        console.error('Settings error:', settingsError);
        throw new Error('Failed to retrieve API key');
      }

      if (!settings) {
        throw new Error('API key not found');
      }

      const apiKey = settings.value as string;

      const prompt = `Write a professional email with the following details:
Purpose: ${selectedPurpose}
Recipient: ${recipient}
Tone: ${selectedTone}
Key Points: ${keyPoints}`;

      const response = await fetch('https://generativelanguage.googleapis.com/v1/models/gemini-pro:generateContent?key=' + apiKey, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contents: [{
            parts: [{
              text: prompt
            }]
          }]
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate email');
      }

      const data = await response.json();
      if (!data.candidates?.[0]?.content?.parts?.[0]?.text) {
        throw new Error('Invalid response from API');
      }

      const generatedText = data.candidates[0].content.parts[0].text;
      setGeneratedEmail(generatedText);

      // Save to email history
      const { data: userData } = await supabase.auth.getUser();
      if (userData?.user?.id) {
        const emailHistoryData: Database['public']['Tables']['email_history']['Insert'] = {
          user_id: userData.user.id,
          purpose: selectedPurpose,
          recipient,
          tone: selectedTone,
          key_points: keyPoints,
          generated_email: generatedText,
        };

        const { error: insertError } = await supabase
          .from('email_history')
          .insert(emailHistoryData);

        if (insertError) {
          console.error('Error saving email history:', insertError);
        }
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(generatedEmail);
      toast({
        title: "Success",
        description: "Email copied to clipboard",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to copy email",
        variant: "destructive"
      });
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Email Assistant</h1>
        <Link to="/email-history">
          <Button variant="outline" className="gap-2">
            <History className="h-4 w-4" />
            View History
          </Button>
        </Link>
      </div>
      <div className="space-y-6">
        <div className="space-y-4">
          <label className="text-sm font-medium">Email Purpose</label>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {purposes.map((purpose) => (
              <div
                key={purpose.value}
                className={`cursor-pointer p-4 rounded-lg border-2 transition-all ${
                  selectedPurpose === purpose.value
                    ? 'border-primary bg-primary/5'
                    : 'border-border hover:border-primary'
                }`}
                onClick={() => setSelectedPurpose(purpose.value)}
              >
                <div className="flex items-start gap-3">
                  <span className="text-xl">{purpose.icon}</span>
                  <div>
                    <h3 className="font-medium text-sm">{purpose.title}</h3>
                    <p className="text-xs text-muted-foreground">{purpose.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Recipient's Name</label>
          <Input
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            placeholder="Enter recipient's name"
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Key Points</label>
          <Textarea
            value={keyPoints}
            onChange={(e) => setKeyPoints(e.target.value)}
            placeholder="Enter your key points here, one per line..."
            rows={4}
          />
        </div>

        <div className="space-y-4">
          <label className="text-sm font-medium">Email Tone</label>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-3">
            {tones.map((tone) => (
              <div
                key={tone.value}
                className={`cursor-pointer p-3 rounded-lg border-2 text-center transition-all ${
                  selectedTone === tone.value
                    ? 'border-primary bg-primary/5'
                    : 'border-border hover:border-primary'
                }`}
                onClick={() => setSelectedTone(tone.value)}
              >
                <div className="text-xl mb-1">{tone.icon}</div>
                <div className="text-xs font-medium">{tone.title}</div>
              </div>
            ))}
          </div>
        </div>

        <Button
          className="w-full"
          onClick={generateEmail}
          disabled={loading}
        >
          {loading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Generating...
            </>
          ) : (
            'Generate Email'
          )}
        </Button>

        {generatedEmail && (
          <div className="mt-6 space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium">Suggested Email Body</h3>
              <Button
                variant="outline"
                size="sm"
                onClick={copyToClipboard}
              >
                <Copy className="h-4 w-4 mr-2" />
                Copy
              </Button>
            </div>
            <div className="p-4 rounded-lg bg-muted whitespace-pre-wrap">
              {generatedEmail}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
