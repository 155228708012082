import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginUser, signInWithGoogle } from '@/lib/auth';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { motion, AnimatePresence } from 'framer-motion';

// Slider content
const sliderContent = [
  {
    title: "Your lists,\namplified",
    subtitle: "Email verification that helps you reach more customers.",
    image: "https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=2070"
  },
  {
    title: "Smart\nvalidation",
    subtitle: "Advanced algorithms ensure your email lists are clean and valid.",
    image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2015"
  },
  {
    title: "Results\nguaranteed",
    subtitle: "Improve deliverability and reach your target audience effectively.",
    image: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=2074"
  }
];

export function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Auto-advance slider
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prev) => (prev + 1) % sliderContent.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const { user } = await loginUser(email, password);
      
      // Store credits in localStorage for the header component
      if (user?.credits !== undefined) {
        localStorage.setItem('userCredits', user.credits.toString());
        
        toast({
          title: "Login successful",
          description: "Welcome back!",
        });
        
        // Get the return URL from location state or default to dashboard
        const returnUrl = location.state?.from || '/dashboard';
        navigate(returnUrl);
      } else {
        throw new Error('Invalid user data received');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: "Login failed",
        description: error instanceof Error 
          ? error.message 
          : "Invalid email or password",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error('Google login error:', error);
      toast({
        title: "Login failed",
        description: error instanceof Error 
          ? error.message 
          : "Failed to login with Google",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <Card className="w-full shadow-none bg-white border border-solid border-[#d0d5dd]/60 rounded-2xl">
        <div className="md:px-5">
          <CardHeader className="space-y-1 pb-6 mt-4">
            <CardTitle className="text-2xl font-bold text-center">Welcome back</CardTitle>
            <p className="text-center text-base text-muted-foreground">
              Enter your email below to sign in
            </p>
          </CardHeader>
          <CardContent className="space-y-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isLoading}
                  className="h-11 transition-all duration-200 focus:ring-2"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  disabled={isLoading}
                  className="h-11 transition-all duration-200 focus:ring-2"
                />
              </div>
              <Button 
                type="submit" 
                className="w-full h-11 mt-2 font-medium transition-all duration-200 hover:opacity-90"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Signing in...
                  </>
                ) : (
                  'Sign In'
                )}
              </Button>
            </form>

            <div className="flex justify-end">
              <Button
                type="button"
                variant="link"
                className="px-0 text-sm font-medium"
                onClick={() => navigate('/forgot-password')}
              >
                Forgot password?
              </Button>
            </div>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-white px-2 text-muted-foreground">
                  Or continue with
                </span>
              </div>
            </div>

            <Button
              variant="outline"
              type="button"
              className="w-full h-11"
              onClick={handleGoogleLogin}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <svg className="mr-2 h-4 w-4" viewBox="0 0 24 24">
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                </svg>
              )}
              Continue with Google
            </Button>
          </CardContent>
          <div className="px-8 pb-6 text-center">
            <span className="text-sm text-muted-foreground">
              Don't have an account?{' '}
              <Button
                type="button"
                variant="link"
                className="px-0 text-sm font-medium text-primary hover:text-primary/80"
                onClick={() => navigate('/register')}
              >
                Create account
              </Button>
            </span>
          </div>
        </div>
      </Card>
      <div className="flex justify-center mt-6">
        <a 
          href="https://emailbounce.org" 
          className="flex items-center text-sm text-muted-foreground hover:text-primary transition-colors"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" 
            height="16" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="mr-2"
          >
            <path d="m12 19-7-7 7-7"/>
            <path d="M19 12H5"/>
          </svg>
          Back to homepage
        </a>
      </div>

    </>
  );
}