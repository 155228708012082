import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/hooks/use-toast';
import { resetPassword } from '@/lib/auth';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertCircle, ArrowLeft, CheckCircle, Loader2 } from 'lucide-react';

export function ForgotPasswordForm() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    try {
      await resetPassword(email);
      setSuccess(true);
      toast({
        title: "Check your email",
        description: "We've sent you a password reset link.",
      });
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to send reset email';
      setError(message);
      toast({
        variant: "destructive",
        title: "Error",
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (success) {
    return (
      <>
        <Card className="w-full shadow-none bg-white border border-solid border-[#d0d5dd]/60 rounded-2xl">
          <div className="md:px-5">
            <CardHeader className="space-y-1 pb-6 mt-4">
              <div className="flex justify-center mb-4">
                <CheckCircle className="h-12 w-12 text-green-500" />
              </div>
              <CardTitle className="text-2xl font-bold text-center">Check Your Email</CardTitle>
              <p className="text-center text-base text-muted-foreground">
                We've sent password reset instructions to {email}
              </p>
            </CardHeader>
            <CardContent className="space-y-4">
              <Button
                type="button"
                className="w-full h-11 mt-2 font-medium transition-all duration-200 hover:opacity-90"
                onClick={() => navigate('/login')}
              >
                Back to Login
              </Button>
            </CardContent>
          </div>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card className="w-full shadow-none bg-white border border-solid border-[#d0d5dd]/60 rounded-2xl">
        <div className="md:px-5">
          <CardHeader className="space-y-1 pb-6 mt-4">
            <CardTitle className="text-2xl font-bold text-center">Forgot Password</CardTitle>
            <p className="text-center text-base text-muted-foreground">
              Enter your email address and we'll send you a link to reset your password
            </p>
          </CardHeader>
          <CardContent className="space-y-4">
            {error && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="h-11 transition-all duration-200 focus:ring-2"
                  disabled={isLoading}
                />
              </div>
              <Button
                type="submit"
                className="w-full h-11 mt-2 font-medium transition-all duration-200 hover:opacity-90"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Sending...
                  </>
                ) : (
                  'Send Reset Link'
                )}
              </Button>
            </form>
          </CardContent>
          <div className="px-8 pb-6 text-center">
            <span className="text-sm text-muted-foreground">
              Remember your password?{' '}
              <Button
                type="button"
                variant="link"
                className="px-0 text-sm font-medium text-primary hover:text-primary/80"
                onClick={() => navigate('/login')}
              >
                Back to login
              </Button>
            </span>
          </div>
        </div>
      </Card>
    </>
  );
}