import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Copy } from 'lucide-react';
import { toast } from '@/components/ui/use-toast';

interface CodeBlockProps {
  language: string;
  code: string;
}

function CodeBlock({ language, code }: CodeBlockProps) {
  const copyCode = () => {
    navigator.clipboard.writeText(code);
    toast({
      title: "Copied to clipboard",
      description: "The code has been copied to your clipboard.",
    });
  };

  return (
    <div className="relative">
      <pre className="rounded-lg bg-muted p-4 overflow-x-auto">
        <code className={`language-${language}`}>{code}</code>
      </pre>
      <Button
        variant="ghost"
        size="sm"
        className="absolute top-2 right-2"
        onClick={copyCode}
      >
        <Copy className="h-4 w-4" />
      </Button>
    </div>
  );
}

interface APIGuideProps {
  apiKey: string;
}

export function APIGuide({ apiKey }: APIGuideProps) {
  const curlExample = `curl -X POST \\
  https://app.emailbounce.org/api/v1/validate-email \\
  -H 'Authorization: Bearer ${apiKey}' \\
  -H 'Content-Type: application/json' \\
  -d '{"email": "test@example.com"}'`;

  const nodeExample = `const response = await fetch('https://app.emailbounce.org/api/v1/validate-email', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer ${apiKey}',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    email: 'test@example.com',
  }),
});

const result = await response.json();`;

  const pythonExample = `import requests

response = requests.post(
    'https://app.emailbounce.org/api/v1/validate-email',
    headers={
        'Authorization': 'Bearer ${apiKey}',
        'Content-Type': 'application/json',
    },
    json={
        'email': 'test@example.com',
    },
)

result = response.json()`;

  return (
    <div className="space-y-4 sm:space-y-6">
      <div className="space-y-1.5 sm:space-y-2">
        <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">API Documentation</h1>
        <p className="text-sm sm:text-base text-muted-foreground">
          Integrate email validation into your application using our REST API.
        </p>
      </div>

      <Card>
        <CardHeader className="space-y-1.5">
          <CardTitle>Authentication</CardTitle>
          <CardDescription>
            All API requests require authentication using your API key in the Authorization header.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-3">
            <p className="text-sm">Your API Key:</p>
            <div className="flex items-center gap-2">
              <code className="flex-1 relative rounded bg-muted px-3 py-2 font-mono text-sm">
                {apiKey}
              </code>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  navigator.clipboard.writeText(apiKey);
                  toast({
                    title: "API Key copied",
                    description: "Your API key has been copied to the clipboard.",
                  });
                }}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="space-y-1.5">
          <CardTitle>Endpoints</CardTitle>
          <CardDescription>
            Available API endpoints and their usage.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="single" className="space-y-4">
            <TabsList className="w-full sm:w-auto">
              <TabsTrigger value="single">Single Email</TabsTrigger>
              <TabsTrigger value="bulk">Bulk Validation</TabsTrigger>
            </TabsList>
            <TabsContent value="single">
              <div className="space-y-4">
                <h3 className="font-medium">POST /api/v1/validate-email</h3>
                <p className="text-sm text-muted-foreground">
                  Validate a single email address.
                </p>
                <Tabs defaultValue="curl" className="space-y-4">
                  <TabsList className="w-full sm:w-auto">
                    <TabsTrigger value="curl">cURL</TabsTrigger>
                    <TabsTrigger value="node">Node.js</TabsTrigger>
                    <TabsTrigger value="python">Python</TabsTrigger>
                  </TabsList>
                  <TabsContent value="curl">
                    <CodeBlock language="bash" code={curlExample} />
                  </TabsContent>
                  <TabsContent value="node">
                    <CodeBlock language="javascript" code={nodeExample} />
                  </TabsContent>
                  <TabsContent value="python">
                    <CodeBlock language="python" code={pythonExample} />
                  </TabsContent>
                </Tabs>
              </div>
            </TabsContent>
            <TabsContent value="bulk">
              <div className="space-y-4">
                <h3 className="font-medium">POST /api/v1/validate-email/bulk</h3>
                <p className="text-sm text-muted-foreground">
                  Validate multiple email addresses in a single request.
                </p>
                <CodeBlock
                  language="bash"
                  code={`curl -X POST \\
  https://app.emailbounce.org/api/v1/validate-email/bulk \\
  -H 'Authorization: Bearer ${apiKey}' \\
  -H 'Content-Type: application/json' \\
  -d '{"emails": ["test1@example.com", "test2@example.com"]}'`}
                />
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="space-y-1.5">
          <CardTitle>Response Format</CardTitle>
          <CardDescription>
            Example response from the API.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <CodeBlock
            language="json"
            code={`{
  "email": "test@example.com",
  "is_valid": true,
  "is_disposable": false,
  "is_role_account": false,
  "is_free_provider": true,
  "did_you_mean": null,
  "domain": "example.com",
  "domain_age_days": 10950,
  "smtp_check": true,
  "mx_records": true,
  "format_valid": true,
  "status": "valid",
  "validation_id": "val_123456789",
  "credits_used": 1
}`}
          />
        </CardContent>
      </Card>
    </div>
  );
}
