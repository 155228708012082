import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { Header } from '@/components/layout/Header';
import { Sidebar } from '@/components/layout/Sidebar';
import { Footer } from '@/components/layout/Footer';
import { LoginForm } from '@/components/auth/LoginForm';
import { RegisterForm } from '@/components/auth/RegisterForm';
import { ForgotPasswordForm } from '@/components/auth/ForgotPasswordForm';
import { Stats } from '@/components/dashboard/Stats';
import { UploadForm } from '@/components/validation/UploadForm';
import { ValidationHistory } from '@/components/history/ValidationHistory';
import { ValidationDetails } from '@/components/history/ValidationDetails';
import { CreditPackages } from '@/components/credits/CreditPackages';
import { PaymentHistory } from '@/pages/payment-history';
import { PaymentInfo } from '@/components/credits/PaymentInfo';
import { SettingsForm } from '@/components/settings/SettingsForm';
import { ApiDashboard } from '@/components/api/ApiDashboard';
import { ThemeProvider } from '@/components/theme-provider';
import { ProtectedRoute } from '@/components/auth/ProtectedRoute';
import { ErrorBoundary } from 'react-error-boundary';
import { AdminLoginForm } from './components/admin/auth/AdminLoginForm';
import { AdminRoute } from './components/admin/AdminRoute';
import { AdminLayout } from './components/admin/AdminLayout';
import { AdminDashboard } from '@/components/admin/Dashboard';
import { UserManagement } from '@/components/admin/users/UserManagement';
import { CreditPackageManagement } from '@/components/admin/packages/CreditPackageManagement';
import { PaymentGatewayManagement } from '@/components/admin/payments/PaymentGatewayManagement';
import { AdminSettings } from './components/admin/settings/AdminSettings';
import { AdminValidationHistory } from '@/components/admin/validation/AdminValidationHistory';
import { InvoiceManagement } from '@/components/admin/invoices/InvoiceManagement';
import { CookieConsent } from '@/components/CookieConsent';
import { useState } from 'react';
import { ValidationResults } from '@/components/validation/ValidationResults';
import APIPage from '@/pages/api';
import { AuthSlider } from '@/components/auth/AuthSlider';
import { AuthLayout } from '@/components/auth/AuthLayout';
import { AuthContainer } from '@/components/auth/AuthContainer';
import { AuthCallback } from '@/components/auth/AuthCallback';
import { EmailAssistant } from '@/pages/email-assistant';
import GeminiSettings from "@/pages/admin/gemini-settings"; // Default import
import EmailHistoryPage from '@/pages/email-history'; // New import
import EmailMarketingPage from '@/pages/email-marketing'; // New import

function AppLayout({ children }: { children: React.ReactNode }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  // Function to determine footer spacing based on route
  const getFooterSpacing = () => {
    const path = location.pathname;
    
    // Add specific spacing for different routes
    switch (true) {
      case path === '/dashboard':
        return 'mt-16';
      case path === '/validate':
      case path.startsWith('/validation/'):
        return 'mt-20';
      case path === '/credits':
      case path === '/pricing':
        return 'mt-24';
      case path === '/settings':
        return 'mt-12';
      default:
        return 'mt-16'; // Default spacing
    }
  };

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <div className="flex min-h-screen flex-col">
        <Header onMenuClick={() => setIsSidebarOpen(!isSidebarOpen)} />
        <div className="flex flex-1">
          <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
          <main className="flex-1 w-full transition-all duration-200 ease-in-out md:pl-64">
            <div className="container py-4 px-4">
              {children}
            </div>
          </main>
        </div>
        <div className={`md:pl-64 ${getFooterSpacing()}`}>
          <Footer />
        </div>
      </div>
    </ErrorBoundary>
  );
}

function App() {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Router>
        <Routes>
          {/* Auth Routes - Always Light Mode */}
          <Route path="/login" element={
            <AuthContainer>
              <LoginForm />
            </AuthContainer>
          } />
          <Route path="/register" element={
            <AuthContainer>
              <RegisterForm />
            </AuthContainer>
          } />
          <Route path="/forgot-password" element={
            <AuthContainer>
              <ForgotPasswordForm />
            </AuthContainer>
          } />
          <Route path="/auth/callback" element={
            <AuthContainer>
              <AuthCallback />
            </AuthContainer>
          } />

          {/* All other routes wrapped in ThemeProvider */}
          <Route element={
            <ThemeProvider defaultTheme="light" storageKey="theme-preference">
              <Outlet />
            </ThemeProvider>
          }>
            {/* Admin Routes */}
            <Route path="/admin/login" element={
              <div className="flex items-center justify-center min-h-screen" 
                style={{ backgroundColor: 'hsl(216, 44%, 25%)' }}>
                <div className="w-full max-w-[400px] space-y-6">
                  <div className="flex justify-center">
                    <img
                      src="https://emailbounce.org/wp-content/uploads/2024/11/Logo_emailbounce_org_dark.svg"
                      alt="EmailBounce Admin"
                      className="h-14 mb-2"
                    />
                  </div>
                  <AdminLoginForm />
                </div>
              </div>
            } />
            <Route 
              path="/admin" 
              element={
                <AdminRoute>
                  <AdminLayout>
                    <Outlet />
                  </AdminLayout>
                </AdminRoute>
              }
            >
              <Route index element={<AdminDashboard />} />
              <Route path="users" element={<UserManagement />} />
              <Route path="packages" element={<CreditPackageManagement />} />
              <Route path="payments" element={<PaymentGatewayManagement />} />
              <Route path="validations" element={<AdminValidationHistory />} />
              <Route path="invoices" element={<InvoiceManagement />} />
              <Route path="analytics" element={<AdminDashboard />} />
              <Route path="settings" element={<AdminSettings />} />
              <Route path="gemini-settings" element={<GeminiSettings />} />
            </Route>

            {/* Protected App Routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <AppLayout>
                    <Outlet />
                  </AppLayout>
                </ProtectedRoute>
              }
            >
              <Route index element={<Navigate to="/dashboard" replace />} />
              <Route path="dashboard" element={<Stats />} />
              <Route path="validate" element={<UploadForm />} />
              <Route path="email-assistant" element={
                <div className="space-y-4 pt-2">
                  <h1 className="hidden text-3xl font-bold tracking-tight md:block">Advanced Email Assistant</h1>
                <EmailAssistant />
                </div>} />
              <Route path="email-history" element={<EmailHistoryPage />} />
              <Route path="history" element={<ValidationHistory />} />
              <Route path="history/:id" element={<ValidationDetails />} />
              <Route path="validation/results" element={<ValidationResults />} />
              <Route path="api" element={<APIPage />} />
              <Route path="credits" element={
                <div className="space-y-4 pt-2">
                  <h1 className="hidden text-3xl font-bold tracking-tight md:block">Purchase Credits</h1>
                  <CreditPackages />
                  <h2 className="text-2xl font-bold tracking-tight">Payment Information</h2>
                  <PaymentInfo />
                </div>
              } />
              <Route path="payment-history" element={
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Payment History</h1>
                  </div>
                  <PaymentHistory />
                </div>
              } />
              <Route path="settings" element={
                <div className="space-y-4 pt-2">
                  <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Settings</h1>
                  <SettingsForm />
                </div>
              } />
              <Route path="email-marketing" element={
                
                  <EmailMarketingPage />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
        <Toaster />
        <CookieConsent />
      </Router>
    </ErrorBoundary>
  );
}

export default App;