import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, Download, CreditCard, Bitcoin, Clock } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { getPaymentHistory, downloadInvoice } from '@/api/payments';
import type { PaymentWithPackage } from '@/api/payments';
import { useAuth } from '@/lib/hooks/useAuth';
import { InvoiceDialog } from '@/components/payments/InvoiceDialog';
import { RetryPaymentDialog } from '@/components/payments/RetryPaymentDialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

// Helper function to format date
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return {
    date: new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(date),
    time: new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(date),
  };
};

const getPaymentMethodIcon = (method: string) => {
  switch (method.toLowerCase()) {
    case 'crypto':
      return <Bitcoin className="h-4 w-4" />;
    case 'credit_card':
      return <CreditCard className="h-4 w-4" />;
    default:
      return null;
  }
};

const formatAmount = (amount: number, currency: string) => {
  // Extract base currency from network-specific currency codes
  const baseCurrency = currency.split('_')[0];
  
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: baseCurrency.toUpperCase(),
    }).format(amount);
  } catch (error) {
    // Fallback formatting if currency code is invalid
    return `${amount.toFixed(2)} ${baseCurrency}`;
  }
};

export function PaymentHistory() {
  const [payments, setPayments] = useState<PaymentWithPackage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [downloadingInvoice, setDownloadingInvoice] = useState<string | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<PaymentWithPackage | null>(null);
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [showRetryDialog, setShowRetryDialog] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();

  useEffect(() => {
    if (authLoading) return;
    
    if (!user) {
      navigate('/login', { state: { from: '/payment-history' } });
      return;
    }
    
    loadPaymentHistory();
  }, [user, authLoading, navigate]);

  const loadPaymentHistory = async () => {
    try {
      setLoading(true);
      setError(null);
      const history = await getPaymentHistory();
      setPayments(history);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load payment history';
      setError(errorMessage);
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleViewInvoice = (payment: PaymentWithPackage) => {
    setSelectedPayment(payment);
    setShowInvoiceDialog(true);
  };

  const handleDownloadInvoice = async (paymentId: string) => {
    try {
      setDownloadingInvoice(paymentId);
      const blob = await downloadInvoice(paymentId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice-${paymentId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast({
        title: "Success",
        description: "Invoice downloaded successfully",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err instanceof Error ? err.message : "Failed to download invoice",
        variant: "destructive",
      });
    } finally {
      setDownloadingInvoice(null);
    }
  };

  const handleRetryPayment = (payment: PaymentWithPackage) => {
    setSelectedPayment(payment);
    setShowRetryDialog(true);
  };

  if (loading || authLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin">
          <Clock className="h-6 w-6" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
        <p className="text-destructive">{error}</p>
        <Button onClick={loadPaymentHistory}>Retry</Button>
      </div>
    );
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Payment History</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Transaction ID</TableHead>
                <TableHead>Date & Time</TableHead>
                <TableHead>Package</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Payment Method</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {payments.map((payment) => {
                const { date, time } = formatDate(payment.created_at);
                const isFailedPayment = ['failed', 'expired'].includes(payment.status.toLowerCase());
                
                return (
                  <TableRow key={payment.id}>
                    <TableCell className="font-mono">
                      {payment.transaction_id || payment.id}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-col">
                        <span className="font-medium">{date}</span>
                        <span className="text-sm text-muted-foreground">{time}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {payment.credit_package ? (
                        <div className="flex flex-col">
                          <span className="font-medium">{payment.credit_package.name}</span>
                          <span className="text-sm text-muted-foreground">
                            {payment.credit_package.credits.toLocaleString()} credits
                          </span>
                        </div>
                      ) : (
                        payment.package_id
                      )}
                    </TableCell>
                    <TableCell>
                      {formatAmount(payment.amount, payment.currency)}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        {getPaymentMethodIcon(payment.payment_method)}
                        <span className="capitalize">{payment.payment_method?.replace('_', ' ')}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {isFailedPayment ? (
                        <div className="flex items-center gap-2">
                          <Badge variant="destructive">{payment.status}</Badge>
                        </div>
                      ) : payment.status.toLowerCase() === 'pending' ? (
                        <Badge variant="secondary">Pending</Badge>
                      ) : (
                        <Badge variant="default">{payment.status}</Badge>
                      )}
                    </TableCell>
                    <TableCell className="text-right">
                      <div className="flex justify-end items-center gap-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleViewInvoice(payment)}
                          title="View Invoice"
                        >
                          <Eye className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDownloadInvoice(payment.id)}
                          disabled={downloadingInvoice === payment.id}
                          title="Download Invoice"
                        >
                          {downloadingInvoice === payment.id ? (
                            <Clock className="h-4 w-4 animate-spin" />
                          ) : (
                            <Download className="h-4 w-4" />
                          )}
                        </Button>
                        {payment.status !== 'confirmed' && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleRetryPayment(payment)}
                          >
                            Retry Payment
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {selectedPayment && showInvoiceDialog && (
        <InvoiceDialog
          isOpen={showInvoiceDialog}
          onClose={() => {
            setShowInvoiceDialog(false);
            setSelectedPayment(null);
          }}
          paymentId={selectedPayment.id}
          paymentDetails={{
            transactionId: selectedPayment.transaction_id || selectedPayment.id,
            date: formatDate(selectedPayment.created_at).date + ' ' + formatDate(selectedPayment.created_at).time,
            status: selectedPayment.status,
            packageName: selectedPayment.credit_package?.name,
            credits: selectedPayment.credit_package?.credits,
            amount: selectedPayment.amount,
            currency: selectedPayment.currency,
          }}
        />
      )}

      {selectedPayment && showRetryDialog && (
        <RetryPaymentDialog
          open={showRetryDialog}
          onOpenChange={(open) => {
            setShowRetryDialog(open);
            if (!open) setSelectedPayment(null);
          }}
          paymentId={selectedPayment.id}
          amount={selectedPayment.amount}
        />
      )}
    </>
  );
}
