import axios from 'axios';
import * as crypto from 'crypto';

interface NOWPaymentsResponse {
  payment_id: string;
  payment_status: string;
  pay_address: string;
  price_amount: number;
  price_currency: string;
  pay_amount: number;
  pay_currency: string;
  order_id: string;
  order_description: string;
  payin_extra_id?: string;
  ipn_callback_url?: string;
  created_at?: string;
  updated_at?: string;
  purchase_id?: string;
  payment_extra_id?: string;
  invoice_url?: string;
}

interface NOWPaymentsErrorResponse {
  message?: string;
  statusCode?: number;
}

interface NOWPaymentsInvoiceRequest {
  price_amount: number;
  price_currency: string;
  pay_currency: string;
  pay_network?: string;
  order_id: string;
  order_description: string;
  ipn_callback_url: string;
  success_url: string;
  cancel_url: string;
  partially_paid_url?: string;
  payout_currency?: string;
  payout_address?: string;
  payout_extra_id?: string;
  fixed_rate?: boolean;
  is_fee_paid_by_user?: boolean;
}

interface NOWPaymentsInvoiceResponse {
  id: string;
  token_id: string;
  order_id: string;
  order_description: string;
  price_amount: number;
  price_currency: string;
  pay_currency: string;
  ipn_callback_url: string;
  invoice_url: string;
  success_url: string;
  cancel_url: string;
  created_at: string;
  updated_at: string;
}

interface NOWPaymentsCurrency {
  code?: string;
  currency?: string;
  name?: string;
  network?: string;
  enabled?: boolean;
}

interface NOWPaymentsResponse {
  selectedCurrencies: string[];
}

interface NOWPaymentsFullCurrencyResponse {
  currencies: CurrencyNetwork[];
}

interface NOWPaymentsCurrencyResponse {
  selectedCurrencies: string[];
}

export interface CurrencyNetwork {
  network: string;
  currency: string;
  name: string;
  enabled: boolean;
  has_payouts: boolean;
  is_popular: boolean;
  network_confirmations: number;
  min_amount?: number;
  max_amount?: number;
}

export class NOWPaymentsService {
  private readonly apiKey: string;
  private readonly baseURL: string;
  private readonly ipnSecret: string;
  private readonly headers: { [key: string]: string };
  
  constructor(apiKey: string, ipnSecret: string) {
    this.apiKey = apiKey;
    this.ipnSecret = ipnSecret;
    this.baseURL = 'https://api.nowpayments.io/v1';
    this.headers = {
      'x-api-key': this.apiKey,
      'Content-Type': 'application/json',
    };
  }

  private formatCurrencyForAPI(currency: string): string {
    // Remove underscore and concatenate for API format
    return currency.replace('_', '');
  }

  async createPayment(params: NOWPaymentsInvoiceRequest): Promise<{ payment_id: string; payment_url: string; pay_address: string }> {
    try {
      if (!this.apiKey) {
        throw new Error('NOWPayments API key is not configured');
      }

      // Format the pay_currency for the API
      const apiParams = {
        ...params,
        pay_currency: this.formatCurrencyForAPI(params.pay_currency)
      };

      console.log('Creating NOWPayments invoice with params:', {
        ...apiParams,
        apiKey: this.apiKey.slice(0, 4) + '...'
      });

      // Create an invoice
      const invoiceResponse = await axios.post<NOWPaymentsInvoiceResponse>(
        `${this.baseURL}/invoice`,
        {
          ...apiParams,
          is_fee_paid_by_user: true, // Optional: let user pay the fee
        },
        { headers: this.headers }
      );

      console.log('NOWPayments invoice response:', invoiceResponse.data);

      if (!invoiceResponse.data?.invoice_url) {
        throw new Error('No invoice URL received from NOWPayments');
      }

      // Extract payment ID from the invoice response
      const paymentId = invoiceResponse.data.id;

      return {
        payment_id: paymentId,
        payment_url: invoiceResponse.data.invoice_url,
        pay_address: '', // Pay address will be shown in the invoice page
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response?.data as NOWPaymentsErrorResponse;
        console.error('NOWPayments API error:', {
          status: error.response?.status,
          data: error.response?.data,
          config: {
            url: error.config?.url,
            method: error.config?.method,
            headers: {
              ...error.config?.headers,
              'x-api-key': '***hidden***',
            },
          },
        });
        throw new Error(`NOWPayments API error: ${errorResponse?.message || error.message}`);
      }
      
      if (error instanceof Error) {
        console.error('NOWPayments error:', error.message);
        throw error;
      }
      
      console.error('Unknown NOWPayments error:', error);
      throw new Error('An unknown error occurred');
    }
  }

  async getAvailableCurrencies(): Promise<CurrencyNetwork[]> {
    try {
      if (!this.apiKey) {
        throw new Error('NOWPayments API key is not configured');
      }

      console.log('Fetching available currencies from NOWPayments...');
      
      const response = await axios.get<NOWPaymentsResponse>(
        `${this.baseURL}/merchant/coins`,
        { 
          headers: this.headers,
          validateStatus: (status) => status === 200
        }
      );

      console.log('NOWPayments API Response:', response.data);

      // Extract currencies from the selectedCurrencies array
      const currencyList = response.data?.selectedCurrencies || [];
      
      if (!Array.isArray(currencyList) || currencyList.length === 0) {
        console.warn('No currencies returned from NOWPayments API');
        return [];
      }

      console.log('Processing currency list:', currencyList.slice(0, 3)); // Log first 3 currencies for debugging

      // Transform the currency strings into CurrencyNetwork objects
      const currencies: CurrencyNetwork[] = currencyList
        .filter((curr): curr is string => typeof curr === 'string' && curr.length > 0)
        .map((currencyCode: string) => {
          const code = currencyCode.toUpperCase();
          console.log('Processing currency:', { currencyCode: code }); // Debug log
          
          // Handle special cases for network-specific currencies
          if (code.startsWith('USDT')) {
            const network = code.replace('USDT', '') || 'TRON';
            return {
              currency: 'USDT',
              network: network,
              name: `USDT (${network})`,
              enabled: true,
              has_payouts: true,
              is_popular: true,
              network_confirmations: 1
            };
          }

          // For other currencies
          return {
            currency: code,
            network: 'DEFAULT',
            name: code,
            enabled: true,
            has_payouts: true,
            is_popular: false,
            network_confirmations: 1
          };
        });

      console.log('Transformed currencies:', currencies.slice(0, 3)); // Log first 3 transformed currencies
      return currencies;
    } catch (error) {
      console.error('Error fetching available currencies:', error);
      if (axios.isAxiosError(error)) {
        console.error('NOWPayments API error details:', {
          status: error.response?.status,
          data: error.response?.data,
          headers: error.response?.headers
        });
      }
      throw error;
    }
  }

  verifyWebhookSignature(payload: any, signature: string): boolean {
    const sortedPayload = this.sortObject(payload);
    const stringifiedPayload = JSON.stringify(sortedPayload);
    const hmac = crypto.createHmac('sha512', this.ipnSecret);
    hmac.update(stringifiedPayload);
    const calculatedSignature = hmac.digest('hex');
    return calculatedSignature === signature;
  }

  verifyIPNSignature(payload: any, signature: string): boolean {
    return this.verifyWebhookSignature(payload, signature);
  }

  private sortObject(obj: any): any {
    if (obj === null) return null;
    if (typeof obj !== "object") return obj;
    if (Array.isArray(obj)) return obj.map(this.sortObject);
    return Object.keys(obj)
      .sort()
      .reduce((result: any, key) => {
        result[key] = this.sortObject(obj[key]);
        return result;
      }, {});
  }
}