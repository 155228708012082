import { supabase } from './supabase'
import { ValidationResult } from './types'
import { Database, DeductCreditsResult } from './database.types'
import { validateEmailsWithRetry } from './emailValidator'
import { v4 as uuidv4 } from 'uuid'

// Import ValidationProgress type from emailValidator
import { ValidationProgress } from './emailValidator'

// Add interface for validation record
export interface ValidationRecord {
  id: string;
  user_id: string;
  total_emails: number;
  valid_emails: string[];
  invalid_emails: string[];
  risky_emails: string[];
  created_at: string;
  status: string;
  file_name?: string | null;
  credits_used: number;
}

// Update interface for validation options to include fileName
interface ValidationOptions {
  batchSize?: number;
  onProgress?: (progress: ValidationProgress) => void;
  fileName?: string;
}

export async function recordValidationStats(
  result: ValidationResult,
  options: ValidationOptions = {}
): Promise<ValidationRecord> {
  try {
    const { data: { user } } = await supabase.auth.getUser()
    if (!user) throw new Error('No authenticated user')

    // Record the validation details
    const { data: validation, error } = await supabase
      .from('email_validations')
      .insert([{
        user_id: user.id,
        total_emails: result.totalEmails,
        valid_emails: result.valid,
        invalid_emails: result.invalid,
        risky_emails: result.risky,
        file_name: result.fileName || null,
        credits_used: result.totalEmails,
        status: 'completed'
      }])
      .select('*')
      .single()

    if (error) throw error
    if (!validation) throw new Error('No validation record created')

    return validation as ValidationRecord
  } catch (error) {
    console.error('Error recording validation stats:', error)
    throw error
  }
}

export async function validateEmailList(
  emails: string[],
  options: ValidationOptions = {}
): Promise<ValidationResult> {
  const { onProgress } = options

  try {
    // Generate validation ID
    const validationId = uuidv4()

    // Validate emails with retry logic and progress tracking
    const result = await validateEmailsWithRetry(emails, 3, onProgress)

    // Record stats and deduct credits atomically
    const validation = await recordValidationStats({
      id: validationId, // Add the ID here
      ...result,
      date: new Date(),
      creditsUsed: emails.length,
      status: 'completed',
      fileName: options.fileName
    })

    // Deduct credits
    const creditResult = await deductValidationCredits(validation.id, emails.length)

    return {
      ...result,
      id: validation.id,
      date: new Date(),
      creditsUsed: creditResult.deducted,
      status: 'completed'
    }
  } catch (error) {
    console.error('Validation error:', error)
    throw error
  }
}

export async function deductValidationCredits(
  validationId: string,
  credits: number
): Promise<DeductCreditsResult> {
  try {
    const { data, error } = await supabase.rpc('deduct_credits_for_validation', {
      p_credits: credits,
      p_validation_id: validationId
    });

    if (error) {
      console.error('RPC Error:', error);
      throw error;
    }

    if (!data) {
      throw new Error('No data returned from deduct_credits_for_validation');
    }

    return data;
  } catch (error) {
    console.error('Error deducting credits:', error);
    throw error;
  }
} 