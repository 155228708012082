import { useState } from 'react';
import { Download, Mail, AlertTriangle, XCircle, Shield, Trash2, Upload, Loader2, AlertCircle } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
} from '@/components/ui/pagination';
import { Input } from '@/components/ui/input';
import { ValidationResult, ValidationCategory } from '@/lib/types';
import { Progress } from '@/components/ui/progress';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { cn } from '@/lib/utils';

const categories: ValidationCategory[] = [
  {
    type: 'valid',
    label: 'Valid Emails',
    color: 'bg-green-100 text-green-800',
    description: 'These emails are valid and safe to send to',
    icon: 'mail',
  },
  {
    type: 'invalid',
    label: 'Invalid Emails',
    color: 'bg-red-100 text-red-800',
    description: 'These emails are invalid or non-existent',
    icon: 'x-circle',
  },
  {
    type: 'risky',
    label: 'Risky Emails',
    color: 'bg-yellow-100 text-yellow-800',
    description: 'These emails might have deliverability issues',
    icon: 'alert-triangle',
  },
  {
    type: 'spamTraps',
    label: 'Spam Traps',
    color: 'bg-purple-100 text-purple-800',
    description: 'Known spam trap addresses',
    icon: 'shield',
  },
  {
    type: 'disposable',
    label: 'Disposable Emails',
    color: 'bg-blue-100 text-blue-800',
    description: 'Temporary email addresses',
    icon: 'trash-2',
  },
];

interface ValidationReportProps {
  result: ValidationResult;
  isLoading?: boolean;
}

export function ValidationReport({ result, isLoading = false }: ValidationReportProps) {
  const navigate = useNavigate();
  const [currentCategory, setCurrentCategory] = useState<string>('valid');
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const getIconComponent = (iconName: string) => {
    switch (iconName) {
      case 'mail':
        return Mail;
      case 'x-circle':
        return XCircle;
      case 'alert-triangle':
        return AlertTriangle;
      case 'shield':
        return Shield;
      case 'trash-2':
        return Trash2;
      default:
        return Mail;
    }
  };

  const getCategoryEmails = (category: string) => {
    switch (category) {
      case 'valid':
        return result.valid;
      case 'invalid':
        return result.invalid;
      case 'risky':
        return result.risky;
      case 'spamTraps':
        return result.spamTraps;
      case 'disposable':
        return result.disposable;
      default:
        return [];
    }
  };

  const itemsPerPage = 10;
  const emails = getCategoryEmails(currentCategory);
  const filteredEmails = emails.filter(email => 
    email.toLowerCase().includes(search.toLowerCase())
  );
  const totalPages = Math.ceil(filteredEmails.length / itemsPerPage);
  const currentEmails = filteredEmails.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const downloadCategory = (category: string) => {
    const emails = getCategoryEmails(category);
    const blob = new Blob([emails.join('\n')], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${category}-emails.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleNewValidation = () => {
    const newValidationId = uuidv4();
    
    const history = JSON.parse(localStorage.getItem('validationHistory') || '[]');
    if (!history.some((item: ValidationResult) => item.id === result.id)) {
      history.unshift({
        ...result,
        id: result.id || uuidv4(),
        date: result.date || new Date(),
        status: 'completed'
      });
      localStorage.setItem('validationHistory', JSON.stringify(history));
    }

    navigate('/validate', { state: { validationId: newValidationId } });
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <p className="text-sm text-muted-foreground">Loading validation report...</p>
      </div>
    );
  }

  if (!result) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
        <AlertCircle className="h-8 w-8 text-destructive" />
        <p className="text-sm text-muted-foreground">Validation report not found</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">Validation Results</h1>
        <Button 
          variant="default" 
          onClick={handleNewValidation}
          className="w-full sm:w-auto"
        >
          <Upload className="mr-2 h-4 w-4" />
          New Validation
        </Button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {categories.map((category) => {
          const emails = getCategoryEmails(category.type);
          const Icon = getIconComponent(category.icon);
          return (
            <Card
              key={category.type}
              className={cn(
                "cursor-pointer hover:shadow-md transition-shadow",
                currentCategory === category.type && "ring-2 ring-primary"
              )}
              onClick={() => setCurrentCategory(category.type)}
            >
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  {category.label}
                </CardTitle>
                <Icon className={cn("h-4 w-4", category.color)} />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{emails.length}</div>
                <p className="text-xs text-muted-foreground">
                  {((emails.length / result.totalEmails) * 100).toFixed(1)}%
                </p>
              </CardContent>
            </Card>
          );
        })}
      </div>

      <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
        <Card className="lg:col-span-2">
          <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <CardTitle>
              {categories.find(c => c.type === currentCategory)?.label}
            </CardTitle>
            <Button
              variant="outline"
              onClick={() => downloadCategory(currentCategory)}
              className="w-full sm:w-auto"
            >
              <Download className="mr-2 h-4 w-4" />
              Download List
            </Button>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <Input
                placeholder="Search emails..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-full sm:max-w-sm"
              />
              
              <div className="rounded-md border overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Email Address</TableHead>
                      <TableHead className="w-[100px]">Status</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentEmails.map((email, index) => (
                      <TableRow key={index}>
                        <TableCell className="break-all">{email}</TableCell>
                        <TableCell>
                          <span className={cn(
                            "inline-flex items-center rounded-full px-2 py-1 text-xs",
                            categories.find(c => c.type === currentCategory)?.color
                          )}>
                            {categories.find(c => c.type === currentCategory)?.label}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                    className="w-[100px]"
                  >
                    Previous
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                    disabled={currentPage === totalPages}
                    className="w-[100px]"
                  >
                    Next
                  </Button>
                </div>
                <span className="text-sm text-muted-foreground">
                  Page {currentPage} of {totalPages}
                </span>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Validation Summary</CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-muted-foreground">Total Emails</span>
                <span className="font-medium">{result.totalEmails}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-muted-foreground">Credits Used</span>
                <span className="font-medium">{result.creditsUsed}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-muted-foreground">Processing Time</span>
                <span className="font-medium">
                  {new Date(result.date).toLocaleTimeString()}
                </span>
              </div>
            </div>
            
            <div className="space-y-4">
              {categories.map(category => {
                const emails = getCategoryEmails(category.type);
                const percentage = (emails.length / result.totalEmails) * 100;
                return (
                  <div key={category.type} className="space-y-1">
                    <div className="flex justify-between text-sm">
                      <span className="text-muted-foreground">{category.label}</span>
                      <span className="font-medium">{percentage.toFixed(1)}%</span>
                    </div>
                    <Progress value={percentage} className="h-1" />
                  </div>
                );
              })}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}