import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bitcoin, Citrus, Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { createPayment } from '@/api/payments';
import { CurrencySelector } from '@/components/payment/CurrencySelector';
import { type CryptoCurrency } from '@/lib/types/currency';
import { getPaymentGateways } from '@/lib/admin';
import { type LemonsqueezyConfig, type CreditPackage } from '@/lib/types';

interface PaymentDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  package: CreditPackage;
}

export function PaymentDialog({ open, onOpenChange, package: pkg }: PaymentDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CryptoCurrency>('btc');
  const [showCryptoOptions, setShowCryptoOptions] = useState(false);
  const [lemonsqueezyConfig, setLemonsqueezyConfig] = useState<LemonsqueezyConfig | null>(null);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const loadLemonsqueezyConfig = async () => {
      try {
        const gateways = await getPaymentGateways();
        const lemonsqueezyGateway = gateways.find(
          (g) => g.type === 'lemonsqueezy' && g.is_active
        );
        
        if (!lemonsqueezyGateway) {
          throw new Error('Lemonsqueezy gateway not found');
        }
        
        setLemonsqueezyConfig(lemonsqueezyGateway.config as LemonsqueezyConfig);
      } catch (error) {
        console.error('Error loading Lemonsqueezy config:', error);
        toast({
          title: 'Error',
          description: 'Failed to load payment gateway configuration',
          variant: 'destructive',
        });
      }
    };
    loadLemonsqueezyConfig();
  }, []);

  const handleCryptoPayment = async () => {
    if (!showCryptoOptions) {
      setShowCryptoOptions(true);
      return;
    }

    try {
      setIsLoading(true);
      const result = await createPayment(pkg.id, selectedCurrency);
      
      if (!result?.paymentUrl) {
        throw new Error('No payment URL received');
      }

      // Close the dialog before redirecting
      onOpenChange(false);
      
      // Use window.location.href for external URLs
      window.location.href = result.paymentUrl;
    } catch (error) {
      console.error('Payment error:', error);
      toast({
        title: 'Payment Error',
        description: error instanceof Error ? error.message : 'Failed to process payment',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardPayment = () => {
    console.log('Card payment clicked', { 
      pkg_id: pkg.id,
      pkg_name: pkg.name,
      pkg_credits: pkg.credits,
      pkg_price: pkg.price,
      pkg_variant_id: pkg.lemon_variant_id,
      lemonsqueezy_config: lemonsqueezyConfig 
    });
    
    if (!pkg.lemon_variant_id) {
      console.log('No lemon_variant_id found for package');
      toast({
        title: 'Error',
        description: 'Card payment is not available for this package. Please try crypto payment instead.',
        variant: 'destructive',
      });
      return;
    }

    if (!lemonsqueezyConfig?.store_id) {
      console.log('No store_id found in lemonsqueezyConfig');
      toast({
        title: 'Error',
        description: 'Card payment is temporarily unavailable. Please try crypto payment instead.',
        variant: 'destructive',
      });
      return;
    }

    // Create a payment record and redirect to Lemonsqueezy with custom domain
    createPayment(pkg.id, 'card')
      .then((result) => {
        // Close the dialog before redirecting
        onOpenChange(false);
        
        // Redirect to the payment URL (now using custom domain)
        window.location.href = result.paymentUrl;
      })
      .catch((error) => {
        console.error('Payment creation error:', error);
        toast({
          title: 'Payment Error',
          description: error instanceof Error ? error.message : 'Failed to process payment',
          variant: 'destructive',
        });
      });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Purchase Credits</DialogTitle>
          <DialogDescription>
            Purchase {pkg.credits.toLocaleString()} credits for ${pkg.price}
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {showCryptoOptions ? (
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <label className="text-sm font-medium">Select Cryptocurrency:</label>
                <CurrencySelector
                  value={selectedCurrency}
                  onValueChange={setSelectedCurrency}
                  disabled={isLoading}
                />
              </div>
              <div className="flex gap-2">
                <Button
                  onClick={() => setShowCryptoOptions(false)}
                  variant="outline"
                  className="flex-1"
                >
                  Back
                </Button>
                <Button
                  onClick={handleCryptoPayment}
                  disabled={isLoading}
                  className="flex-1"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    'Continue'
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <Button
                onClick={handleCryptoPayment}
                disabled={isLoading}
                className="w-full h-12"
              >
                <Bitcoin className="mr-2 h-4 w-4" />
                Pay with Crypto
              </Button>
              <Button
                onClick={handleCardPayment}
                variant="outline"
                className="w-full h-12"
              >
                <Citrus className="mr-2 h-4 w-4 dark:text-white" />
                Pay with Card
              </Button>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
