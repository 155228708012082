'use client';

import { useState, useEffect } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle, CheckCircle2, Info, Loader2 as Loader, ExternalLink } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { useNavigate } from 'react-router-dom';
import { Database } from '@/lib/database.types';

type Json = Database['public']['Tables']['email_marketing_integrations']['Row']['settings'];
type EmailMarketingPlatform = 'mailchimp' | 'brevo' | 'activecampaign';

type Integration = {
  id: string;
  user_id: string;
  platform: EmailMarketingPlatform;
  api_key: string | null;
  oauth_token: string | null;
  oauth_refresh_token: string | null;
  oauth_expires_at: string | null;
  created_at: string;
  updated_at: string;
};

const platformInfo = {
  mailchimp: {
    name: 'Mailchimp',
    description: 'Connect with Mailchimp to sync your email lists and track bounces automatically.',
    features: ['Email List Sync', 'Bounce Tracking', 'Campaign Stats'],
    apiDocs: 'https://mailchimp.com/developer/marketing/api/root/',
    color: '#FFE01B'
  },
  brevo: {
    name: 'Brevo',
    description: 'Integrate with Brevo (formerly Sendinblue) for comprehensive email marketing and bounce management.',
    features: ['Contact Sync', 'Bounce Handling', 'Email Analytics'],
    apiDocs: 'https://developers.brevo.com/reference/getaccount',
    color: '#0B996E'
  },
  activecampaign: {
    name: 'ActiveCampaign',
    description: 'Leverage ActiveCampaign\'s automation capabilities while managing email bounces effectively.',
    features: ['Automation Integration', 'Contact Management', 'Bounce Detection'],
    apiDocs: 'https://developers.activecampaign.com/reference/overview',
    color: '#356AE6'
  }
};

const platformLogos: Record<EmailMarketingPlatform, string> = {
  brevo: '/logos/brevo.svg',
  mailchimp: '/logos/mailchimp.svg',
  activecampaign: '/logos/activecampaign.svg'
};

export default function EmailMarketingPage() {
  const [selectedPlatform, setSelectedPlatform] = useState<EmailMarketingPlatform | ''>('');
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
    loadIntegrations();
  }, []);

  const checkAuth = async () => {
    try {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error || !session) {
        navigate('/login');
      }
    } catch (error) {
      console.error('Auth error:', error);
      navigate('/login');
    }
  };

  const loadIntegrations = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate('/login');
        return;
      }

      const { data, error } = await supabase
        .from('email_marketing_integrations')
        .select(`
          id,
          user_id,
          platform,
          api_key,
          oauth_token,
          oauth_refresh_token,
          oauth_expires_at,
          created_at,
          updated_at
        `)
        .eq('user_id', session.user.id);

      if (error) throw error;
      setIntegrations(data || []);
    } catch (error) {
      console.error('Error loading integrations:', error);
      toast({
        title: 'Error',
        description: 'Failed to load integrations',
        variant: 'destructive',
      });
    }
  };

  const handleMailchimpAuth = async () => {
    // Implement Mailchimp OAuth flow
    window.location.href = `/api/auth/mailchimp`;
  };

  const handleConnect = async () => {
    if (!selectedPlatform || (selectedPlatform !== 'mailchimp' && !apiKey)) {
      toast({
        title: 'Error',
        description: 'Please fill in all required fields',
        variant: 'destructive',
      });
      return;
    }

    setLoading(true);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate('/login');
        return;
      }

      const integrationData = {
        user_id: session.user.id,
        platform: selectedPlatform,
        api_key: apiKey,
        oauth_token: null,
        oauth_refresh_token: null,
        oauth_expires_at: null
      };

      const { data, error } = await supabase
        .from('email_marketing_integrations')
        .upsert(integrationData)
        .select(`
          id,
          user_id,
          platform,
          api_key,
          oauth_token,
          oauth_refresh_token,
          oauth_expires_at,
          created_at,
          updated_at
        `)
        .single();

      if (error) throw error;

      toast({
        title: 'Success',
        description: `Successfully connected to ${selectedPlatform.charAt(0).toUpperCase() + selectedPlatform.slice(1)}`,
        variant: 'default',
      });

      setApiKey('');
      setSelectedPlatform('');
      loadIntegrations();
    } catch (error) {
      console.error('Error connecting platform:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to connect platform',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnect = async (integrationId: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate('/login');
        return;
      }

      const { error } = await supabase
        .from('email_marketing_integrations')
        .delete()
        .eq('id', integrationId)
        .eq('user_id', session.user.id);

      if (error) throw error;

      toast({
        title: 'Success',
        description: 'Platform disconnected successfully',
        variant: 'default',
      });

      loadIntegrations();
    } catch (error) {
      console.error('Error disconnecting platform:', error);
      toast({
        title: 'Error',
        description: 'Failed to disconnect platform',
        variant: 'destructive',
      });
    }
  };

  const renderIntegrationCard = (integration: Integration) => (
    <Card key={integration.id} className="w-full">
      <CardHeader>
        <CardTitle className="flex items-center space-x-3">
          <div className="w-32 h-8 relative flex items-center">
            <img 
              src={platformLogos[integration.platform]} 
              alt={`${integration.platform} logo`}
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <span className="font-semibold">Connected:</span>
            <span>{new Date(integration.created_at).toLocaleString()}</span>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end space-x-2">
        <Button
          variant="destructive"
          size="sm"
          onClick={() => handleDisconnect(integration.id)}
        >
          Disconnect
        </Button>
      </CardFooter>
    </Card>
  );

  const renderPlatformSupport = () => (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">Platform Support</CardTitle>
        <CardDescription>Connect your preferred email marketing platform to start managing bounces</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid gap-6 md:grid-cols-3">
          {Object.entries(platformInfo).map(([platform, info]) => (
            <div 
              key={platform}
              className="relative overflow-hidden rounded-lg border bg-background p-4"
            >
              <div className="mb-4 h-10 flex items-center">
                <img 
                  src={platformLogos[platform as EmailMarketingPlatform]} 
                  alt={`${info.name} logo`}
                  className="max-h-full object-contain"
                />
              </div>
              <p className="mb-4 text-sm text-muted-foreground">
                {info.description}
              </p>
              <div className="space-y-2">
                <div className="text-sm font-semibold">Key Features:</div>
                <ul className="text-sm space-y-1">
                  {info.features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <CheckCircle2 className="mr-2 h-4 w-4 text-green-500" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <a 
                  href={info.apiDocs}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-blue-500 hover:text-blue-700 flex items-center"
                >
                  <ExternalLink className="mr-1 h-4 w-4" />
                  API Docs
                </a>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setSelectedPlatform(platform as EmailMarketingPlatform);
                    document.getElementById('connect-form')?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Connect
                </Button>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );

  return (
    <div className="space-y-8">
      {/* Documentation Section */}
      <div className="space-y-6">
        <Alert className="bg-blue-50 border-blue-200">
          <AlertCircle className="h-4 w-4 text-blue-600" />
          <AlertTitle className="text-blue-800 font-semibold">Important</AlertTitle>
          <AlertDescription className="text-blue-700">
            Before connecting your email marketing platform, make sure you have:
            <ul className="mt-2 list-disc list-inside space-y-1">
              <li>An active account with your chosen platform</li>
              <li>API access enabled</li>
              <li>The necessary API key or credentials</li>
            </ul>
          </AlertDescription>
        </Alert>

        {renderPlatformSupport()}

      </div>

      <div className="grid gap-8 md:grid-cols-2">
        {/* Connect New Platform */}
        <Card className="md:row-span-2">
          <CardHeader>
            <CardTitle>Connect Platform</CardTitle>
            <CardDescription>
              Select a platform to start integrating with your email marketing tools
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              <Select
                value={selectedPlatform}
                onValueChange={(value) => setSelectedPlatform(value as EmailMarketingPlatform)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Platform" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="mailchimp">Mailchimp</SelectItem>
                  <SelectItem value="brevo">Brevo</SelectItem>
                  <SelectItem value="activecampaign">ActiveCampaign</SelectItem>
                </SelectContent>
              </Select>

              {selectedPlatform && (
                <div className="rounded-lg border p-4 space-y-4">
                  <div>
                    <h3 className="font-medium">{platformInfo[selectedPlatform].name}</h3>
                    <p className="text-sm text-muted-foreground mt-1">
                      {platformInfo[selectedPlatform].description}
                    </p>
                  </div>

                  <div>
                    <h4 className="text-sm font-medium mb-2">Features:</h4>
                    <ul className="text-sm space-y-1">
                      {platformInfo[selectedPlatform].features.map((feature, index) => (
                        <li key={index} className="flex items-center gap-2">
                          <CheckCircle2 className="h-4 w-4 text-green-500" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {selectedPlatform !== 'mailchimp' && (
                    <div className="space-y-2">
                      <label className="text-sm font-medium">API Key</label>
                      <Input
                        type="password"
                        placeholder="Enter API Key"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                      />
                      <p className="text-xs text-muted-foreground">
                        Find your API key in your {platformInfo[selectedPlatform].name} dashboard settings
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CardContent>
          <CardFooter className="flex flex-col items-stretch gap-4">
            <Button
              onClick={selectedPlatform === 'mailchimp' ? handleMailchimpAuth : handleConnect}
              disabled={loading || !selectedPlatform || (selectedPlatform !== 'mailchimp' && !apiKey)}
              className="w-full"
            >
              {loading && <Loader className="mr-2 h-4 w-4 animate-spin" />}
              {loading ? 'Connecting...' : 'Connect Platform'}
            </Button>
            {selectedPlatform && (
              <a
                href={platformInfo[selectedPlatform].apiDocs}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs text-center text-muted-foreground hover:text-foreground transition-colors"
              >
                View {platformInfo[selectedPlatform].name} API Documentation →
              </a>
            )}
          </CardFooter>
        </Card>

        {/* Connected Platforms */}
        <Card>
          <CardHeader>
            <CardTitle>Connected Platforms</CardTitle>
            <CardDescription>
              Manage your connected email marketing platforms
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {integrations.length === 0 ? (
                <div className="text-center py-8">
                  <AlertCircle className="mx-auto h-8 w-8 text-muted-foreground mb-3" />
                  <p className="text-sm text-muted-foreground">
                    No platforms connected yet
                  </p>
                  <p className="text-xs text-muted-foreground mt-1">
                    Connect a platform to start exporting your validated emails
                  </p>
                </div>
              ) : (
                integrations.map((integration) => renderIntegrationCard(integration))
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
