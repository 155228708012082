import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/components/ui/use-toast';
import { Database, Json } from '@/lib/database.types';
import { updatePaymentGateway } from '@/lib/admin';

type PaymentGateway = Database['public']['Tables']['payment_gateways']['Row'];

interface PaymentGatewayConfig {
  [key: string]: string | undefined;
  api_key?: string;
  store_id?: string;
  webhook_secret?: string;
  ipn_secret?: string;
  custom_domain?: string;
}

interface ConfigureGatewayDialogProps {
  gateway: PaymentGateway;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: () => void;
}

export function ConfigureGatewayDialog({
  gateway,
  open,
  onOpenChange,
  onSuccess,
}: ConfigureGatewayDialogProps) {
  const { toast } = useToast();

  // Form states
  const [config, setConfig] = useState<PaymentGatewayConfig>({});

  useEffect(() => {
    if (gateway) {
      const gatewayConfig = (gateway.config || {}) as PaymentGatewayConfig;
      setConfig({
        api_key: gatewayConfig.api_key || '',
        store_id: gatewayConfig.store_id || '',
        webhook_secret: gatewayConfig.webhook_secret || '',
        ipn_secret: gatewayConfig.ipn_secret || '',
        custom_domain: gatewayConfig.custom_domain || '',
      });
    }
  }, [gateway]);

  const handleSave = async () => {
    try {
      const { id, ...restGateway } = gateway;
      await updatePaymentGateway(id, {
        ...restGateway,
        config: config as Json,
      });

      toast({
        title: 'Success',
        description: 'Gateway configuration updated successfully',
      });

      onSuccess?.();
      onOpenChange(false);
    } catch (error) {
      console.error('Error updating gateway:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update gateway configuration',
        variant: 'destructive',
      });
    }
  };

  const renderConfigFields = () => {
    switch (gateway.type) {
      case 'lemonsqueezy':
        return (
          <>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="api_key">API Key</Label>
                <Input
                  id="api_key"
                  type="password"
                  value={config.api_key || ''}
                  onChange={(e) =>
                    setConfig({ ...config, api_key: e.target.value })
                  }
                  placeholder="Enter your Lemon Squeezy API key"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="store_id">Store ID</Label>
                <Input
                  id="store_id"
                  value={config.store_id || ''}
                  onChange={(e) =>
                    setConfig({ ...config, store_id: e.target.value })
                  }
                  placeholder="Enter your store ID"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="webhook_secret">Webhook Secret</Label>
                <Input
                  id="webhook_secret"
                  type="password"
                  value={config.webhook_secret || ''}
                  onChange={(e) =>
                    setConfig({ ...config, webhook_secret: e.target.value })
                  }
                  placeholder="Enter your webhook secret"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="custom_domain">Custom Domain</Label>
                <Input
                  id="custom_domain"
                  value={config.custom_domain || ''}
                  onChange={(e) =>
                    setConfig({ ...config, custom_domain: e.target.value })
                  }
                  placeholder="e.g., pay.emailbounce.org"
                />
                <p className="text-sm text-muted-foreground">
                  Enter your custom domain for Lemonsqueezy checkout. Leave empty to use the default domain.
                </p>
              </div>
            </div>
          </>
        );
      case 'crypto':
        return (
          <>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="api_key">API Key</Label>
                <Input
                  id="api_key"
                  type="password"
                  value={config.api_key || ''}
                  onChange={(e) =>
                    setConfig({ ...config, api_key: e.target.value })
                  }
                  placeholder="Enter your API key"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="ipn_secret">IPN Secret</Label>
                <Input
                  id="ipn_secret"
                  type="password"
                  value={config.ipn_secret || ''}
                  onChange={(e) =>
                    setConfig({ ...config, ipn_secret: e.target.value })
                  }
                  placeholder="Enter your IPN secret"
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Configure {gateway.name}</DialogTitle>
          <DialogDescription>
            Enter the required configuration details for this payment gateway.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {renderConfigFields()}
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save Changes</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
