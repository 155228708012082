import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Search, Download, Loader2 } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { downloadInvoice } from '@/api/payments';
import { useToast } from '@/components/ui/use-toast';

interface Invoice {
  id: string;
  user_id: string;
  amount: number;
  status: string;
  created_at: string;
  user_email: string | undefined;
  company_name: string | undefined;
}

interface UserProfile {
  id: string;
  email: string;
  company_name: string | null;
}

interface Payment {
  id: string;
  user_id: string;
  amount: number;
  status: string;
  created_at: string;
}

export function InvoiceManagement() {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    loadInvoices();
  }, []);

  const loadInvoices = async () => {
    try {
      setIsLoading(true);
      
      // First, get all payments
      const { data: paymentsData, error: paymentsError } = await supabase
        .from('payments')
        .select('*')
        .order('created_at', { ascending: false });

      if (paymentsError) throw paymentsError;
      if (!paymentsData) return;
      
      const payments = paymentsData as unknown as Payment[];

      // Then, get user details for all user_ids
      const userIds = [...new Set(payments.map(p => p.user_id))];
      const { data: profilesData, error: usersError } = await supabase
        .from('user_profiles')
        .select('id, email, company_name')
        .in('id', userIds);

      if (usersError) throw usersError;
      if (!profilesData) return;
      
      const userProfiles = profilesData as unknown as UserProfile[];

      // Create a map of user details for quick lookup
      const userMap = new Map(userProfiles.map(user => [user.id, user]));

      // Combine payment data with user details
      const formattedInvoices: Invoice[] = payments.map(payment => ({
        id: payment.id,
        user_id: payment.user_id,
        amount: payment.amount,
        status: payment.status,
        created_at: payment.created_at,
        user_email: userMap.get(payment.user_id)?.email,
        company_name: userMap.get(payment.user_id)?.company_name || undefined
      }));

      setInvoices(formattedInvoices);
    } catch (error) {
      console.error('Error loading invoices:', error);
      toast({
        title: 'Error',
        description: 'Failed to load invoices',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async (invoiceId: string) => {
    try {
      const blob = await downloadInvoice(invoiceId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice-${invoiceId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading invoice:', error);
      toast({
        title: 'Error',
        description: 'Failed to download invoice',
        variant: 'destructive',
      });
    }
  };

  const filteredInvoices = invoices.filter(invoice => 
    invoice.user_email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    invoice.company_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    invoice.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Invoice Management</CardTitle>
          <CardDescription>
            View and manage all user invoices
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex items-center space-x-2 mb-4">
            <div className="relative flex-1">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search by email, company, or invoice ID"
                className="pl-8"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Button
              variant="outline"
              onClick={() => loadInvoices()}
            >
              Refresh
            </Button>
          </div>

          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="whitespace-nowrap">Invoice ID</TableHead>
                      <TableHead className="whitespace-nowrap">User</TableHead>
                      <TableHead className="whitespace-nowrap">Company</TableHead>
                      <TableHead className="whitespace-nowrap">Amount</TableHead>
                      <TableHead className="whitespace-nowrap">Status</TableHead>
                      <TableHead className="whitespace-nowrap">Date</TableHead>
                      <TableHead className="whitespace-nowrap">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center py-8">
                          <div className="flex justify-center">
                            <Loader2 className="h-6 w-6 animate-spin" />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : filteredInvoices.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center py-8">
                          No invoices found
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredInvoices.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <TableCell className="font-medium whitespace-nowrap">
                            {invoice.id.slice(0, 8)}
                          </TableCell>
                          <TableCell className="whitespace-nowrap max-w-[200px] truncate">
                            {invoice.user_email}
                          </TableCell>
                          <TableCell className="whitespace-nowrap max-w-[200px] truncate">
                            {invoice.company_name || '-'}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            ${invoice.amount.toFixed(2)}
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              invoice.status === 'confirmed' 
                                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                : invoice.status === 'pending'
                                ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                                : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                            }`}>
                              {invoice.status}
                            </span>
                          </TableCell>
                          <TableCell className="whitespace-nowrap">
                            {new Date(invoice.created_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center gap-2">
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => handleDownload(invoice.id)}
                              >
                                <Download className="h-4 w-4" />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
