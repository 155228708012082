import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Mail, CheckCircle, XCircle, AlertTriangle, AlertCircle, Loader2, Upload } from 'lucide-react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { supabase } from '@/lib/supabase';
import { useNavigate } from 'react-router-dom';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from '@/components/ui/button';

interface StatsData {
  totalEmails: number;
  validEmails: number;
  invalidEmails: number;
  riskyEmails: number;
  chartData: Array<{
    date: string;
    validations: number;
    valid: number;
    invalid: number;
    risky: number;
  }>;
}

interface TooltipContentProps {
  active?: boolean;
  payload?: any[];
}

const CustomTooltip = ({ active, payload }: TooltipContentProps) => {
  if (active && payload?.length) {
    return (
      <div className="rounded-lg border bg-background p-2 shadow-sm">
        <div className="grid gap-2">
          <div className="flex flex-col">
            <span className="text-[0.70rem] uppercase text-muted-foreground">
              Total
            </span>
            <span className="font-bold text-muted-foreground">
              {payload[0].value}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-[0.70rem] uppercase text-green-500">
              Valid
            </span>
            <span className="font-bold text-green-500">
              {payload[1].value}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-[0.70rem] uppercase text-red-500">
              Invalid
            </span>
            <span className="font-bold text-red-500">
              {payload[2].value}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-[0.70rem] uppercase text-yellow-500">
              Risky
            </span>
            <span className="font-bold text-yellow-500">
              {payload[3].value}
            </span>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export function Stats() {
  const navigate = useNavigate();
  const [stats, setStats] = useState<StatsData>({
    totalEmails: 0,
    validEmails: 0,
    invalidEmails: 0,
    riskyEmails: 0,
    chartData: [],
  });
  const [timeRange, setTimeRange] = useState('7d');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadStats();
  }, [timeRange]);

  const loadStats = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const startDate = new Date();
      switch (timeRange) {
        case '24h':
          startDate.setHours(startDate.getHours() - 24);
          break;
        case '7d':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case '30d':
          startDate.setDate(startDate.getDate() - 30);
          break;
        case '90d':
          startDate.setDate(startDate.getDate() - 90);
          break;
      }

      const { data, error } = await supabase
        .from('email_validations')
        .select('*')
        .eq('user_id', user.id)
        .gte('created_at', startDate.toISOString());

      if (error) throw error;

      // Process stats
      const totalEmails = data.reduce((sum, item) => sum + (item.total_emails || 0), 0);
      const validEmails = data.reduce((sum, item) => {
        const validList = item.valid_emails;
        return sum + (Array.isArray(validList) ? validList.length : 0);
      }, 0);
      const invalidEmails = data.reduce((sum, item) => {
        const invalidList = item.invalid_emails;
        return sum + (Array.isArray(invalidList) ? invalidList.length : 0);
      }, 0);
      const riskyEmails = data.reduce((sum, item) => {
        const riskyList = item.risky_emails;
        return sum + (Array.isArray(riskyList) ? riskyList.length : 0);
      }, 0);

      // Process chart data
      const validationsByDate = data.reduce((acc: Record<string, any>, item) => {
        const date = new Date(item.created_at).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = {
            date,
            validations: 0,
            valid: 0,
            invalid: 0,
            risky: 0
          };
        }
        acc[date].validations += item.total_emails || 0;
        acc[date].valid += Array.isArray(item.valid_emails) ? item.valid_emails.length : 0;
        acc[date].invalid += Array.isArray(item.invalid_emails) ? item.invalid_emails.length : 0;
        acc[date].risky += Array.isArray(item.risky_emails) ? item.risky_emails.length : 0;
        return acc;
      }, {});

      const chartData = Object.values(validationsByDate).sort((a, b) => 
        new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      setStats({
        totalEmails,
        validEmails,
        invalidEmails,
        riskyEmails,
        chartData,
      });
    } catch (error) {
      console.error('Error loading stats:', error);
      setError(error instanceof Error ? error.message : 'Failed to load statistics');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="flex flex-col items-center gap-2">
          <AlertCircle className="h-8 w-8 text-destructive" />
          <p className="text-sm text-muted-foreground">{error}</p>
          <button
            onClick={loadStats}
            className="text-sm text-muted-foreground hover:text-primary"
          >
            Try again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h2 className="text-2xl sm:text-3xl font-bold tracking-tight">Dashboard</h2>
        <div className="flex flex-col sm:flex-row gap-4">
          <Select value={timeRange} onValueChange={setTimeRange}>
            <SelectTrigger className="w-full sm:w-[180px]">
              <SelectValue placeholder="Select time range" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="24h">Last 24 Hours</SelectItem>
              <SelectItem value="7d">Last 7 Days</SelectItem>
              <SelectItem value="30d">Last 30 Days</SelectItem>
              <SelectItem value="90d">Last 90 Days</SelectItem>
            </SelectContent>
          </Select>
          <Button 
            variant="default" 
            onClick={() => navigate('/validate')}
            className="w-full sm:w-auto"
          >
            <Upload className="mr-2 h-4 w-4" />
            New Validation
          </Button>
        </div>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Emails</CardTitle>
            <Mail className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.totalEmails.toLocaleString()}</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Valid Emails</CardTitle>
            <CheckCircle className="h-4 w-4 text-green-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.validEmails.toLocaleString()}</div>
            <p className="text-xs text-muted-foreground">
              {stats.totalEmails ? ((stats.validEmails / stats.totalEmails) * 100).toFixed(1) : 0}% success rate
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Invalid Emails</CardTitle>
            <XCircle className="h-4 w-4 text-red-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.invalidEmails.toLocaleString()}</div>
            <p className="text-xs text-muted-foreground">
              {stats.totalEmails ? ((stats.invalidEmails / stats.totalEmails) * 100).toFixed(1) : 0}% of total
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Risky Emails</CardTitle>
            <AlertTriangle className="h-4 w-4 text-yellow-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.riskyEmails.toLocaleString()}</div>
            <p className="text-xs text-muted-foreground">
              {stats.totalEmails ? ((stats.riskyEmails / stats.totalEmails) * 100).toFixed(1) : 0}% of total
            </p>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Validation Trends</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={stats.chartData}>
                <defs>
                  <linearGradient id="colorValidations" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#0ea5e9" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#0ea5e9" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorValid" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#22c55e" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#22c55e" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorInvalid" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#ef4444" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ef4444" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorRisky" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#eab308" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#eab308" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
                <XAxis
                  dataKey="date"
                  tick={{ fontSize: 12 }}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  tick={{ fontSize: 12 }}
                  tickLine={false}
                  axisLine={false}
                  width={60}
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="validations"
                  stroke="#0ea5e9"
                  fillOpacity={1}
                  fill="url(#colorValidations)"
                />
                <Area
                  type="monotone"
                  dataKey="valid"
                  stroke="#22c55e"
                  fillOpacity={1}
                  fill="url(#colorValid)"
                />
                <Area
                  type="monotone"
                  dataKey="invalid"
                  stroke="#ef4444"
                  fillOpacity={1}
                  fill="url(#colorInvalid)"
                />
                <Area
                  type="monotone"
                  dataKey="risky"
                  stroke="#eab308"
                  fillOpacity={1}
                  fill="url(#colorRisky)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}