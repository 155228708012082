import { supabase } from './supabase';
import { validateEmailBatch } from './emailValidator';
import { 
  ValidationResult, 
  ValidationFilters,
  ValidationRecord,
  ValidationStatus,
  ValidationHistoryResponse
} from './types';
import { recordValidationStats } from './validation';
import { v4 as uuidv4 } from 'uuid';

// Add mock data for development
const mockValidationResult = async (emails: string[]): Promise<ValidationResult> => {
  const results = await validateEmailBatch(emails);
  const result: ValidationResult = {
    id: uuidv4(),
    date: new Date(),
    fileName: 'upload.csv',
    totalEmails: emails.length,
    valid: results.valid,
    invalid: results.invalid,
    risky: results.risky,
    disposable: results.disposable,
    spamTraps: results.spamTraps,
    creditsUsed: emails.length,
    status: 'completed'
  };

  // Save to history in development
  if (process.env.NODE_ENV === 'development') {
    const history = JSON.parse(localStorage.getItem('validationHistory') || '[]');
    history.unshift(result);
    localStorage.setItem('validationHistory', JSON.stringify(history));
  }

  return result;
};

export async function validateEmails(emails: string[]): Promise<ValidationResult> {
  try {
    if (process.env.NODE_ENV === 'development') {
      return new Promise(resolve => {
        setTimeout(async () => {
          const result = await mockValidationResult(emails);
          resolve(result);
        }, 2000);
      });
    }

    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    const results = await validateEmailBatch(emails);
    
    const validationResult: ValidationResult = {
      id: uuidv4(),
      date: new Date(),
      fileName: 'upload.csv',
      totalEmails: emails.length,
      valid: results.valid,
      invalid: results.invalid,
      risky: results.risky,
      disposable: results.disposable,
      spamTraps: results.spamTraps,
      creditsUsed: emails.length,
      status: 'completed'
    };

    return validationResult;
  } catch (error) {
    console.error('Error validating emails:', error);
    throw error;
  }
}

export async function validateSingleEmail(email: string): Promise<ValidationResult> {
  return validateEmails([email]);
}

export async function getUserCredits(): Promise<number> {
  try {
    if (process.env.NODE_ENV === 'development') {
      const credits = parseInt(localStorage.getItem('userCredits') || '1000');
      return Promise.resolve(credits);
    }

    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    const { data, error } = await supabase
      .from('user_profiles')
      .select('credits')
      .eq('id', user.id)
      .single();

    if (error) throw error;
    return data.credits;
  } catch (error) {
    console.error('Error fetching credits:', error);
    throw error;
  }
}

export async function getValidationHistory(
  page: number = 1,
  filters: ValidationFilters = {}
): Promise<ValidationHistoryResponse> {
  try {
    const itemsPerPage = 10;
    const start = (page - 1) * itemsPerPage;

    if (process.env.NODE_ENV === 'development') {
      const history = JSON.parse(localStorage.getItem('validationHistory') || '[]');
      const filteredHistory = history.filter((item: ValidationResult) => {
        let matches = true;
        
        if (filters.search) {
          const searchLower = filters.search.toLowerCase();
          matches = matches && (
            (item.fileName?.toLowerCase().includes(searchLower) || false) ||
            item.valid.some(email => email.toLowerCase().includes(searchLower)) ||
            item.invalid.some(email => email.toLowerCase().includes(searchLower))
          );
        }

        if (filters.status && filters.status !== 'all') {
          matches = matches && item.status === filters.status;
        }

        return matches;
      });

      // Apply sorting
      const sortedHistory = [...filteredHistory].sort((a, b) => {
        switch (filters.sort) {
          case 'oldest':
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          case 'largest':
            return b.totalEmails - a.totalEmails;
          case 'smallest':
            return a.totalEmails - b.totalEmails;
          case 'newest':
          default:
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        }
      });

      return {
        results: sortedHistory.slice(start, start + itemsPerPage),
        total: filteredHistory.length,
        pages: Math.ceil(filteredHistory.length / itemsPerPage)
      };
    }

    // Production code
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    console.log('Fetching validation history for user:', user.id);
    console.log('Filters:', filters);

    let query = supabase
      .from('email_validations')
      .select('*', { count: 'exact' })
      .eq('user_id', user.id);

    // Apply filters
    if (filters.status && filters.status !== 'all') {
      console.log('Applying status filter:', filters.status);
      query = query.eq('status', filters.status);
    }

    if (filters.search) {
      console.log('Applying search filter:', filters.search);
      const searchTerm = `%${filters.search}%`;
      query = query.ilike('file_name', searchTerm);
    }

    // Apply sorting
    const sortConfig = {
      column: 'created_at',
      ascending: false
    };

    switch (filters.sort) {
      case 'oldest':
        sortConfig.ascending = true;
        break;
      case 'largest':
        sortConfig.column = 'total_emails';
        sortConfig.ascending = false;
        break;
      case 'smallest':
        sortConfig.column = 'total_emails';
        sortConfig.ascending = true;
        break;
      case 'newest':
      default:
        sortConfig.ascending = false;
    }

    console.log('Applying sort:', sortConfig);
    query = query.order(sortConfig.column, { ascending: sortConfig.ascending });

    // Apply pagination
    query = query.range(start, start + itemsPerPage - 1);
    console.log('Pagination:', { start, end: start + itemsPerPage - 1 });

    const { data, error, count } = await query;
    console.log('Query results:', { 
      success: !error,
      count,
      resultsCount: data?.length ?? 0
    });

    if (error) {
      console.error('Database error:', error);
      throw error;
    }
    
    if (!data || !count) {
      console.log('No data found');
      return { results: [], total: 0, pages: 0 };
    }

    const results: ValidationResult[] = data.map((record: any) => {
      // Convert the date string to a Date object
      const date = new Date(record.created_at);
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', record.created_at);
      }

      return {
        id: record.id,
        date: date,
        fileName: record.file_name || undefined,
        totalEmails: record.total_emails,
        valid: record.valid_emails || [],
        invalid: record.invalid_emails || [],
        risky: record.risky_emails || [],
        disposable: [],
        spamTraps: [],
        creditsUsed: record.credits_used || 0,
        status: record.status as ValidationStatus
      };
    });

    console.log('Processed results:', {
      totalResults: results.length,
      firstResult: results[0] ? {
        id: results[0].id,
        date: results[0].date,
        totalEmails: results[0].totalEmails
      } : null
    });

    return {
      results,
      total: count,
      pages: Math.ceil(count / itemsPerPage)
    };

  } catch (error) {
    console.error('Error fetching validation history:', error);
    return { results: [], total: 0, pages: 0 };
  }
}

export async function downloadValidationReport(id: string): Promise<Blob> {
  try {
    if (process.env.NODE_ENV === 'development') {
      const history = JSON.parse(localStorage.getItem('validationHistory') || '[]');
      const validation = history.find((item: ValidationResult) => item.id === id);
      
      if (!validation) {
        throw new Error('Validation not found');
      }

      const rows = [
        ['Email', 'Status'],
        ...validation.valid.map((email: string) => [email, 'Valid']),
        ...validation.invalid.map((email: string) => [email, 'Invalid']),
        ...validation.risky.map((email: string) => [email, 'Risky'])
      ];

      const csv = rows.map(row => row.join(',')).join('\n');
      return new Blob([csv], { type: 'text/csv' });
    }

    const { data, error } = await supabase
      .from('email_validations')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    const record = data as ValidationRecord;

    const rows = [
      ['Email', 'Status'],
      ...(record.valid_emails || []).map((email: string) => [email, 'Valid']),
      ...(record.invalid_emails || []).map((email: string) => [email, 'Invalid']),
      ...(record.risky_emails || []).map((email: string) => [email, 'Risky'])
    ];

    const csv = rows.map(row => row.join(',')).join('\n');
    return new Blob([csv], { type: 'text/csv' });
  } catch (error) {
    console.error('Error downloading validation report:', error);
    throw error;
  }
}

export const fetchValidationDetails = async (validationId: string): Promise<ValidationRecord> => {
  const { data, error } = await supabase
    .from('email_validations')
    .select('*')
    .eq('id', validationId)
    .single();

  if (error) throw error;
  return data as ValidationRecord;
};