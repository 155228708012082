import React, { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { toast } from '@/components/ui/use-toast';
import type { Database } from '@/types/supabase';

type AdminSettings = Database['public']['Tables']['admin_settings']['Row'];

const GeminiSettings = () => {
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchApiKey();
  }, []);

  const fetchApiKey = async () => {
    try {
      const { data, error } = await supabase
        .from('admin_settings')
        .select('*')
        .eq('key', 'gemini_api_key')
        .single();

      if (error) throw error;
      if (data) {
        setApiKey(data.value as string);
      }
    } catch (error) {
      console.error('Error fetching API key:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch API key. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const saveApiKey = async () => {
    if (!apiKey.trim()) {
      toast({
        title: 'Error',
        description: 'Please enter an API key',
        variant: 'destructive',
      });
      return;
    }

    setLoading(true);
    try {
      const { error } = await supabase
        .from('admin_settings')
        .upsert({
          key: 'gemini_api_key',
          value: apiKey
        }, {
          onConflict: 'key'
        });

      if (error) throw error;

      toast({
        title: 'Success',
        description: 'API key saved successfully',
      });
    } catch (error: any) {
      console.error('Error saving API key:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to save API key',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <Card>
        <CardHeader>
          <CardTitle>Gemini AI Settings</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="apiKey" className="text-sm font-medium">
                API Key
              </label>
              <Input
                id="apiKey"
                type="password"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="Enter your Gemini AI API key"
              />
            </div>
            <Button
              onClick={saveApiKey}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save API Key'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default GeminiSettings;
