import { useState, useEffect } from 'react';
import { Bitcoin, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { CreditPackage } from '@/lib/types';
import { PaymentDialog } from './PaymentDialog';
import { VisaIcon, MastercardIcon, ETHIcon } from '@/components/ui/icons/payment-icons';
import { getCreditPackages } from '@/lib/admin';
import { useToast } from '@/components/ui/use-toast';

export function CreditPackages() {
  const [selectedPackage, setSelectedPackage] = useState<CreditPackage | null>(null);
  const [packages, setPackages] = useState<CreditPackage[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    const loadPackages = async () => {
      try {
        const data = await getCreditPackages();
        // Filter out inactive packages
        const activePackages = data.filter(pkg => pkg.is_active);
        // Map the data and add features
        const mappedPackages: CreditPackage[] = (activePackages || []).map(pkg => {
          let features: string[] = [];
          
          if (pkg.name.toLowerCase().includes('starter')) {
            features = [
              'Perfect for small businesses and startups',
              '1,000 email validations',
              'Real-time validation',
              'Full API access',
              'Advanced validation rules',
              'Priority support',
              'Detailed validation reports'
            ];
          } else if (pkg.name.toLowerCase().includes('pro')) {
            features = [
              'Most popular choice for growing companies',
              '5,000 email validations',
              'Real-time validation',
              'Full API access',
              'Advanced validation rules',
              'Priority support',
              'Detailed validation reports'
            ];
          } else if (pkg.name.toLowerCase().includes('enterprise')) {
            features = [
              'Ideal for large-scale email validation needs',
              '20,000 email validations',
              'Real-time validation',
              'Premium API access',
              'Custom validation rules',
              '24/7 priority support',
              'Advanced analytics'
            ];
          }

          return {
            ...pkg,
            features,
            lemon_variant_id: pkg.lemon_variant_id ?? undefined
          };
        });
        setPackages(mappedPackages);
      } catch (error) {
        console.error('Error loading packages:', error);
        toast({
          title: 'Error',
          description: 'Failed to load credit packages',
          variant: 'destructive',
        });
      } finally {
        setLoading(false);
      }
    };

    loadPackages();
  }, []);

  if (loading) {
    return <div>Loading packages...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="text-center px-4 sm:px-0">
        <h2 className="text-2xl sm:text-3xl font-bold tracking-tight">Choose Your Plan</h2>
        <p className="text-muted-foreground mt-2 text-sm sm:text-base">
          Select the package that best fits your needs
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 px-4 sm:px-0">
        {packages.map((pkg) => (
          <Card 
            key={pkg.id} 
            className="group relative flex flex-col border-primary/20 transition-all duration-300
              hover:border-primary hover:shadow-[0_0_1rem_rgba(var(--primary),.15)]"
          >
            <CardHeader className="space-y-2">
              <CardTitle className="text-lg sm:text-xl group-hover:text-primary transition-colors">{pkg.name}</CardTitle>
              <CardDescription className="text-sm">{pkg.description}</CardDescription>
            </CardHeader>
            <CardContent className="flex-1 space-y-4">
              <div className="text-center">
                <div className="text-3xl sm:text-4xl font-bold group-hover:text-primary transition-colors">
                  ${pkg.price}
                  <span className="text-base sm:text-lg text-muted-foreground font-normal ml-1">
                    /package
                  </span>
                </div>
                <div className="text-base sm:text-lg text-muted-foreground mt-1">
                  {pkg.credits.toLocaleString()} Credits
                </div>
              </div>
              {pkg.features && pkg.features.length > 0 && (
                <ul className="space-y-2">
                  {pkg.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-sm">
                      <Check className="h-4 w-4 text-primary mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              )}
            </CardContent>
            <CardFooter className="flex flex-col space-y-4 pt-4">
              <Button 
                className="w-full py-2 sm:py-6 text-sm sm:text-base transition-all
                  hover:bg-primary hover:text-primary-foreground border-primary/20 hover:border-primary"
                variant="outline"
                onClick={() => setSelectedPackage(pkg)}
              >
                Get Started
              </Button>
              <div className="flex justify-center space-x-3">
                <Bitcoin className="h-5 w-5 sm:h-6 sm:w-6 text-[#F7931A]" />
                <ETHIcon className="h-5 w-5 sm:h-6 sm:w-6 text-[#627EEA] dark:text-[#627EEA]" />
                <VisaIcon className="h-5 w-5 sm:h-6 sm:w-6 dark:text-white" />
                <MastercardIcon className="h-5 w-5 sm:h-6 sm:w-6 dark:text-white" />
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>

      {selectedPackage && (
        <PaymentDialog
          open={true}
          onOpenChange={(open) => !open && setSelectedPackage(null)}
          package={selectedPackage}
        />
      )}
    </div>
  );
}