import { useState, useEffect } from 'react';
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useToast } from '@/components/ui/use-toast';
import { Loader2 } from 'lucide-react';
import type { EmailMarketingIntegration, EmailList } from '@/types/email-marketing';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

interface ExportEmailsProps {
  validationId: string;
  totalEmails: number;
  validCount: number;
}

export function ExportEmails({ validationId, totalEmails, validCount }: ExportEmailsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [integrations, setIntegrations] = useState<EmailMarketingIntegration[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [lists, setLists] = useState<EmailList[]>([]);
  const [selectedList, setSelectedList] = useState('');
  const supabase = createClientComponentClient();
  const { toast } = useToast();

  useEffect(() => {
    if (isOpen) {
      loadIntegrations();
    }
  }, [isOpen]);

  const loadIntegrations = async () => {
    try {
      const { data, error } = await supabase
        .from('email_marketing_integrations')
        .select('*')
        .eq('status', 'connected');

      if (error) throw error;
      setIntegrations(data || []);
    } catch (error) {
      console.error('Error loading integrations:', error);
      toast({
        title: 'Error',
        description: 'Failed to load integrations',
        variant: 'destructive',
      });
    }
  };

  const loadLists = async (platformId: string) => {
    try {
      const { data, error } = await supabase
        .from('email_marketing_lists')
        .select('*')
        .eq('integration_id', platformId);

      if (error) throw error;
      setLists(data || []);
    } catch (error) {
      console.error('Error loading lists:', error);
      toast({
        title: 'Error',
        description: 'Failed to load lists',
        variant: 'destructive',
      });
    }
  };

  const handlePlatformChange = (value: string) => {
    setSelectedPlatform(value);
    setSelectedList('');
    if (value) {
      loadLists(value);
    }
  };

  const handleExport = async () => {
    if (!selectedPlatform || !selectedList) {
      toast({
        title: 'Error',
        description: 'Please select a platform and list',
        variant: 'destructive',
      });
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('/api/email-marketing/export', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          validationId,
          platformId: selectedPlatform,
          listId: selectedList,
        }),
      });

      const result = await response.json();

      if (!response.ok) throw new Error(result.error);

      toast({
        title: 'Success',
        description: result.message,
      });

      setIsOpen(false);
    } catch (error) {
      console.error('Error exporting emails:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to export emails',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary">
          Export Valid Emails ({validCount})
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Export Emails</DialogTitle>
          <DialogDescription>
            Export your validated emails to your email marketing platform
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 py-4">
          {integrations.length === 0 ? (
            <p className="text-sm text-muted-foreground">
              No email marketing platforms connected. Please connect a platform in the Email Marketing section.
            </p>
          ) : (
            <>
              <div className="space-y-2">
                <label className="text-sm font-medium">Select Platform</label>
                <Select value={selectedPlatform} onValueChange={handlePlatformChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a platform" />
                  </SelectTrigger>
                  <SelectContent>
                    {integrations.map((integration) => (
                      <SelectItem key={integration.id} value={integration.id}>
                        {integration.platform.charAt(0).toUpperCase() + integration.platform.slice(1)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {selectedPlatform && (
                <div className="space-y-2">
                  <label className="text-sm font-medium">Select List</label>
                  <Select value={selectedList} onValueChange={setSelectedList}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a list" />
                    </SelectTrigger>
                    <SelectContent>
                      {lists.map((list) => (
                        <SelectItem key={list.id} value={list.id}>
                          {list.name} ({list.subscriber_count} subscribers)
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}

              <Button
                className="w-full"
                onClick={handleExport}
                disabled={loading || !selectedPlatform || !selectedList}
              >
                {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Export {validCount} Emails
              </Button>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
