import { useState, useEffect } from 'react';
import { getUsers, updateUserProfile } from '@/lib/admin';
import { useToast } from '@/components/ui/use-toast';
import { Loader2, UserCog, Check, X, Eye, EyeOff } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from "@/components/ui/badge";
import { Label } from "@/components/ui/label";
import { format } from 'date-fns';
import { createClient } from '@supabase/supabase-js/dist/module';

interface User {
  id: string;
  email: string;
  credits: number;
  created_at: string;
  is_banned: boolean;
  email_validations: {
    count: number;
  };
}

// Add interfaces for the dialogs
interface EditDialogProps {
  user: User | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (userData: { 
    email: string; 
    name: string | null;
    password?: string; // Make password optional
  }) => Promise<void>;
}

interface AdjustCreditsDialogProps {
  user: User | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (amount: number) => Promise<void>;
}

// Edit Dialog Component
function EditUserDialog({ user, open, onOpenChange, onSave }: EditDialogProps) {
  const [email, setEmail] = useState(user?.email || '');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName('');
      setPassword(''); // Reset password when dialog opens
    }
  }, [user]);

  const handleSave = () => {
    // Only include password in the update if it's not empty
    const updateData = {
      email,
      name,
      ...(password ? { password } : {})
    };
    onSave(updateData);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit User</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">New Password (leave empty to keep current)</Label>
            <div className="flex gap-2">
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
              />
              <Button
                type="button"
                variant="outline"
                size="icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Eye className="h-4 w-4" /> : <EyeOff className="h-4 w-4" />}
              </Button>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

// Adjust Credits Dialog Component
function AdjustCreditsDialog({ user, open, onOpenChange, onSave }: AdjustCreditsDialogProps) {
  const [amount, setAmount] = useState('0');

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Adjust Credits</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="amount">Amount (use negative for deduction)</Label>
            <Input
              id="amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount..."
            />
          </div>
          <div className="text-sm text-muted-foreground">
            Current credits: {user?.credits}
          </div>
        </div>
        <DialogFooter>
          <Button onClick={() => onSave(Number(amount))}>Save Changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function UserManagement() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { toast } = useToast();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [adjustCreditsDialogOpen, setAdjustCreditsDialogOpen] = useState(false);

  const loadUsers = async () => {
    try {
      const result = await getUsers(currentPage, 10, searchTerm);
      setUsers(result.users);
      setTotalPages(result.totalPages);
    } catch (error) {
      console.error('Error loading users:', error);
      toast({
        title: "Error",
        description: error instanceof Error 
          ? error.message 
          : "Failed to load users. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Single useEffect for initial load and pagination/search
  useEffect(() => {
    loadUsers();
  }, [currentPage, searchTerm]);

  const handleBanUser = async (userId: string, currentStatus: boolean) => {
    try {
      await updateUserProfile(userId, { is_banned: !currentStatus });
      toast({
        title: "Success",
        description: `User ${currentStatus ? 'unbanned' : 'banned'} successfully`,
      });
      loadUsers(); // Reload the users list
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update user status",
        variant: "destructive",
      });
    }
  };

  const handleViewProfile = (userId: string) => {
    // Implement view profile logic
    console.log('View profile:', userId);
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setEditDialogOpen(true);
  };

  const handleAdjustCredits = (user: User) => {
    setSelectedUser(user);
    setAdjustCreditsDialogOpen(true);
  };

  const handleSaveEdit = async (userData: { 
    email: string; 
    name: string | null; 
    password?: string;
  }) => {
    if (!selectedUser) return;
    
    try {
      // If password is provided, update it using admin API
      if (userData.password) {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.access_token) {
          throw new Error('No valid session');
        }

        // Create admin client with service role
        const supabaseAdmin = createClient(
          import.meta.env.VITE_SUPABASE_URL,
          import.meta.env.VITE_SUPABASE_SERVICE_ROLE_KEY,
          {
            auth: {
              autoRefreshToken: false,
              persistSession: false
            }
          }
        );

        // Update password using admin API
        const { error: authError } = await supabaseAdmin.auth
          .admin.updateUserById(selectedUser.id, {
            password: userData.password,
          });

        if (authError) {
          console.error('Password update error:', authError);
          throw new Error('Failed to update password');
        }
      }

      // Update other user data in user_profiles
      const updateData = {
        email: userData.email,
        name: userData.name,
        updated_at: new Date().toISOString()
      };

      const { error: profileError } = await supabase
        .from('user_profiles')
        .update(updateData)
        .eq('id', selectedUser.id);

      if (profileError) {
        console.error('Profile update error:', profileError);
        throw new Error('Failed to update user profile');
      }

      toast({
        title: "Success",
        description: "User updated successfully",
      });
      setEditDialogOpen(false);
      loadUsers(); // Reload the users list
    } catch (error) {
      console.error('Error updating user:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update user",
        variant: "destructive",
      });
    }
  };

  const handleSaveCredits = async (amount: number) => {
    if (!selectedUser) return;
    
    try {
      const newCredits = selectedUser.credits + amount;
      await updateUserProfile(selectedUser.id, { credits: newCredits });
      toast({
        title: "Success",
        description: "Credits adjusted successfully",
      });
      setAdjustCreditsDialogOpen(false);
      loadUsers(); // Reload the users list
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to adjust credits",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold tracking-tight">User Management</h2>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Users</CardTitle>
          <CardDescription>
            Manage user accounts and permissions
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-4">
            <Input
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-sm"
            />
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Credits</TableHead>
                <TableHead>Total Validations</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <div className="flex items-center space-x-2">
                      <UserCog className="h-4 w-4 text-muted-foreground" />
                      <span>{user.email}</span>
                    </div>
                  </TableCell>
                  <TableCell>{user.credits.toLocaleString()}</TableCell>
                  <TableCell>{user.email_validations?.count?.toLocaleString() || 0}</TableCell>
                  <TableCell>
                    <Badge 
                      variant={user.is_banned ? "destructive" : "secondary"}
                      className="flex items-center gap-1 w-fit"
                    >
                      {user.is_banned ? (
                        <X className="h-3 w-3" />
                      ) : (
                        <Check className="h-3 w-3" />
                      )}
                      {user.is_banned ? 'Inactive' : 'Active'}
                    </Badge>
                  </TableCell>
                  <TableCell className="text-right">
                    <div className="flex justify-end gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleAdjustCredits(user)}
                      >
                        Adjust Credits
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleEdit(user)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant={user.is_banned ? "outline" : "destructive"}
                        size="sm"
                        onClick={() => handleBanUser(user.id, user.is_banned)}
                      >
                        {user.is_banned ? 'Unban' : 'Ban'}
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {users.length === 0 && !isLoading && (
            <div className="text-center py-8 text-muted-foreground">
              No users found
            </div>
          )}

          <div className="flex justify-center gap-2 mt-4">
            <Button
              variant="outline"
              onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              onClick={() => setCurrentPage(p => p + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </CardContent>
      </Card>

      <EditUserDialog
        user={selectedUser}
        open={editDialogOpen}
        onOpenChange={setEditDialogOpen}
        onSave={handleSaveEdit}
      />

      <AdjustCreditsDialog
        user={selectedUser}
        open={adjustCreditsDialogOpen}
        onOpenChange={setAdjustCreditsDialogOpen}
        onSave={handleSaveCredits}
      />
    </div>
  );
} 