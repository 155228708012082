import { useEffect, useState } from 'react';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { CurrencyOption } from '@/lib/types/currency';
import { useToast } from '@/components/ui/use-toast';
import { getPaymentGateways } from '@/lib/admin';
import { NOWPaymentsService, CurrencyNetwork } from '@/lib/services/nowpayments';

interface CurrencySelectorProps {
  value: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
}

interface PaymentGatewayConfig {
  crypto?: {
    apiKey: string;
    apiSecret?: string;
    network?: string;
    walletAddress?: string;
  };
}

export function CurrencySelector({ value, onValueChange, disabled }: CurrencySelectorProps) {
  const [availableOptions, setAvailableOptions] = useState<CurrencyOption[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    const fetchAvailableCurrencies = async () => {
      try {
        console.log('Starting to fetch available currencies...');
        
        const gateways = await getPaymentGateways();
        console.log('Available gateways:', JSON.stringify(gateways, null, 2));

        // Look for NOWPayments gateway
        const nowPaymentsGateway = gateways.find(g => 
          (g.name.toLowerCase().includes('nowpayments') || g.type.toLowerCase().includes('nowpayments')) 
          && g.is_active
        );
        
        console.log('Found NOWPayments gateway:', JSON.stringify(nowPaymentsGateway, null, 2));

        if (!nowPaymentsGateway) {
          throw new Error('NOWPayments gateway not found or not active');
        }

        // Type assertion for the config object
        const configObj = nowPaymentsGateway.config as PaymentGatewayConfig;
        console.log('Gateway config:', JSON.stringify(configObj, null, 2));

        const apiKey = configObj?.crypto?.apiKey;
        console.log('Found API key:', apiKey ? `${apiKey.slice(0, 4)}...` : 'not found');
        
        if (!apiKey) {
          console.error('Config object structure:', JSON.stringify(configObj, null, 2));
          throw new Error('NOWPayments API key not found in configuration');
        }

        console.log('Creating NOWPayments service...');
        const service = new NOWPaymentsService(apiKey, ''); // We don't need ipnSecret for fetching currencies
        
        console.log('Fetching currencies from NOWPayments...');
        const currencies = await service.getAvailableCurrencies();
        console.log('Received currencies:', currencies);
        
        if (!currencies || currencies.length === 0) {
          console.error('No currencies returned from NOWPayments API');
          toast({
            title: "Error",
            description: "No cryptocurrencies are currently available. Please try again later.",
            variant: "destructive"
          });
          return;
        }
        
        // Transform the currencies into options
        const options: CurrencyOption[] = currencies.map((currency: CurrencyNetwork) => {
          const value = currency.network === 'DEFAULT' 
            ? currency.currency 
            : `${currency.currency}${currency.network}`;
            
          const label = currency.network === 'DEFAULT'
            ? currency.currency
            : `${currency.currency} (${currency.network})`;
            
          return {
            value,
            label,
            network: currency.network
          };
        });

        // Sort options: USDT networks first, then alphabetically
        const sortedOptions = options.sort((a, b) => {
          // Put USDT options first
          if (a.value.startsWith('USDT') && !b.value.startsWith('USDT')) return -1;
          if (!a.value.startsWith('USDT') && b.value.startsWith('USDT')) return 1;
          
          // Then sort alphabetically
          return a.label.localeCompare(b.label);
        });

        console.log('Available currency options:', sortedOptions);
        setAvailableOptions(sortedOptions);
      } catch (error) {
        console.error('Error fetching available currencies:', error);
        const errorMessage = error instanceof Error 
          ? error.message 
          : 'Could not fetch available currencies. Please try again later.';
          
        toast({
          title: 'Warning',
          description: errorMessage,
          variant: 'destructive',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableCurrencies();
  }, [toast]);

  // Group options by base currency (in this case, they're all base currencies)
  const groupedOptions = availableOptions.reduce((groups, option) => {
    const currency = option.label;
    if (!groups[currency]) {
      groups[currency] = [];
    }
    groups[currency].push(option);
    return groups;
  }, {} as Record<string, CurrencyOption[]>);

  return (
    <Select
      value={value}
      onValueChange={onValueChange}
      disabled={disabled || loading}
    >
      <SelectTrigger className="w-[220px]">
        <SelectValue placeholder={loading ? "Loading..." : "Select currency"} />
      </SelectTrigger>
      <SelectContent>
        {Object.entries(groupedOptions).map(([currency, options]) => (
          <SelectGroup key={currency}>
            <SelectItem key={options[0].value} value={options[0].value}>
              {options[0].label}
            </SelectItem>
          </SelectGroup>
        ))}
      </SelectContent>
    </Select>
  );
}
