import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { getCurrentUser } from '@/lib/auth';
import { supabase } from '@/lib/supabase';
import { Loader2, Upload, AlertTriangle } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useNavigate } from 'react-router-dom';
import { Form, FormField } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { User } from '@/lib/types';

interface UserProfile {
  id: string;
  email: string;
  name: string;
  emailVerified: boolean;
  avatar_url: string | null;
  emailNotifications: boolean;
  weeklyReports: boolean;
  marketingEmails: boolean;
  securityAlerts: boolean;
  company_name: string | null;
  billing_address: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  country: string | null;
  vat_number: string | null;
  tax_id: string | null;
}

export function SettingsForm() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [passwords, setPasswords] = useState({
    new: '',
    confirm: '',
  });
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [isSavingBilling, setIsSavingBilling] = useState(false);
  const [profile, setProfile] = useState<UserProfile>({
    id: '',
    email: '',
    name: '',
    emailVerified: false,
    avatar_url: null,
    emailNotifications: false,
    weeklyReports: false,
    marketingEmails: false,
    securityAlerts: false,
    company_name: null,
    billing_address: null,
    city: null,
    state: null,
    postal_code: null,
    country: null,
    vat_number: null,
    tax_id: null
  });

  const form = useForm<UserProfile>({
    defaultValues: profile
  });

  const passwordForm = useForm();

  useEffect(() => {
    loadUserProfile();
  }, []);

  useEffect(() => {
    form.reset(profile);
  }, [profile]);

  const loadUserProfile = async () => {
    try {
      const user = await getCurrentUser();
      
      if (!user) throw new Error('No user found');

      setProfile({
        id: user.id,
        email: user.email,
        name: user.name ?? '',
        emailVerified: user.emailVerified,
        avatar_url: user.avatar_url ?? null,
        emailNotifications: user.email_notifications ?? false,
        weeklyReports: user.weekly_reports ?? false,
        marketingEmails: false,
        securityAlerts: false,
        company_name: user.company_name ?? null,
        billing_address: user.billing_address ?? null,
        city: user.city ?? null,
        state: user.state ?? null,
        postal_code: user.postal_code ?? null,
        country: user.country ?? null,
        vat_number: user.vat_number ?? null,
        tax_id: user.tax_id ?? null
      });

      setIsLoading(false);
    } catch (error) {
      console.error('Error loading user profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to load user profile',
        variant: 'destructive',
      });
      navigate('/');
    }
  };

  const handleAvatarUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) return;

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Upload image to storage
      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}-${Math.random()}.${fileExt}`;
      const { error: uploadError, data } = await supabase.storage
        .from('avatars')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(fileName);

      // Update profile with new avatar URL
      const { error: updateError } = await supabase
        .from('user_profiles')
        .update({
          avatar_url: publicUrl,
          updated_at: new Date().toISOString()
        })
        .eq('id', user.id);

      if (updateError) throw updateError;

      setProfile({ ...profile, avatar_url: publicUrl });
      toast({
        title: 'Success',
        description: 'Avatar updated successfully',
      });
    } catch (error) {
      console.error('Error uploading avatar:', error);
      toast({
        title: 'Error',
        description: 'Failed to upload avatar',
        variant: 'destructive',
      });
    }
  };

  const handleChangePassword = async () => {
    setIsSavingUser(true);
    const { new: newPassword, confirm } = passwords;

    if (newPassword !== confirm) {
      toast({
        title: 'Error',
        description: 'New password and confirmation do not match',
        variant: 'destructive',
      });
      setIsSavingUser(false);
      return;
    }

    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      toast({
        title: 'Error',
        description: error.message,
        variant: 'destructive',
      });
    } else {
      toast({
        title: 'Success',
        description: 'Password changed successfully',
      });
    }
    setIsSavingUser(false);
  };

  const handleAccountDeletion = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Delete user data from profiles
      const { error: profileError } = await supabase
        .from('user_profiles')
        .delete()
        .eq('id', user.id);

      if (profileError) throw profileError;

      // Delete auth user
      const { error: authError } = await supabase.auth.admin.deleteUser(user.id);
      if (authError) throw authError;

      toast({
        title: 'Account Deleted',
        description: 'Your account has been successfully deleted',
      });
      navigate('/login');
    } catch (error) {
      console.error('Error deleting account:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete account',
        variant: 'destructive',
      });
    }
  };

  const handleSaveUserSettings = async (data: UserProfile) => {
    if (!profile.id) return;
    setIsSavingUser(true);
    try {
      const { error } = await supabase
        .from('user_profiles')
        .update({
          email_notifications: data.emailNotifications,
          weekly_reports: data.weeklyReports
        })
        .eq('id', profile.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "User settings saved successfully",
      });
      await loadUserProfile();
    } catch (error) {
      console.error('Error saving user settings:', error);
      toast({
        title: "Error",
        description: "Failed to save user settings",
        variant: "destructive",
      });
    } finally {
      setIsSavingUser(false);
    }
  };

  const handleSaveBillingSettings = async (data: UserProfile) => {
    if (!profile.id) return;
    setIsSavingBilling(true);
    try {
      const { error } = await supabase
        .from('user_profiles')
        .update({
          company_name: data.company_name ?? null,
          billing_address: data.billing_address ?? null,
          city: data.city ?? null,
          state: data.state ?? null,
          postal_code: data.postal_code ?? null,
          country: data.country ?? null,
          vat_number: data.vat_number ?? null,
          tax_id: data.tax_id ?? null,
          updated_at: new Date().toISOString()
        })
        .eq('id', profile.id);

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      // Update local state
      setProfile(prev => ({
        ...prev,
        company_name: data.company_name,
        billing_address: data.billing_address,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        country: data.country,
        vat_number: data.vat_number,
        tax_id: data.tax_id
      }));

      toast({
        title: "Success",
        description: "Billing information saved successfully",
      });
    } catch (error) {
      console.error('Error saving billing settings:', error);
      toast({
        title: "Error",
        description: "Failed to save billing information",
        variant: "destructive",
      });
      // Reset form to previous values on error
      form.reset(profile);
    } finally {
      setIsSavingBilling(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSaveUserSettings)} className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Profile Settings</CardTitle>
              <CardDescription>
                Update your personal information and profile picture
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center space-x-4">
                <Avatar className="h-20 w-20">
                  <AvatarImage src={profile.avatar_url || undefined} />
                  <AvatarFallback>{profile.name.charAt(0)}</AvatarFallback>
                </Avatar>
                <div>
                  <input
                    type="file"
                    id="avatar"
                    className="hidden"
                    accept="image/*"
                    onChange={handleAvatarUpload}
                  />
                  <Label htmlFor="avatar">
                    <Button variant="outline" asChild>
                      <span>
                        <Upload className="mr-2 h-4 w-4" />
                        Change Avatar
                      </span>
                    </Button>
                  </Label>
                </div>
              </div>

              {!profile.emailVerified && (
                <div className="flex items-center space-x-2 text-yellow-600 bg-yellow-50 p-2 rounded">
                  <AlertTriangle className="h-4 w-4" />
                  <span className="text-sm">Please verify your email address</span>
                  <Button
                    variant="link"
                    className="text-yellow-600"
                    onClick={async () => {
                      const { error } = await supabase.auth.resend({
                        type: 'signup',
                        email: profile.email,
                      });
                      if (!error) {
                        toast({
                          title: 'Verification Email Sent',
                          description: 'Please check your inbox',
                        });
                      }
                    }}
                  >
                    Resend verification email
                  </Button>
                </div>
              )}

              <div className="flex justify-end">
                <Button 
                  type="submit"
                  disabled={isSavingUser}
                >
                  {isSavingUser ? "Saving..." : "Save User Settings"}
                </Button>
              </div>
            </CardContent>
          </Card>
          <form onSubmit={form.handleSubmit(handleSaveBillingSettings)} className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Billing Information</CardTitle>
              <CardDescription>
                Add your billing details for invoice generation
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="company_name">Company Name</Label>
                  <Input
                    id="company_name"
                    value={profile.company_name || ''}
                    onChange={(e) => setProfile({ ...profile, company_name: e.target.value })}
                    placeholder="Your company name"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="vat_number">VAT Number</Label>
                  <Input
                    id="vat_number"
                    value={profile.vat_number || ''}
                    onChange={(e) => setProfile({ ...profile, vat_number: e.target.value })}
                    placeholder="VAT number (if applicable)"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="tax_id">Tax ID</Label>
                  <Input
                    id="tax_id"
                    value={profile.tax_id || ''}
                    onChange={(e) => setProfile({ ...profile, tax_id: e.target.value })}
                    placeholder="Tax ID or EIN"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="billing_address">Billing Address</Label>
                  <Input
                    id="billing_address"
                    value={profile.billing_address || ''}
                    onChange={(e) => setProfile({ ...profile, billing_address: e.target.value })}
                    placeholder="Street address"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="city">City</Label>
                  <Input
                    id="city"
                    value={profile.city || ''}
                    onChange={(e) => setProfile({ ...profile, city: e.target.value })}
                    placeholder="City"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="state">State/Province</Label>
                  <Input
                    id="state"
                    value={profile.state || ''}
                    onChange={(e) => setProfile({ ...profile, state: e.target.value })}
                    placeholder="State or province"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="postal_code">Postal Code</Label>
                  <Input
                    id="postal_code"
                    value={profile.postal_code || ''}
                    onChange={(e) => setProfile({ ...profile, postal_code: e.target.value })}
                    placeholder="Postal or ZIP code"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="country">Country</Label>
                  <Input
                    id="country"
                    value={profile.country || ''}
                    onChange={(e) => setProfile({ ...profile, country: e.target.value })}
                    placeholder="Country"
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <Button 
                  type="submit"
                  disabled={isSavingBilling}
                >
                  {isSavingBilling ? "Saving..." : "Save Billing Information"}
                </Button>
              </div>
            </CardContent>
          </Card>
        </form>
          <Card>
            <CardHeader>
              <CardTitle>Account Settings</CardTitle>
              <CardDescription>Manage your account settings</CardDescription>
            </CardHeader>
            <CardContent>
              <Form {...passwordForm}>
                <div className="mt-6">
                  <Label>Change Password</Label>
                  <Input
                    type="password"
                    placeholder="New Password"
                    {...passwordForm.register('new')}
                    className="mb-4"
                  />
                  <Input
                    type="password"
                    placeholder="Confirm New Password"
                    {...passwordForm.register('confirm')}
                    className="mb-4"
                  />
                  <Button onClick={handleChangePassword} disabled={isSavingUser} className="w-full">
                    {isSavingUser ? <Loader2 className="animate-spin" /> : 'Change Password'}
                  </Button>
                </div>
              </Form>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Notification Preferences</CardTitle>
              <CardDescription>Configure how you receive notifications</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label>Email Notifications</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive notifications about completed validations
                  </p>
                </div>
                <Switch
                  checked={profile.emailNotifications}
                  onCheckedChange={(checked) =>
                    setProfile({ ...profile, emailNotifications: checked })
                  }
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label>Weekly Reports</Label>
                  <p className="text-sm text-muted-foreground">
                    Get weekly summaries of your validation activities
                  </p>
                </div>
                <Switch
                  checked={profile.weeklyReports}
                  onCheckedChange={(checked) =>
                    setProfile({ ...profile, weeklyReports: checked })
                  }
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label>Marketing Emails</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive updates about new features and promotions
                  </p>
                </div>
                <Switch
                  checked={profile.marketingEmails}
                  onCheckedChange={(checked) =>
                    setProfile({ ...profile, marketingEmails: checked })
                  }
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label>Security Alerts</Label>
                  <p className="text-sm text-muted-foreground">
                    Get notified about important security updates
                  </p>
                </div>
                <Switch
                  checked={profile.securityAlerts}
                  onCheckedChange={(checked) =>
                    setProfile({ ...profile, securityAlerts: checked })
                  }
                />
              </div>
            </CardContent>
          </Card>

          <Card className="border-destructive">
            <CardHeader>
              <CardTitle className="text-destructive">Danger Zone</CardTitle>
              <CardDescription>
                Permanently delete your account and all associated data
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Button
                variant="destructive"
                onClick={() => setShowDeleteDialog(true)}
              >
                Delete Account
              </Button>
            </CardContent>
          </Card>
        </form>

        
      </Form>

      {/* Password Change Dialog */}
      <Dialog open={showPasswordDialog} onOpenChange={setShowPasswordDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Change Password</DialogTitle>
            <DialogDescription>
              Enter your new password
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="new-password">New Password</Label>
              <Input
                id="new-password"
                type="password"
                value={passwords.new}
                onChange={(e) =>
                  setPasswords({ ...passwords, new: e.target.value })
                }
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="confirm-password">Confirm New Password</Label>
              <Input
                id="confirm-password"
                type="password"
                value={passwords.confirm}
                onChange={(e) =>
                  setPasswords({ ...passwords, confirm: e.target.value })
                }
              />
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowPasswordDialog(false)}>
              Cancel
            </Button>
            <Button onClick={handleChangePassword}>Update Password</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Account Dialog */}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Account</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete your
              account and remove your data from our servers.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowDeleteDialog(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleAccountDeletion}>
              Delete Account
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}