import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';
// import { Database } from '@/lib/database.types';
import { useToast } from '@/components/ui/use-toast';

// type AdminUser = Database['public']['Tables']['admin_users']['Row'];

export function AdminRoute({ children }: { children: React.ReactNode }) {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { toast } = useToast();

  useEffect(() => {
    let mounted = true;
    let timeoutId: NodeJS.Timeout;

    const initializeAuth = async () => {
      try {
        // Set a timeout to prevent infinite loading
        timeoutId = setTimeout(() => {
          if (mounted && isLoading) {
            setIsAdmin(false);
            setIsLoading(false);
            toast({
              title: "Session Expired",
              description: "Please sign in again",
              variant: "destructive",
            });
          }
        }, 5000); // 5 seconds timeout

        // Check localStorage first
        const adminSession = localStorage.getItem('adminSession');
        if (!adminSession) {
          if (mounted) {
            setIsAdmin(false);
            setIsLoading(false);
          }
          return;
        }

        // Parse admin session
        const { userId, timestamp } = JSON.parse(adminSession);
        
        // Check if session is expired (24 hours)
        const sessionAge = Date.now() - new Date(timestamp).getTime();
        if (sessionAge > 24 * 60 * 60 * 1000) {
          localStorage.removeItem('adminSession');
          if (mounted) {
            setIsAdmin(false);
            setIsLoading(false);
          }
          return;
        }

        // Verify current session
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user?.id || session.user.id !== userId) {
          localStorage.removeItem('adminSession');
          if (mounted) {
            setIsAdmin(false);
            setIsLoading(false);
          }
          return;
        }

        // Verify admin status
        const { data: adminUser, error: adminError } = await supabase
          .from('admin_users')
          .select('role')
          .eq('id', session.user.id)
          .single();

        if (adminError || !adminUser || !['super_admin', 'admin'].includes(adminUser.role)) {
          localStorage.removeItem('adminSession');
          if (mounted) {
            setIsAdmin(false);
            setIsLoading(false);
          }
          return;
        }

        const isAdminUser = ['super_admin', 'admin'].includes(adminUser.role);
        
        if (mounted) {
          setIsAdmin(isAdminUser);
          if (!isAdminUser) {
            localStorage.removeItem('adminSession');
            toast({
              title: "Access Denied",
              description: "You don't have permission to access the admin panel",
              variant: "destructive",
            });
          }
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        localStorage.removeItem('adminSession');
        if (mounted) {
          setIsAdmin(false);
          toast({
            title: "Authentication Error",
            description: "Please sign in again",
            variant: "destructive",
          });
        }
      } finally {
        clearTimeout(timeoutId);
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    initializeAuth();

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event) => {
      if (event === 'SIGNED_IN') {
        await initializeAuth();
      } else if (event === 'SIGNED_OUT') {
        localStorage.removeItem('adminSession');
        if (mounted) {
          setIsAdmin(false);
          setIsLoading(false);
        }
      }
    });

    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      subscription.unsubscribe();
    };
  }, [location.pathname]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!isAdmin) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
} 