import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useToast } from "@/hooks/use-toast"
import { registerUser, verifyOTP, resendOTP, signInWithGoogle } from "@/lib/auth"
import { OTPVerification } from "./OTPVerification"
import { 
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertCircle, Loader2, ArrowLeft } from 'lucide-react';

export function RegisterForm() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [showOTPVerification, setShowOTPVerification] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const { toast } = useToast()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError('')
    setIsLoading(true)

    try {
      // Validate password match
      if (password !== confirmPassword) {
        toast({
          variant: "destructive",
          title: "Password mismatch",
          description: "Passwords do not match. Please try again.",
        })
        throw new Error('Passwords do not match');
      }

      // Validate name
      if (!name.trim()) {
        toast({
          variant: "destructive",
          title: "Name required",
          description: "Please enter your name to continue.",
        })
        throw new Error('Name is required');
      }

      // Validate password strength
      if (password.length < 8) {
        toast({
          variant: "destructive",
          title: "Weak password",
          description: "Password must be at least 8 characters long.",
        })
        throw new Error('Password must be at least 8 characters long');
      }

      await registerUser(email, password, name)
      setShowOTPVerification(true)
      toast({
        title: "Verification code sent",
        description: "Please check your email for the verification code",
      })
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "Registration failed. Please try again."
      toast({
        variant: "destructive",
        title: "Registration failed",
        description: errorMessage,
      })
      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleVerification = async (token: string) => {
    try {
      const { user, session } = await verifyOTP(email, token, password, name)
      if (user && session) {
        navigate('/dashboard')
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Verification failed",
        description: error instanceof Error ? error.message : "Please try again",
      })
    }
  }

  const handleResendOTP = async () => {
    try {
      await resendOTP(email)
      toast({
        title: "Code resent",
        description: "A new verification code has been sent to your email",
      })
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Failed to resend code",
        description: error instanceof Error ? error.message : "Please try again",
      })
    }
  }

  const handleGoogleRegister = async () => {
    try {
      setIsLoading(true);
      await signInWithGoogle();
    } catch (error) {
      console.error('Google registration error:', error);
      toast({
        title: "Registration failed",
        description: error instanceof Error 
          ? error.message 
          : "Failed to register with Google",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (showOTPVerification) {
    return (
      <OTPVerification
        email={email}
        name={name}
        onVerificationComplete={handleVerification}
        onResendOTP={handleResendOTP}
      />
    )
  }

  return (
    <>
      <Card className="w-full shadow-none bg-white border border-solid border-[#d0d5dd]/60 rounded-2xl">
        <div className="md:px-5">
          <CardHeader className="space-y-1 pb-6 mt-4">
            <CardTitle className="text-2xl font-bold text-center">Create an account</CardTitle>
            <p className="text-center text-base text-muted-foreground">
              Enter your details below to create your account
            </p>
          </CardHeader>
          <CardContent className="space-y-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  placeholder="Enter your full name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="h-11 transition-all duration-200 focus:ring-2"
                  disabled={isLoading}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="h-11 transition-all duration-200 focus:ring-2"
                  disabled={isLoading}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="h-11 transition-all duration-200 focus:ring-2"
                  disabled={isLoading}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="confirm-password">Confirm Password</Label>
                <Input
                  id="confirm-password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="h-11 transition-all duration-200 focus:ring-2"
                  disabled={isLoading}
                />
              </div>
              <Button
                type="submit"
                className="w-full h-11 mt-2 font-medium transition-all duration-200 hover:opacity-90"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Creating account...
                  </>
                ) : (
                  'Create Account'
                )}
              </Button>
            </form>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">
                  Or continue with
                </span>
              </div>
            </div>

            <Button
              variant="outline"
              type="button"
              className="w-full h-11"
              onClick={handleGoogleRegister}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <svg className="mr-2 h-4 w-4" viewBox="0 0 24 24">
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                </svg>
              )}
              Continue with Google
            </Button>
          </CardContent>
          <div className="px-8 pb-6 text-center">
            <span className="text-sm text-muted-foreground">
              Already have an account?{' '}
              <Button
                type="button"
                variant="link"
                className="px-0 text-sm font-medium text-primary hover:text-primary/80"
                onClick={() => navigate('/login')}
              >
                Sign in
              </Button>
            </span>
          </div>
        </div>
      </Card>
    </>
  );
}
