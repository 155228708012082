import { useState, useRef, useEffect } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"

interface OTPVerificationProps {
  email: string;
  name: string;
  onVerificationComplete: (token: string) => void;
  onResendOTP: () => Promise<void>;
}

export function OTPVerification({ email, name, onVerificationComplete, onResendOTP }: OTPVerificationProps) {
  const [otp, setOtp] = useState<string[]>(['', '', '', '', '', ''])
  const [isLoading, setIsLoading] = useState(false)
  const [resendTimer, setResendTimer] = useState(30)
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const { toast } = useToast()

  useEffect(() => {
    // Focus first input on mount
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
  }, [])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1)
      }, 1000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [resendTimer])

  const handleInputChange = (index: number, value: string) => {
    if (value.length > 1) {
      value = value[0]
    }
    
    if (!/^\d*$/.test(value)) {
      return
    }

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    // Auto-focus next input
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('text')
    const pastedOTP = pastedData.slice(0, 6).split('')
    
    if (!/^\d+$/.test(pastedData)) {
      return
    }

    const newOtp = [...otp]
    pastedOTP.forEach((digit: string, index: number) => {
      if (index < 6) {
        newOtp[index] = digit
      }
    })
    setOtp(newOtp)

    // Find last filled input
    const lastFilledIndex = newOtp.reduce((lastIndex, digit, index) => 
      digit !== '' ? index : lastIndex, -1
    )

    // Focus next empty input or last input if all filled
    const nextIndex = lastFilledIndex < 5 ? lastFilledIndex + 1 : 5
    inputRefs.current[nextIndex]?.focus()
  }

  const handleVerifyOTP = async () => {
    const otpString = otp.join('')
    if (otpString.length !== 6) {
      toast({
        variant: "destructive",
        title: "Invalid OTP",
        description: "Please enter all 6 digits of the OTP",
      })
      return
    }

    setIsLoading(true)
    try {
      await onVerificationComplete(otpString)
    } catch (error) {
      console.error('OTP verification error:', error);
      // Clear OTP fields on error
      setOtp(['', '', '', '', '', '']);
      // Focus first input
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
      toast({
        variant: "destructive",
        title: "Verification failed",
        description: "Invalid or expired code. Please try again or request a new code.",
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleResendOTP = async () => {
    if (resendTimer > 0) return;
    
    try {
      await onResendOTP()
      // Clear existing OTP fields
      setOtp(['', '', '', '', '', '']);
      // Focus first input
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
      setResendTimer(30)
      toast({
        title: "Code Sent",
        description: "A new verification code has been sent to your email",
      })
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Failed to send code",
        description: "Please try again in a few moments",
      })
    }
  }

  return (
    <div className="mx-auto max-w-[350px] space-y-6 p-6 bg-background rounded-lg border shadow-sm">
      <div className="space-y-2 text-center">
        <h2 className="text-2xl font-semibold tracking-tight">Verify your email</h2>
        <p className="text-sm text-muted-foreground">
          Enter the 6-digit code sent to {email}
        </p>
      </div>

      <div className="flex justify-center gap-2 px-4">
        {otp.map((digit, index) => (
          <Input
            key={index}
            type="text"
            inputMode="numeric"
            maxLength={1}
            ref={(el) => {
              inputRefs.current[index] = el
            }}
            value={digit}
            onChange={e => handleInputChange(index, e.target.value)}
            onKeyDown={e => handleKeyDown(index, e)}
            onPaste={handlePaste}
            className="w-10 h-12 text-center text-lg"
          />
        ))}
      </div>

      <Button
        className="w-full"
        onClick={handleVerifyOTP}
        disabled={isLoading || otp.some(digit => !digit)}
      >
        {isLoading ? "Verifying..." : "Verify Email"}
      </Button>

      <div className="text-center">
        <Button
          variant="link"
          onClick={handleResendOTP}
          disabled={resendTimer > 0}
          className="text-sm"
        >
          {resendTimer > 0
            ? `Resend code in ${resendTimer}s`
            : "Resend verification code"}
        </Button>
      </div>
    </div>
  )
}
