import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Download, Loader2 } from 'lucide-react';
import { downloadInvoice } from '@/api/payments';
import { useToast } from '@/hooks/use-toast';
import { Badge } from '@/components/ui/badge';

interface InvoiceDialogProps {
  isOpen: boolean;
  onClose: () => void;
  paymentId: string;
  paymentDetails: {
    transactionId: string;
    date: string;
    amount: number;
    currency: string;
    packageName?: string;
    credits?: number;
    status: string;
    packageId?: string;
  };
}

export function InvoiceDialog({ isOpen, onClose, paymentId, paymentDetails }: InvoiceDialogProps) {
  const [downloading, setDownloading] = useState(false);
  const { toast } = useToast();

  const handleDownload = async () => {
    try {
      setDownloading(true);
      const blob = await downloadInvoice(paymentId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice-${paymentDetails.transactionId || paymentId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast({
        title: "Success",
        description: "Invoice downloaded successfully",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err instanceof Error ? err.message : "Failed to download invoice",
        variant: "destructive",
      });
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Invoice Details</DialogTitle>
        </DialogHeader>
        <div className="space-y-6">
          {/* Invoice Preview */}
          <div className="bg-muted p-6 rounded-lg space-y-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold">EmailBounce</h3>
                <p className="text-sm text-muted-foreground">Invoice for Credit Purchase</p>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleDownload}
                  disabled={downloading}
                >
                  {downloading ? (
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  ) : (
                    <Download className="h-4 w-4 mr-2" />
                  )}
                  Download PDF
                </Button>
              </div>
            </div>

            <div className="border-t border-border pt-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-medium">Transaction ID</p>
                  <p className="text-sm font-mono">{paymentDetails.transactionId || paymentId}</p>
                </div>
                <div>
                  <p className="text-sm font-medium">Date</p>
                  <p className="text-sm">{paymentDetails.date}</p>
                </div>
                <div>
                  <p className="text-sm font-medium">Status</p>
                  <Badge 
                    variant={
                      paymentDetails.status.toLowerCase() === 'pending' ? 'secondary' :
                      paymentDetails.status.toLowerCase() === 'failed' ? 'destructive' :
                      'default'
                    }
                  >
                    {paymentDetails.status}
                  </Badge>
                </div>
              </div>
            </div>

            <div className="border-t border-border pt-4">
              <h4 className="font-medium mb-2">Purchase Details</h4>
              <div className="space-y-2">
                {paymentDetails.packageName && (
                  <div className="flex justify-between text-sm">
                    <span>Package</span>
                    <span className="font-medium">{paymentDetails.packageName}</span>
                  </div>
                )}
                {paymentDetails.credits && (
                  <div className="flex justify-between text-sm">
                    <span>Credits</span>
                    <span className="font-medium">{paymentDetails.credits.toLocaleString()} credits</span>
                  </div>
                )}
                <div className="flex justify-between text-sm">
                  <span>Amount</span>
                  <span className="font-medium">
                    {(() => {
                      try {
                        const baseCurrency = paymentDetails.currency.split('_')[0].toUpperCase();
                        // For cryptocurrencies or network-specific tokens, use simple format
                        if (baseCurrency.length > 3) {
                          return `${paymentDetails.amount.toFixed(2)} ${baseCurrency}`;
                        }
                        // For standard currencies, use NumberFormat
                        return new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: baseCurrency,
                        }).format(paymentDetails.amount);
                      } catch (error) {
                        // Fallback format
                        return `${paymentDetails.amount.toFixed(2)} ${paymentDetails.currency}`;
                      }
                    })()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
