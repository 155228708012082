import { useEffect, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { supabase } from '@/lib/supabase';
import { Database } from '@/lib/database.types';
import { formatDistanceToNow, format } from 'date-fns';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { Copy, Mail, Search, Send, Calendar, Clock, ChevronDown, ExternalLink } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

type EmailHistory = Database['public']['Tables']['email_history']['Row'];

export default function EmailHistory() {
  const [emails, setEmails] = useState<EmailHistory[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<'all' | 'purpose' | 'recipient' | 'content'>('all');
  const [selectedEmail, setSelectedEmail] = useState<EmailHistory | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    try {
      const { data: userData } = await supabase.auth.getUser();
      if (!userData?.user?.id) return;

      const { data, error } = await supabase
        .from('email_history')
        .select('*')
        .eq('user_id', userData.user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setEmails(data || []);
    } catch (error) {
      console.error('Error fetching emails:', error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      const formattedEmail = formatEmailContent(text);
      await navigator.clipboard.writeText(formattedEmail);
      toast({
        title: "Copied!",
        description: "Formatted email content copied to clipboard",
      });
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const formatEmailContent = (content: string) => {
    const paragraphs = content.split(/\n\s*\n/);
    const formattedParagraphs = paragraphs.map(para => {
      return para.trim().replace(/\s+/g, ' ');
    });
    return formattedParagraphs.join('\n\n');
  };

  const renderEmailContent = (content: string) => {
    const formattedContent = formatEmailContent(content);
    return formattedContent.split('\n\n').map((paragraph, index) => (
      <p key={index} className="mb-4 last:mb-0">
        {paragraph}
      </p>
    ));
  };

  const handleSendEmail = async (email: EmailHistory) => {
    // TODO: Implement email sending functionality
    toast({
      title: "Coming Soon",
      description: "Email sending functionality will be available soon!",
    });
  };

  const getToneBadgeStyle = (tone: string) => {
    const toneStyles: Record<string, { variant: "default" | "secondary" | "destructive" | "outline", className: string }> = {
      "professional": {
        variant: "default",
        className: "bg-blue-100 hover:bg-blue-200 text-blue-700"
      },
      "diplomatic": {
        variant: "default",
        className: "bg-indigo-100 hover:bg-indigo-200 text-indigo-700"
      },
      "urgent": {
        variant: "default",
        className: "bg-rose-100 hover:bg-rose-200 text-rose-700"
      },
      "friendly": {
        variant: "default",
        className: "bg-green-100 hover:bg-green-200 text-green-700"
      },
      "casual": {
        variant: "default",
        className: "bg-yellow-100 hover:bg-yellow-200 text-yellow-700"
      },
      "formal": {
        variant: "default",
        className: "bg-purple-100 hover:bg-purple-200 text-purple-700"
      },
      "assertive": {
        variant: "default",
        className: "bg-red-100 hover:bg-red-200 text-red-700"
      }
    };

    // Convert tone to lowercase for case-insensitive matching
    const normalizedTone = tone.toLowerCase();
    return toneStyles[normalizedTone] || {
      variant: "secondary",
      className: "bg-gray-100 hover:bg-gray-200 text-gray-700"
    };
  };

  const filteredEmails = emails.filter(email => {
    if (!searchTerm) return true;
    const term = searchTerm.toLowerCase();
    
    switch (filter) {
      case 'purpose':
        return email.purpose.toLowerCase().includes(term);
      case 'recipient':
        return email.recipient.toLowerCase().includes(term);
      case 'content':
        return email.generated_email.toLowerCase().includes(term);
      default:
        return (
          email.purpose.toLowerCase().includes(term) ||
          email.recipient.toLowerCase().includes(term) ||
          email.generated_email.toLowerCase().includes(term)
        );
    }
  });

  if (loading) {
    return (
      <div className="container mx-auto p-6 space-y-6">
        <div className="flex justify-between items-center">
          <Skeleton className="h-8 w-48" />
          <div className="flex gap-4">
            <Skeleton className="h-10 w-64" />
            <Skeleton className="h-10 w-40" />
          </div>
        </div>
        <div className="grid gap-4">
          {[1, 2, 3].map((i) => (
            <Card key={i} className="w-full">
              <CardHeader>
                <div className="flex justify-between">
                  <div className="space-y-2">
                    <Skeleton className="h-5 w-48" />
                    <Skeleton className="h-4 w-32" />
                  </div>
                  <Skeleton className="h-8 w-8 rounded-full" />
                </div>
              </CardHeader>
              <CardContent>
                <Skeleton className="h-24 w-full" />
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-primary/10 rounded-lg">
              <Mail className="h-6 w-6 text-primary" />
            </div>
            <div>
              <h2 className="text-lg md:text-2xl font-bold">Email History</h2>
              <p className="text-sm text-muted-foreground">
                {emails.length} email{emails.length !== 1 ? 's' : ''} generated
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto">
            <div className="relative w-full md:w-64">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search emails..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-8"
              />
            </div>
            <Select value={filter} onValueChange={(value: any) => setFilter(value)}>
              <SelectTrigger>
                <SelectValue placeholder="Filter by" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Fields</SelectItem>
                <SelectItem value="purpose">Purpose</SelectItem>
                <SelectItem value="recipient">Recipient</SelectItem>
                <SelectItem value="content">Content</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <ScrollArea className="h-[calc(100vh-12rem)]">
          <div className="grid gap-4">
            {filteredEmails.map((email) => (
              <Card key={email.id} className="group w-full hover:shadow-lg transition-all duration-200 border-l-4 border-l-transparent hover:border-l-primary">
                <CardHeader className="pb-3">
                  <div className="flex justify-between items-start">
                    <div className="space-y-1">
                      <CardTitle className="text-lg font-medium leading-none group-hover:text-primary transition-colors">
                        {email.purpose}
                      </CardTitle>
                      <CardDescription className="flex items-center gap-2 text-sm">
                        <span className="font-medium">To:</span> {email.recipient}
                        <span className="text-muted-foreground/50">•</span>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="flex items-center gap-1">
                              <Clock className="h-3 w-3" />
                              <span>{formatDistanceToNow(new Date(email.created_at), { addSuffix: true })}</span>
                            </TooltipTrigger>
                            <TooltipContent>
                              <Calendar className="h-3 w-3 inline mr-1" />
                              {format(new Date(email.created_at), 'PPpp')}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </CardDescription>
                    </div>
                    <div className="flex items-center gap-2">
                      <Badge 
                        variant={getToneBadgeStyle(email.tone).variant}
                        className={`text-xs ${getToneBadgeStyle(email.tone).className}`}
                      >
                        {email.tone}
                      </Badge>
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                          >
                            <ChevronDown className="h-4 w-4" />
                          </Button>
                        </DialogTrigger>
                        <DialogContent className="max-w-2xl">
                          <DialogHeader>
                            <DialogTitle>{email.purpose}</DialogTitle>
                            <DialogDescription>
                              Created {format(new Date(email.created_at), 'PPpp')}
                            </DialogDescription>
                          </DialogHeader>
                          <Tabs defaultValue="preview" className="mt-4">
                            <TabsList>
                              <TabsTrigger value="preview">Preview</TabsTrigger>
                              <TabsTrigger value="details">Details</TabsTrigger>
                            </TabsList>
                            <TabsContent value="preview" className="mt-4">
                              <div className="prose prose-sm max-w-none">
                                <div className="rounded-lg border bg-card p-6 space-y-4">
                                  <div className="flex items-center justify-between mb-4 pb-4 border-b">
                                    <div>
                                      <div className="font-medium mb-1">To: {email.recipient}</div>
                                      <div className="text-sm text-muted-foreground">Subject: {email.purpose}</div>
                                    </div>
                                    <Badge 
                                      variant={getToneBadgeStyle(email.tone).variant}
                                      className={`${getToneBadgeStyle(email.tone).className}`}
                                    >
                                      {email.tone}
                                    </Badge>
                                  </div>
                                  <div className="leading-relaxed">
                                    {renderEmailContent(email.generated_email)}
                                  </div>
                                </div>
                              </div>
                            </TabsContent>
                            <TabsContent value="details" className="mt-4 space-y-4">
                              <div>
                                <h4 className="text-sm font-semibold mb-1">Key Points</h4>
                                <div className="text-sm text-muted-foreground">
                                  {email.key_points}
                                </div>
                              </div>
                              <div>
                                <h4 className="text-sm font-semibold mb-1">Metadata</h4>
                                <div className="grid grid-cols-2 gap-2 text-sm">
                                  <div>
                                    <span className="text-muted-foreground">Recipient:</span> {email.recipient}
                                  </div>
                                  <div>
                                    <span className="text-muted-foreground">Tone:</span> {email.tone}
                                  </div>
                                  <div>
                                    <span className="text-muted-foreground">Created:</span> {format(new Date(email.created_at), 'PPpp')}
                                  </div>
                                </div>
                              </div>
                            </TabsContent>
                          </Tabs>
                          <div className="flex justify-end gap-2 mt-4">
                            <Button
                              variant="outline"
                              onClick={() => copyToClipboard(email.generated_email)}
                              className="gap-2"
                            >
                              <Copy className="h-4 w-4" /> Copy
                            </Button>
                            <Button onClick={() => handleSendEmail(email)} className="gap-2">
                              <Send className="h-4 w-4" /> Send Email
                            </Button>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="prose prose-sm max-w-none">
                    <div className="text-sm text-muted-foreground space-y-2 line-clamp-3 group-hover:line-clamp-none transition-all duration-200">
                      {renderEmailContent(email.generated_email)}
                    </div>
                    <div className="flex items-center justify-between mt-4 pt-3 border-t">
                      <div className="flex items-center gap-4">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => copyToClipboard(email.generated_email)}
                          className="h-8 gap-2 text-xs"
                        >
                          <Copy className="h-3 w-3" /> Copy
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleSendEmail(email)}
                          className="h-8 gap-2 text-xs"
                        >
                          <Send className="h-3 w-3" /> Send
                        </Button>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-xs text-muted-foreground">Key points:</span>
                        <span className="text-xs text-muted-foreground line-clamp-1 max-w-[300px]">
                          {email.key_points}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
