import { supabase } from '@/lib/supabase';
import { NOWPaymentsService } from '@/lib/services/nowpayments';
import type { CreatePaymentData, PaymentStatus, CreditPackage, PaymentMethod } from '@/lib/types';
import type { BillingProfile } from '@/types/billing';
import html2pdf from 'html2pdf.js';

interface PaymentResponse {
  paymentUrl: string;
  paymentId: string;
}

// Raw database record type (what we get from Supabase)
export interface DatabasePayment {
  id: string;
  user_id: string;
  package_id?: string;
  payment_method: PaymentMethod | 'lemonsqueezy';
  amount: number;
  transaction_id: string;
  status: PaymentStatus;
  currency: string;
  crypto_address: string | null;
  payment_url: string;
  created_at: string;
  updated_at: string;
  pay_address?: string;
  ipn_callback_url?: string;
  success_url?: string;
  cancel_url?: string;
  is_fee_paid_by_user?: boolean;
  fee_amount?: number;
  pay_amount?: number;
  actually_paid?: number;
  credit_package?: CreditPackage;
  payment_provider?: string;
}

// Type for processed payment data with package
export interface Payment extends DatabasePayment {
  credit_package?: CreditPackage;
}

export interface PaymentWithPackage extends DatabasePayment {
  credit_package?: CreditPackage;
}

export async function createPayment(packageId: string, currency: string = 'card'): Promise<PaymentResponse> {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (!session) {
      throw new Error('Unauthorized');
    }

    console.log('Creating payment for package:', packageId);

    const { data: packageData } = await supabase
      .from('credit_packages')
      .select('*')
      .eq('id', packageId)
      .single();

    if (!packageData) {
      console.error('Package not found:', packageId);
      throw new Error('Package not found');
    }

    console.log('Found package:', packageData);

    // Always use 'crypto' for database compatibility
    const paymentMethod: PaymentMethod = 'crypto';
    const actualPaymentMethod: PaymentMethod = currency === 'card' ? 'card' : 'crypto';

    // Create the payment record in our database
    const paymentData: CreatePaymentData = {
      user_id: session.user.id,
      package_id: packageId,
      payment_method: paymentMethod, // Use 'crypto' for database
      amount: packageData.price,
      transaction_id: '', // Will be updated later by webhook
      status: 'pending',
      currency: currency,
      crypto_address: '', // Empty string for card payments
      payment_url: '', // Will be updated later by webhook
    };

    const { data: payment, error: paymentError } = await supabase
      .from('payments')
      .insert({
        user_id: paymentData.user_id,
        package_id: paymentData.package_id,
        payment_method: paymentMethod, // Force to 'crypto' for Supabase
        amount: paymentData.amount,
        transaction_id: paymentData.transaction_id,
        status: paymentData.status,
        currency: paymentData.currency,
        crypto_address: '', // Empty string for card payments
        payment_url: paymentData.payment_url,
        // Add a custom field to track the actual payment method
        payment_provider: actualPaymentMethod === 'card' ? 'lemonsqueezy' : 'nowpayments',
      })
      .select('*')
      .single();

    if (paymentError) {
      console.error('Error creating payment record:', paymentError);
      throw new Error('Failed to create payment record');
    }

    // For card payment, return the Lemonsqueezy checkout URL
    if (actualPaymentMethod === 'card') {
      // Validate Lemonsqueezy variant ID
      if (!packageData.lemon_variant_id) {
        throw new Error('Lemonsqueezy variant ID not found for this package');
      }

      // Use custom subdomain instead of default Lemonsqueezy subdomain
      const checkoutUrl = `https://pay.emailbounce.org/buy/${packageData.lemon_variant_id}`;
      
      return {
        paymentUrl: checkoutUrl,
        paymentId: payment.id,
      };
    }

    // For crypto, use NOWPayments as before
    const nowPayments = new NOWPaymentsService(
      import.meta.env.VITE_NOWPAYMENTS_API_KEY,
      import.meta.env.VITE_NOWPAYMENTS_IPN_SECRET
    );

    const nowPaymentResponse = await nowPayments.createPayment({
      price_amount: packageData.price,
      price_currency: 'USD',
      pay_currency: currency,
      order_id: payment.id,
      order_description: `Purchase of ${packageData.credits} credits`,
      ipn_callback_url: `${import.meta.env.VITE_API_URL}/api/payments/webhook`,
      success_url: `${import.meta.env.VITE_FRONTEND_URL}/payment/success`,
      cancel_url: `${import.meta.env.VITE_FRONTEND_URL}/payment/cancel`,
      is_fee_paid_by_user: true,
    });

    console.log('NOWPayments response:', nowPaymentResponse);

    if (!nowPaymentResponse.payment_url) {
      throw new Error('No payment URL received from NOWPayments');
    }

    // Update the payment record with NOWPayments details
    await supabase
      .from('payments')
      .update({
        transaction_id: nowPaymentResponse.payment_id,
        crypto_address: nowPaymentResponse.pay_address,
        payment_url: nowPaymentResponse.payment_url,
      })
      .eq('id', payment.id);

    // Return the payment URL and ID
    return {
      paymentUrl: nowPaymentResponse.payment_url,
      paymentId: payment.id,
    };
  } catch (error) {
    console.error('Payment creation error:', error);
    throw error;
  }
}

export async function retryPayment(paymentId: string, currency: string): Promise<PaymentResponse> {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (!session) {
      throw new Error('Unauthorized');
    }

    // Get the existing payment record
    const { data, error: fetchError } = await supabase
      .from('payments')
      .select(`
        *,
        credit_package:credit_packages!payments_package_id_fkey (
          id,
          name,
          description,
          credits,
          price,
          lemon_variant_id
        )
      `)
      .eq('id', paymentId)
      .single();

    if (fetchError || !data) {
      throw new Error('Payment not found');
    }

    const existingPayment = data;
    
    // Ensure credit_package is properly typed
    const creditPackage = existingPayment.credit_package ? 
      (Array.isArray(existingPayment.credit_package) ? existingPayment.credit_package[0] : existingPayment.credit_package)
      : undefined;

    if (!creditPackage) {
      throw new Error('Credit package not found');
    }

    // Determine payment method based on currency
    const paymentMethod: PaymentMethod = currency === 'card' ? 'card' : 'crypto';

    // For Lemonsqueezy (card), return the Lemonsqueezy checkout URL
    if (paymentMethod === 'card') {
      // Validate Lemonsqueezy variant ID
      if (!creditPackage.lemon_variant_id) {
        throw new Error('Lemonsqueezy variant ID not found for this package');
      }

      const checkoutUrl = `https://emailbounce.lemonsqueezy.com/buy/${creditPackage.lemon_variant_id}`;
      
      return {
        paymentUrl: checkoutUrl,
        paymentId: existingPayment.id,
      };
    }

    // Create new NOWPayments invoice
    const nowPayments = new NOWPaymentsService(
      import.meta.env.VITE_NOWPAYMENTS_API_KEY,
      import.meta.env.VITE_NOWPAYMENTS_IPN_SECRET
    );

    const nowPaymentResponse = await nowPayments.createPayment({
      price_amount: creditPackage.price,
      price_currency: 'USD',
      pay_currency: currency,
      order_id: existingPayment.id,
      order_description: `Purchase of ${creditPackage.credits} credits`,
      ipn_callback_url: `${import.meta.env.VITE_API_URL}/api/payments/webhook`,
      success_url: `${import.meta.env.VITE_FRONTEND_URL}/payment/success`,
      cancel_url: `${import.meta.env.VITE_FRONTEND_URL}/payment/cancel`,
      is_fee_paid_by_user: true,
    });

    console.log('NOWPayments response:', nowPaymentResponse);

    if (!nowPaymentResponse.payment_url) {
      throw new Error('No payment URL received from NOWPayments');
    }

    // Update the payment record with NOWPayments details
    await supabase
      .from('payments')
      .update({
        transaction_id: nowPaymentResponse.payment_id,
        crypto_address: nowPaymentResponse.pay_address,
        payment_url: nowPaymentResponse.payment_url,
        payment_method: 'crypto',
      })
      .eq('id', existingPayment.id);

    // Return the payment URL and ID
    return {
      paymentUrl: nowPaymentResponse.payment_url,
      paymentId: existingPayment.id,
    };
  } catch (error) {
    console.error('Payment retry error:', error);
    throw error;
  }
}

export async function getPaymentHistory(): Promise<Payment[]> {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (!session) {
      throw new Error('Unauthorized');
    }

    // Get payment history with credit package details
    const { data, error } = await supabase
      .from('payments')
      .select(`
        *,
        credit_package:credit_packages!payments_package_id_fkey (
          id,
          name,
          description,
          credits,
          price
        )
      `)
      .eq('user_id', session.user.id)
      .order('created_at', { ascending: false });

    if (error) {
      throw error;
    }

    if (!data) {
      return [];
    }

    // Transform the data to ensure credit_package is handled correctly
    return data.map((dbPayment: any) => {
      return {
        id: dbPayment.id,
        user_id: dbPayment.user_id,
        package_id: dbPayment.package_id,
        payment_method: dbPayment.payment_method,
        amount: dbPayment.amount,
        status: dbPayment.status,
        currency: dbPayment.currency,
        crypto_address: dbPayment.crypto_address || '',
        transaction_id: dbPayment.transaction_id,
        payment_url: dbPayment.payment_url || '',
        pay_address: dbPayment.pay_address || '',
        created_at: dbPayment.created_at,
        updated_at: dbPayment.updated_at,
        credit_package: dbPayment.credit_package,
        ipn_callback_url: dbPayment.ipn_callback_url || '',
        success_url: dbPayment.success_url || '',
        cancel_url: dbPayment.cancel_url || '',
        is_fee_paid_by_user: dbPayment.is_fee_paid_by_user || false,
        fee_amount: dbPayment.fee_amount || 0,
        pay_amount: dbPayment.pay_amount || 0,
        actually_paid: dbPayment.actually_paid || 0,
        payment_provider: dbPayment.payment_provider || '',
      };
    });
  } catch (error) {
    console.error('Error fetching payment history:', error);
    throw error;
  }
}

export async function downloadInvoice(paymentId: string): Promise<Blob> {
  console.log('Starting invoice download for payment:', paymentId);
  type PaymentWithCreditPackage = DatabasePayment & {
    credit_package: CreditPackage;
  };

  const { data, error } = await supabase
    .from('payments')
    .select(`
      *,
      credit_package:credit_packages!payments_package_id_fkey (
        id,
        name,
        description,
        credits,
        price
      )
    `)
    .eq('id', paymentId)
    .single<PaymentWithCreditPackage>();

  if (error) {
    throw new Error('Failed to fetch payment details');
  }

  if (!data) {
    throw new Error('Payment not found');
  }

  const payment = data;
  console.log('Payment data fetched:', payment);

  // Helper functions for credit package
  function getCreditPackageName(pkg: CreditPackage | null | undefined): string {
    return pkg?.name || 'Credit Package';
  }

  function getCreditPackageDescription(pkg: CreditPackage | null | undefined): string {
    return pkg?.description || 'Email verification credits';
  }

  function formatCurrency(amount: number): string {
    try {
      // For network-specific cryptocurrencies (e.g., USDT_MATIC), use only the base currency
      const baseCurrency = payment.currency.split('_')[0].toUpperCase();
      
      // For cryptocurrencies, just show the amount with the currency code
      if (baseCurrency.length > 3 || !CURRENCY_OPTIONS.some(c => c.value.toUpperCase() === baseCurrency)) {
        return `${amount.toFixed(2)} ${baseCurrency}`;
      }

      // For standard fiat currencies, use NumberFormat
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: baseCurrency
      }).format(amount);
    } catch (error) {
      // Fallback format if anything goes wrong
      return `${amount.toFixed(2)} ${payment.currency}`;
    }
  }

const logoUrl = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDAgODAiPjxwYXRoIGZpbGw9IiMwMDAiIGQ9Ik00My40IDQyLjFjLS4zLTIuNS0xLjYtNC42LTMuNy02LjEtMi4xLTEuNS00LjctMi4yLTcuMy0yLjItMi43IDAtNS4zLjgtNy40IDIuMi0yLjEgMS41LTMuNCAzLjYtMy43IDYuMS0uMyAyLjUuNiA0LjggMi40IDYuNyAxLjggMS45IDQuMyAyLjkgNi45IDIuOSAyLjYgMCA1LjEtMSA2LjktMi45IDEuOS0xLjkgMi43LTQuMyAyLjQtNi43ek0yOS43IDE4LjJoNi45djQuNWMxLjQtMS42IDMuMS0yLjkgNS4xLTMuOCAyLTEgNC4yLTEuNSA2LjQtMS41IDQuNiAwIDguNSAxLjYgMTEuNyA0LjggMy4yIDMuMiA0LjggNy4xIDQuOCAxMS43cy0xLjYgOC41LTQuOCAxMS43Yy0zLjIgMy4yLTcuMSA0LjgtMTEuNyA0LjhzLTguNS0xLjYtMTEuNy00LjhjLTMuMi0zLjItNC44LTcuMS00LjgtMTEuN3MxLjYtOC41IDQuOC0xMS43YzMuMi0zLjIgNy4xLTQuOCAxMS43LTQuOHM4LjUgMS42IDExLjcgNC44eiIvPjxwYXRoIGZpbGw9IiMwMDcyYzYiIGQ9Ik0xNjAuNCAyOS45Yy0yLjEtMi4xLTQuNy0zLjItNy43LTMuMnMtNS42IDEuMS03LjcgMy4yYy0yLjEgMi4xLTMuMiA0LjctMy4yIDcuN3MxLjEgNS42IDMuMiA3LjdjMi4xIDIuMSA0LjcgMy4yIDcuNyAzLjJzNS42LTEuMSA3LjctMy4yYzIuMS0yLjEgMy4yLTQuNyAzLjItNy43cy0xLjEtNS42LTMuMi03Ljd6bTUuNS0zLjljMy4yIDMuMiA0LjggNy4xIDQuOCAxMS43cy0xLjYgOC41LTQuOCAxMS43Yy0zLjIgMy4yLTcuMSA0LjgtMTEuNyA0LjhzLTguNS0xLjYtMTEuNy00LjhjLTMuMi0zLjItNC44LTcuMS00LjgtMTEuN3MxLjYtOC41IDQuOC0xMS43YzMuMi0zLjIgNy4xLTQuOCAxMS43LTQuOHM4LjUgMS42IDExLjcgNC44eiIvPjxwYXRoIGZpbGw9IiMwMDcyYzYiIGQ9Ik0xOTUuOSAyOS45Yy0yLjEtMi4xLTQuNy0zLjItNy43LTMuMnMtNS42IDEuMS03LjcgMy4yYy0yLjEgMi4xLTMuMiA0LjctMy4yIDcuN3MxLjEgNS42IDMuMiA3LjdjMi4xIDIuMSA0LjcgMy4yIDcuNyAzLjJzNS42LTEuMSA3LjctMy4yYzIuMS0yLjEgMy4yLTQuNyAzLjItNy43cy0xLjEtNS42LTMuMi03Ljd6bTUuNS0zLjljMy4yIDMuMiA0LjggNy4xIDQuOCAxMS43cy0xLjYgOC41LTQuOCAxMS43Yy0zLjIgMy4yLTcuMSA0LjgtMTEuNyA0LjhzLTguNS0xLjYtMTEuNy00LjhjLTMuMi0zLjItNC44LTcuMS00LjgtMTEuN3MxLjYtOC41IDQuOC0xMS43YzMuMi0zLjIgNy4xLTQuOCAxMS43LTQuOHM4LjUgMS42IDExLjcgNC44eiIvPjwvc3ZnPg==';

  const CURRENCY_OPTIONS = [
    { value: 'usd', label: 'USD' },
    { value: 'eur', label: 'EUR' },
    { value: 'gbp', label: 'GBP' },
    { value: 'jpy', label: 'JPY' },
    { value: 'aud', label: 'AUD' },
    { value: 'cad', label: 'CAD' },
    { value: 'chf', label: 'CHF' },
    { value: 'cny', label: 'CNY' },
    { value: 'sek', label: 'SEK' },
    { value: 'nok', label: 'NOK' },
    { value: 'dkk', label: 'DKK' },
    { value: 'inr', label: 'INR' },
    { value: 'rub', label: 'RUB' },
    { value: 'try', label: 'TRY' },
    { value: 'zar', label: 'ZAR' },
    { value: 'brl', label: 'BRL' },
    { value: 'mxn', label: 'MXN' },
    { value: 'krw', label: 'KRW' },
    { value: 'sgd', label: 'SGD' },
    { value: 'thb', label: 'THB' },
    { value: 'myr', label: 'MYR' },
    { value: 'php', label: 'PHP' },
    { value: 'idr', label: 'IDR' },
    { value: 'vnd', label: 'VND' },
    { value: 'bgn', label: 'BGN' },
    { value: 'czk', label: 'CZK' },
    { value: 'hrk', label: 'HRK' },
    { value: 'ron', label: 'RON' },
    { value: 'huf', label: 'HUF' },
    { value: 'pln', label: 'PLN' },
    { value: 'ils', label: 'ILS' },
    { value: 'isk', label: 'ISK' },
    { value: 'all', label: 'ALL' },
    { value: 'bam', label: 'BAM' },
    { value: 'bdt', label: 'BDT' },
    { value: 'bgn', label: 'BGN' },
    { value: 'bob', label: 'BOB' },
    { value: 'bzd', label: 'BZD' },
    { value: 'cop', label: 'COP' },
    { value: 'crc', label: 'CRC' },
    { value: 'cuc', label: 'CUC' },
    { value: 'cup', label: 'CUP' },
    { value: 'dop', label: 'DOP' },
    { value: 'dzd', label: 'DZD' },
    { value: 'egp', label: 'EGP' },
    { value: 'gtq', label: 'GTQ' },
    { value: 'hnl', label: 'HNL' },
    { value: 'jmd', label: 'JMD' },
    { value: 'kes', label: 'KES' },
    { value: 'lbp', label: 'LBP' },
    { value: 'mad', label: 'MAD' },
    { value: 'mdl', label: 'MDL' },
    { value: 'mga', label: 'MGA' },
    { value: 'mkd', label: 'MKD' },
    { value: 'mmk', label: 'MMK' },
    { value: 'mnt', label: 'MNT' },
    { value: 'mop', label: 'MOP' },
    { value: 'mvr', label: 'MVR' },
    { value: 'mur', label: 'MUR' },
    { value: 'mzn', label: 'MZN' },
    { value: 'nad', label: 'NAD' },
    { value: 'ngn', label: 'NGN' },
    { value: 'nio', label: 'NIO' },
    { value: 'npr', label: 'NPR' },
    { value: 'pen', label: 'PEN' },
    { value: 'pgk', label: 'PGK' },
    { value: 'php', label: 'PHP' },
    { value: 'pkr', label: 'PKR' },
    { value: 'pyg', label: 'PYG' },
    { value: 'qar', label: 'QAR' },
    { value: 'ron', label: 'RON' },
    { value: 'rsd', label: 'RSD' },
    { value: 'rub', label: 'RUB' },
    { value: 'rwf', label: 'RWF' },
    { value: 'sar', label: 'SAR' },
    { value: 'sbd', label: 'SBD' },
    { value: 'scr', label: 'SCR' },
    { value: 'sdg', label: 'SDG' },
    { value: 'sek', label: 'SEK' },
    { value: 'sgd', label: 'SGD' },
    { value: 'shp', label: 'SHP' },
    { value: 'sll', label: 'SLL' },
    { value: 'sos', label: 'SOS' },
    { value: 'spl', label: 'SPL' },
    { value: 'srd', label: 'SRD' },
    { value: 'std', label: 'STD' },
    { value: 'svc', label: 'SVC' },
    { value: 'szl', label: 'SZL' },
    { value: 'thb', label: 'THB' },
    { value: 'tjs', label: 'TJS' },
    { value: 'tmt', label: 'TMT' },
    { value: 'tnd', label: 'TND' },
    { value: 'top', label: 'TOP' },
    { value: 'try', label: 'TRY' },
    { value: 'ttd', label: 'TTD' },
    { value: 'twd', label: 'TWD' },
    { value: 'tzs', label: 'TZS' },
    { value: 'uah', label: 'UAH' },
    { value: 'ugx', label: 'UGX' },
    { value: 'usd', label: 'USD' },
    { value: 'usn', label: 'USN' },
    { value: 'uss', label: 'USS' },
    { value: 'uys', label: 'UYS' },
    { value: 'uzs', label: 'UZS' },
    { value: 'vef', label: 'VEF' },
    { value: 'vnd', label: 'VND' },
    { value: 'vuv', label: 'VUV' },
    { value: 'wst', label: 'WST' },
    { value: 'xaf', label: 'XAF' },
    { value: 'xcd', label: 'XCD' },
    { value: 'xof', label: 'XOF' },
    { value: 'xpf', label: 'XPF' },
    { value: 'yer', label: 'YER' },
    { value: 'zar', label: 'ZAR' },
    { value: 'zmw', label: 'ZMW' },
    { value: 'zwl', label: 'ZWL' },
  ];

  const getCurrencyLabel = (currency: string): string => {
    const option = CURRENCY_OPTIONS.find(opt => opt.value === currency.toLowerCase());
    return option ? option.label : currency.toUpperCase();
  };

  // Get user profile with billing information
  const { data: userProfile, error: userError } = await supabase
    .from('user_profiles')
    .select('*')
    .eq('id', payment.user_id)
    .single<BillingProfile>();

  if (userError) {
    console.error('Error fetching user profile:', userError);
    throw new Error('Failed to fetch user profile');
  }

  const invoiceHtml = `
    <!DOCTYPE html>
    <html>
    <head>
      <meta charset="UTF-8">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
        
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
        
        body { 
          font-family: 'Inter', sans-serif;
          color: #1f2937;
          line-height: 1.5;
          background: #fff;
          padding: 40px;
        }

        .invoice-container {
          max-width: 800px;
          margin: 0 auto;
          border: 1px solid #e5e7eb;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          background: #fff;
        }

        .header {
          padding: 32px;
          border-bottom: 1px solid #e5e7eb;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }

        .logo {
          height: 40px;
          width: auto;
        }

        .invoice-label {
          text-align: right;
        }

        .invoice-label h1 {
          font-size: 24px;
          font-weight: 700;
          color: #111827;
          margin-bottom: 4px;
        }

        .invoice-label p {
          color: #6b7280;
          font-size: 14px;
        }

        .info-section {
          padding: 32px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 48px;
          border-bottom: 1px solid #e5e7eb;
          background: #f9fafb;
        }

        .info-group h2 {
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: #6b7280;
          margin-bottom: 12px;
          font-weight: 600;
        }

        .info-group p {
          font-size: 14px;
          color: #111827;
          margin-bottom: 4px;
        }

        .items-section {
          padding: 32px;
        }

        table {
          width: 100%;
          border-collapse: collapse;
        }

        th {
          background: #f9fafb;
          padding: 12px;
          text-align: left;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: #6b7280;
          border-bottom: 1px solid #e5e7eb;
        }

        td {
          padding: 16px 12px;
          font-size: 14px;
          border-bottom: 1px solid #e5e7eb;
        }

        .total-section {
          padding: 24px 32px;
          background: #f9fafb;
          border-top: 1px solid #e5e7eb;
        }

        .total-row {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;
        }

        .total-row.final {
          font-weight: 600;
          font-size: 16px;
          border-top: 1px solid #e5e7eb;
          margin-top: 8px;
          padding-top: 16px;
        }
      </style>
    </head>
    <body>
      <div class="invoice-container">
        <div class="header">
          <img src="${logoUrl}" alt="EmailBounce" class="logo">
          <div class="invoice-label">
            <h1>INVOICE</h1>
            <p>Invoice #${payment.id.slice(0, 8).toUpperCase()}</p>
            <p>${new Date(payment.created_at).toLocaleDateString()}</p>
          </div>
        </div>

        <div class="info-section">
          <div class="info-group">
            <h2>From</h2>
            <p>EmailBounce</p>
            <p>Email Verification Service</p>
            <p>support@emailbounce.org</p>
          </div>

          <div class="info-group">
            <h2>Bill To</h2>
            ${userProfile.company_name ? `<p>${userProfile.company_name}</p>` : ''}
            ${userProfile.billing_address ? `<p>${userProfile.billing_address}</p>` : ''}
            ${userProfile.city ? `<p>${userProfile.city}${userProfile.state ? `, ${userProfile.state}` : ''} ${userProfile.postal_code || ''}</p>` : ''}
            ${userProfile.country ? `<p>${userProfile.country}</p>` : ''}
            ${userProfile.vat_number ? `<p>VAT: ${userProfile.vat_number}</p>` : ''}
            ${userProfile.tax_id ? `<p>Tax ID: ${userProfile.tax_id}</p>` : ''}
          </div>

          <div class="info-group">
            <h2>Payment Details</h2>
            <p>Transaction ID: ${payment.transaction_id || payment.id}</p>
            <p>Status: ${payment.status}</p>
            <p>Payment Method: ${payment.payment_provider === 'lemonsqueezy' ? 'Card' : 'Crypto'}</p>
          </div>

          <div class="info-group">
            <h2>Order Summary</h2>
            <p>Package: ${getCreditPackageName(payment.credit_package)}</p>
            <p>Credits: ${payment.credit_package?.credits.toLocaleString()} credits</p>
          </div>
        </div>

        <div class="items-section">
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${getCreditPackageDescription(payment.credit_package)}</td>
                <td>${payment.credit_package?.credits.toLocaleString()}</td>
                <td>${formatCurrency(payment.amount / (payment.credit_package?.credits || 1))}</td>
                <td>${formatCurrency(payment.amount)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="total-section">
          <div class="total-row">
            <span>Subtotal</span>
            <span>${formatCurrency(payment.amount)}</span>
          </div>
          ${payment.fee_amount ? `
          <div class="total-row">
            <span>Network Fee</span>
            <span>${formatCurrency(payment.fee_amount)}</span>
          </div>
          ` : ''}
          <div class="total-row final">
            <span>Total</span>
            <span>${formatCurrency(payment.amount + (payment.fee_amount || 0))}</span>
          </div>
        </div>
      </div>
    </body>
    </html>
  `;

  // Convert HTML to PDF using html2pdf
  console.log('Creating PDF from HTML');
  const element = document.createElement('div');
  element.innerHTML = invoiceHtml;
  document.body.appendChild(element);

  try {
    console.log('Starting PDF generation');
    const pdf = await html2pdf().set({
      margin: 0.5,
      filename: `invoice-${paymentId}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { 
        scale: 2,
        logging: true,
        useCORS: true,
        letterRendering: true
      },
      jsPDF: { 
        unit: 'in', 
        format: 'letter', 
        orientation: 'portrait',
        compress: true,
        hotfixes: ['px_scaling']
      }
    }).from(element).outputPdf('blob');
    console.log('PDF generated successfully');
    document.body.removeChild(element);
    return pdf;
  } catch (error) {
    console.error('PDF generation failed:', error);
    document.body.removeChild(element);
    throw new Error('Failed to generate PDF');
  }
}
