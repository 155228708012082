import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/components/ui/use-toast';
import { Loader2, Citrus, Wallet } from 'lucide-react';
import { getPaymentGateways, toggleGatewayStatus, updatePackageVariantIds } from '@/lib/admin';
import { Database } from '@/lib/database.types';
import { ConfigureGatewayDialog } from './ConfigureGatewayDialog';
import { UpdateVariantIds } from './UpdateVariantIds';

type PaymentGateway = Database['public']['Tables']['payment_gateways']['Row'];

export function PaymentGatewayManagement() {
  const [gateways, setGateways] = useState<PaymentGateway[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGateway, setSelectedGateway] = useState<PaymentGateway | null>(null);
  const { toast } = useToast();

  const loadGateways = async () => {
    try {
      setIsLoading(true);
      const data = await getPaymentGateways();
      setGateways(data as PaymentGateway[]);

      // Update package variant IDs
      await updatePackageVariantIds();
    } catch (error) {
      console.error('Error loading payment gateways:', error);
      toast({
        title: 'Error',
        description: 'Failed to load payment gateways',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadGateways();
  }, []);

  const handleStatusToggle = async (gatewayId: string, newStatus: boolean) => {
    try {
      await toggleGatewayStatus(gatewayId, newStatus);
      await loadGateways();
      toast({
        title: 'Status Updated',
        description: `Gateway ${newStatus ? 'activated' : 'deactivated'} successfully`,
      });
    } catch (error) {
      console.error('Error toggling status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update gateway status',
        variant: 'destructive',
      });
    }
  };

  const getGatewayIcon = (type: string) => {
    switch (type) {
      case 'lemonsqueezy':
        return <Citrus className="h-4 w-4" />;
      case 'crypto':
        return <Wallet className="h-4 w-4" />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div>
      <UpdateVariantIds />
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h2 className="text-3xl font-bold tracking-tight">Payment Gateways</h2>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Configure Payment Methods</CardTitle>
            <CardDescription>
              Manage payment gateway integrations and settings
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Gateway</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Last Updated</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {gateways.map((gateway) => (
                  <TableRow key={gateway.id}>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        {getGatewayIcon(gateway.type)}
                        <span className="font-medium">{gateway.name}</span>
                      </div>
                    </TableCell>
                    <TableCell className="capitalize">{gateway.type}</TableCell>
                    <TableCell>
                      <Switch
                        checked={gateway.is_active}
                        onCheckedChange={(checked) =>
                          handleStatusToggle(gateway.id, checked)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(gateway.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setSelectedGateway(gateway)}
                      >
                        Configure
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        {selectedGateway && (
          <ConfigureGatewayDialog
            gateway={selectedGateway}
            open={!!selectedGateway}
            onOpenChange={() => setSelectedGateway(null)}
            onSuccess={loadGateways}
          />
        )}
      </div>
    </div>
  );
} 