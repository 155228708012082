import { Link } from 'react-router-dom';
import { Github, Twitter, Mail } from 'lucide-react';
import { useTheme } from '@/components/theme-provider';

export function Footer() {
  const { theme } = useTheme();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="w-full border-t bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="w-full flex flex-col px-4 py-8 md:px-6 2xl:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {/* Logo and Description */}
          <div className="flex flex-col items-start gap-3">
            <img
              src={theme === 'light' 
                ? "/img/logo-light.svg"
                : "/img/logo-dark.svg"
              }
              alt="EmailCleaner"
              className="h-10 sm:h-11 md:h-12 max-w-[180px] sm:max-w-[200px] md:max-w-[220px] object-contain object-left"
            />
            <p className="text-sm leading-relaxed text-muted-foreground/80 mt-1">
              Professional email validation service to improve your email deliverability and maintain a clean mailing list.
            </p>
          </div>

          {/* Product Links */}
          <div className="flex flex-col gap-3">
            <h3 className="text-sm font-medium">Product</h3>
            <div className="flex flex-col gap-2">
              <Link to="/pricing" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Pricing
              </Link>
              <Link to="/features" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Features
              </Link>
              <Link to="/docs" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Documentation
              </Link>
              <Link to="/api" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                API
              </Link>
            </div>
          </div>

          {/* Company Links */}
          <div className="flex flex-col gap-3">
            <h3 className="text-sm font-medium">Company</h3>
            <div className="flex flex-col gap-2">
              <Link to="/about" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                About Us
              </Link>
              <Link to="/blog" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Blog
              </Link>
              <Link to="/careers" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Careers
              </Link>
              <Link to="/contact" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Contact
              </Link>
            </div>
          </div>

          {/* Legal Links */}
          <div className="flex flex-col gap-3">
            <h3 className="text-sm font-medium">Legal</h3>
            <div className="flex flex-col gap-2">
              <Link to="/privacy" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Privacy Policy
              </Link>
              <Link to="/terms" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Terms of Service
              </Link>
              <Link to="/cookies" className="text-sm text-muted-foreground/80 transition-colors hover:text-primary">
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-10 flex flex-col items-center justify-between gap-6 border-t pt-8 sm:flex-row">
          <p className="text-sm text-muted-foreground/70">
            {currentYear} EmailBounce. All rights reserved.
          </p>
          
          <div className="flex items-center gap-5">
            <a
              href="https://twitter.com/emailbounce"
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted-foreground/70 transition-colors hover:text-primary"
            >
              <Twitter className="h-[18px] w-[18px]" />
              <span className="sr-only">Twitter</span>
            </a>
            <a
              href="https://github.com/emailbounce"
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted-foreground/70 transition-colors hover:text-primary"
            >
              <Github className="h-[18px] w-[18px]" />
              <span className="sr-only">GitHub</span>
            </a>
            <a
              href="mailto:support@emailbounce.org"
              className="text-muted-foreground/70 transition-colors hover:text-primary"
            >
              <Mail className="h-[18px] w-[18px]" />
              <span className="sr-only">Email</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
