import { ReactNode, useEffect } from 'react';
import { AuthSlider } from './AuthSlider';

interface AuthContainerProps {
  children: ReactNode;
}

export function AuthContainer({ children }: AuthContainerProps) {
  // Force light mode for auth pages
  useEffect(() => {
    // Remove dark mode class if present
    document.documentElement.classList.remove('dark');
    
    // Store the original theme preference
    const originalTheme = localStorage.getItem('theme-preference');
    
    return () => {
      // Restore theme preference when component unmounts (user logs in)
      if (originalTheme) {
        localStorage.setItem('theme-preference', originalTheme);
        if (originalTheme === 'dark') {
          document.documentElement.classList.add('dark');
        }
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-white md:bg-[#f3f8ff] relative overflow-y-auto">
      {/* Background gradient - hidden on mobile */}
      <div className="hidden md:block absolute right-0 top-0 w-1/2 h-full bg-[#f3f8ff]" />
      
      {/* Main content container */}
      <div className="relative w-full min-h-screen flex">
        {/* Logo */}
        <div className="absolute top-8 left-8 z-50">
          <img 
            src="/img/logo-light.svg" 
            alt="EmailBounce" 
            className="h-12 md:h-14 w-auto"
          />
        </div>

        {/* Left column */}
        <div className="w-full md:w-1/2 flex flex-col min-h-full bg-white md:rounded-tr-[40px] md:rounded-br-[40px] relative z-10">
          <div className="mt-24 md:mt-32 flex-1 flex items-center justify-center px-4 md:px-8">
            <div className="w-full max-w-sm md:max-w-lg">
              {children}
            </div>
          </div>

          {/* Footer */}
          <div className="w-full max-w-md mx-auto px-4 md:px-8 pb-4 md:pb-6">
            <div className="flex flex-col items-center space-y-2 md:space-y-3 text-xs md:text-sm text-gray-500">
              <div className="flex flex-wrap justify-center gap-x-2 md:gap-x-4">
                <a href="https://emailbounce.org/privacy/" className="hover:text-gray-700 transition-colors">Privacy Policy</a>
                <span>•</span>
                <a href="https://emailbounce.org/terms" className="hover:text-gray-700 transition-colors">Terms of Use</a>
                <span>•</span>
                <a href="https://emailbounce.org/cookies" className="hover:text-gray-700 transition-colors">Cookies Law</a>
                <span>•</span>
                <a href="https://emailbounce.org/legal" className="hover:text-gray-700 transition-colors">Legal</a>
              </div>
              <div className="text-center">
                &copy; {new Date().getFullYear()} EmailBounce. All rights reserved.
              </div>
            </div>
          </div>
        </div>

        {/* Right column - hidden on mobile */}
        <div className="hidden md:block w-1/2">
          <AuthSlider />
        </div>
      </div>
    </div>
  );
}
