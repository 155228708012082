import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/components/ui/use-toast';
import { Loader2, Plus, PackageOpen } from 'lucide-react';
import { getCreditPackages, togglePackageStatus } from '@/lib/admin';
import { CreatePackageDialog } from './CreatePackageDialog';
import { EditPackageDialog } from './EditPackageDialog';

interface CreditPackage {
  id: string;
  name: string;
  description: string;
  credits: number;
  price: number;
  is_active: boolean;
  created_at: string;
}

export function CreditPackageManagement() {
  const [packages, setPackages] = useState<CreditPackage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<CreditPackage | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = async () => {
    try {
      setIsLoading(true);
      const data = await getCreditPackages();
      setPackages(data);
    } catch (error) {
      console.error('Error loading packages:', error);
      toast({
        title: 'Error',
        description: 'Failed to load credit packages',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusToggle = async (packageId: string, newStatus: boolean) => {
    try {
      await togglePackageStatus(packageId, newStatus);
      await loadPackages();
      toast({
        title: 'Status Updated',
        description: `Package ${newStatus ? 'activated' : 'deactivated'} successfully`,
      });
    } catch (error) {
      console.error('Error toggling status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update package status',
        variant: 'destructive',
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold tracking-tight">Credit Packages</h2>
        <Button onClick={() => setShowCreateDialog(true)}>
          <Plus className="mr-2 h-4 w-4" />
          New Package
        </Button>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Manage Packages</CardTitle>
          <CardDescription>
            Configure credit packages and their pricing
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Credits</TableHead>
                <TableHead>Price</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {packages.map((pkg) => (
                <TableRow key={pkg.id}>
                  <TableCell>
                    <div className="flex items-center space-x-2">
                      <PackageOpen className="h-4 w-4 text-muted-foreground" />
                      <div>
                        <div className="font-medium">{pkg.name}</div>
                        <div className="text-sm text-muted-foreground">
                          {pkg.description}
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{pkg.credits.toLocaleString()}</TableCell>
                  <TableCell>${pkg.price}</TableCell>
                  <TableCell>
                    <Switch
                      checked={pkg.is_active}
                      onCheckedChange={(checked) =>
                        handleStatusToggle(pkg.id, checked)
                      }
                    />
                  </TableCell>
                  <TableCell className="text-right">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setSelectedPackage(pkg)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <CreatePackageDialog
        open={showCreateDialog}
        onOpenChange={setShowCreateDialog}
        onSuccess={loadPackages}
      />

      {selectedPackage && (
        <EditPackageDialog
          open={!!selectedPackage}
          onOpenChange={() => setSelectedPackage(null)}
          package={selectedPackage}
          onSuccess={loadPackages}
        />
      )}
    </div>
  );
} 