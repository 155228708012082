import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bitcoin, Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { retryPayment } from '@/api/payments';
import { CurrencySelector } from '@/components/payment/CurrencySelector';
import { type CryptoCurrency } from '@/lib/types/currency';

interface RetryPaymentDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  paymentId: string;
  amount: number;
}

export function RetryPaymentDialog({ open, onOpenChange, paymentId, amount }: RetryPaymentDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CryptoCurrency>('btc');
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleRetryPayment = async () => {
    try {
      setIsLoading(true);
      const { paymentUrl } = await retryPayment(paymentId, selectedCurrency);
      window.location.href = paymentUrl;
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to process payment',
        variant: 'destructive',
      });
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Retry Payment</DialogTitle>
          <DialogDescription>
            Select your preferred cryptocurrency to complete the payment of {amount} credits
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          <div className="space-y-4">
            <CurrencySelector
              value={selectedCurrency}
              onValueChange={setSelectedCurrency}
              disabled={isLoading}
            />
          </div>

          <div className="flex justify-end">
            <Button
              onClick={handleRetryPayment}
              disabled={isLoading}
              className="w-full sm:w-auto"
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <Bitcoin className="mr-2 h-4 w-4" />
                  Pay with Crypto
                </>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
