import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { Loader2, Download, FileText } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { format } from 'date-fns';
import { Badge } from '@/components/ui/badge';

// Update interface to match actual database structure
interface ValidationRecord {
  id: string;
  user_id: string;
  total_emails: number;
  valid_emails: string[];
  invalid_emails: string[];
  risky_emails: string[];
  created_at: string;
}

export function AdminValidationHistory() {
  const [validations, setValidations] = useState<ValidationRecord[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { toast } = useToast();

  const ITEMS_PER_PAGE = 20; // Set number of items per page

  const loadValidations = async () => {
    try {
      setIsLoading(true);
      
      // Calculate pagination range
      const from = (currentPage - 1) * ITEMS_PER_PAGE;
      const to = from + ITEMS_PER_PAGE - 1;
      
      const { data, error, count } = await supabase
        .from('email_validations')
        .select('*', { count: 'exact' })
        .order('created_at', { ascending: false })
        .range(from, to);

      if (error) {
        console.error('Query error:', error);
        throw error;
      }

      // Transform and type check the data
      const transformedData = data?.map(record => ({
        id: record.id,
        user_id: record.user_id,
        total_emails: record.total_emails || 0,
        valid_emails: Array.isArray(record.valid_emails) ? record.valid_emails : [],
        invalid_emails: Array.isArray(record.invalid_emails) ? record.invalid_emails : [],
        risky_emails: Array.isArray(record.risky_emails) ? record.risky_emails : [],
        created_at: record.created_at
      })) || [];

      setValidations(transformedData);
      // Calculate total pages based on count
      setTotalPages(Math.ceil((count || 0) / ITEMS_PER_PAGE));

    } catch (error) {
      console.error('Error loading validations:', error);
      toast({
        title: 'Error',
        description: 'Failed to load validation history',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadValidations();
  }, [currentPage]); // Reload when page changes

  const handleDownload = (type: 'cleaned' | 'original', validation: ValidationRecord) => {
    try {
      const timestamp = format(new Date(validation.created_at), 'yyyy-MM-dd-HHmm');

      if (type === 'cleaned') {
        // Download only valid emails
        const content = validation.valid_emails.join('\n');
        const filename = `valid-emails-${timestamp}.txt`;
        const blob = new Blob([content], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        // Download all emails
        const content = [
          '# Valid Emails',
          ...validation.valid_emails,
          '\n# Invalid Emails',
          ...validation.invalid_emails,
          '\n# Risky Emails',
          ...validation.risky_emails
        ].join('\n');
        
        const filename = `all-emails-${timestamp}.txt`;
        const blob = new Blob([content], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }

      toast({
        title: 'Success',
        description: 'File downloaded successfully',
      });
    } catch (error) {
      console.error('Download error:', error);
      toast({
        title: 'Error',
        description: 'Failed to download file',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Validation History</CardTitle>
          <CardDescription>
            View all email validation records
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Date</TableHead>
                    <TableHead>User ID</TableHead>
                    <TableHead>Total Emails</TableHead>
                    <TableHead>Results</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {validations.map((validation) => (
                    <TableRow key={validation.id}>
                      <TableCell>
                        {format(new Date(validation.created_at), 'PPp')}
                      </TableCell>
                      <TableCell>{validation.user_id}</TableCell>
                      <TableCell>{validation.total_emails}</TableCell>
                      <TableCell>
                        <div className="flex gap-2">
                          <Badge variant="secondary" className="bg-green-100 text-green-800">
                            {validation.valid_emails.length} valid
                          </Badge>
                          <Badge variant="secondary" className="bg-red-100 text-red-800">
                            {validation.invalid_emails.length} invalid
                          </Badge>
                          <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
                            {validation.risky_emails.length} risky
                          </Badge>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-2">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleDownload('cleaned', validation)}
                          >
                            <Download className="h-4 w-4 mr-1" />
                            Valid Only
                          </Button>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleDownload('original', validation)}
                          >
                            <FileText className="h-4 w-4 mr-1" />
                            All Results
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {validations.length === 0 && (
                <div className="text-center py-8 text-muted-foreground">
                  No validations found
                </div>
              )}

              {/* Update pagination UI to show current page */}
              <div className="flex justify-between items-center mt-4">
                <div className="text-sm text-muted-foreground">
                  Page {currentPage} of {totalPages}
                </div>
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setCurrentPage(p => p + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
} 