import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Slider content
const titles = [
  "Your lists, amplified",
  "Smart validation",
  "Results guaranteed"
];

const descriptions = [
  "Email verification that helps you reach more customers.",
  "Advanced algorithms ensure your email lists are clean and valid.",
  "Improve deliverability and reach your target audience effectively."
];

const images = [
  "/img/APP_ONE.jpg",
  "/img/APP_FOUR.jpg",
  "/img/APP_THREE.jpg"
];

export function AuthSlider() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Auto-advance slider
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prev) => (prev + 1) % images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="h-full w-full flex flex-col items-center justify-center px-6">
      {/* Text Content Above Slider */}
      <div className="w-full max-w-[500px] space-y-2 text-center mb-6">
        <motion.h2 
          key={`title-${currentImageIndex}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="text-2xl md:text-3xl font-bold text-gray-900"
        >
          {titles[currentImageIndex]}
        </motion.h2>
        <motion.p
          key={`description-${currentImageIndex}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="text-sm md:text-base text-gray-600 leading-relaxed"
        >
          {descriptions[currentImageIndex]}
        </motion.p>
      </div>

      {/* Image Slider */}
      <div className="relative w-full max-w-[500px] h-[300px] rounded-2xl shadow-lg overflow-hidden bg-white">
        <AnimatePresence mode="wait">
          <motion.div
            key={`bg-${currentImageIndex}`}
            className="absolute inset-0"
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={images[currentImageIndex]}
              alt={`Slide ${currentImageIndex + 1}`}
              className="w-full h-full object-cover"
            />
          </motion.div>
        </AnimatePresence>

        {/* Navigation Dots */}
        <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentImageIndex(index)}
              className={`w-2 h-2 rounded-full transition-all duration-300 ${
                index === currentImageIndex
                  ? 'bg-blue-500 w-4'
                  : 'bg-gray-300 hover:bg-gray-400'
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}