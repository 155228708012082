import React, { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { APIKeys } from '@/components/api/APIKeys';
import { APIGuide } from '@/components/api/APIGuide';
import { supabase } from '@/lib/supabase';
import { APIKey } from '@/lib/api/types';
import { Loader2 } from 'lucide-react';

export default function APIPage() {
  const [activeKey, setActiveKey] = useState<APIKey | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadActiveKey();
  }, []);

  const loadActiveKey = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Use any type here temporarily until we update database types
      const { data, error } = await (supabase as any)
        .from('api_keys')
        .select('*')
        .eq('user_id', user.id)
        .eq('is_active', true)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      
      // Type guard to ensure data matches APIKey interface
      if (data && isAPIKey(data)) {
        setActiveKey(data);
      } else {
        setActiveKey(null);
      }
    } catch (error) {
      console.error('Error loading active API key:', error);
      setActiveKey(null);
    } finally {
      setIsLoading(false);
    }
  };

  // Type guard function to validate APIKey shape
  const isAPIKey = (data: any): data is APIKey => {
    return (
      typeof data.id === 'string' &&
      typeof data.user_id === 'string' &&
      typeof data.key === 'string' &&
      typeof data.name === 'string' &&
      typeof data.created_at === 'string' &&
      (data.last_used_at === null || typeof data.last_used_at === 'string') &&
      typeof data.is_active === 'boolean'
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
      {activeKey ? (
        <Tabs defaultValue="guide" className="space-y-4 sm:space-y-6">
          <TabsList className="w-full sm:w-auto">
            <TabsTrigger value="guide">API Guide</TabsTrigger>
            <TabsTrigger value="keys">API Keys</TabsTrigger>
          </TabsList>

          <TabsContent value="guide">
            <APIGuide apiKey={activeKey.key} />
          </TabsContent>

          <TabsContent value="keys">
            <APIKeys onKeyCreated={loadActiveKey} />
          </TabsContent>
        </Tabs>
      ) : (
        <div className="max-w-2xl mx-auto">
          <APIKeys onKeyCreated={loadActiveKey} />
        </div>
      )}
    </div>
  );
}
