import { Card, CardContent } from "@/components/ui/card";
import { Bitcoin, CreditCard, Zap } from "lucide-react";

export function PaymentInfo() {
  return (
    <Card className="mt-4 bg-background/60 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <CardContent className="p-8">
        <div className="grid gap-10 md:grid-cols-3">
          {/* Payment Methods */}
          <div className="group rounded-lg p-4 transition-all hover:bg-primary/5 hover:shadow-sm">
            <h3 className="flex items-center gap-2.5 text-lg font-medium group-hover:text-primary">
              <CreditCard className="h-5 w-5 text-primary dark:text-white transition-colors group-hover:text-primary" />
              Payment Methods
            </h3>
            <p className="mt-2.5 text-sm leading-relaxed text-muted-foreground group-hover:text-primary/90">
              Accept all major credit cards and over 2,000 cryptocurrencies with secure, PCI-compliant processing.
            </p>
          </div>

          {/* Instant Credits */}
          <div className="group rounded-lg p-4 transition-all hover:bg-primary/5 hover:shadow-sm">
            <h3 className="flex items-center gap-2.5 text-lg font-medium group-hover:text-primary">
              <Zap className="h-5 w-5 text-primary transition-colors group-hover:text-primary" />
              Instant Credits
            </h3>
            <p className="mt-2.5 text-sm leading-relaxed text-muted-foreground group-hover:text-primary/90">
              Credits are automatically added to your account after successful payment. Start validating emails immediately.
            </p>
          </div>

          {/* Crypto Support */}
          <div className="group rounded-lg p-4 transition-all hover:bg-primary/5 hover:shadow-sm">
            <h3 className="flex items-center gap-2.5 text-lg font-medium group-hover:text-primary">
              <Bitcoin className="h-5 w-5 text-primary transition-colors group-hover:text-primary" />
              Crypto Friendly
            </h3>
            <p className="mt-2.5 text-sm leading-relaxed text-muted-foreground group-hover:text-primary/90">
              Support for Bitcoin, Ethereum, and 2000+ other cryptocurrencies with real-time exchange rates.
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
