import disposableDomains from 'disposable-email-domains';
import deepEmailValidator from 'deep-email-validator';
import emailExistence from 'email-existence';
import Mailcheck from 'mailcheck';
import { chunk } from 'lodash';

// Core patterns that worked well
const ROLE_BASED_PREFIXES = new Set([
  'admin', 'info', 'support', 'sales', 'contact',
  'help', 'webmaster', 'postmaster', 'marketing',
  'no-reply', 'noreply', 'abuse', 'bounce', 'billing',
  'careers', 'dev', 'development', 'hello', 'hostmaster',
  'jobs', 'list', 'office', 'recruitment', 'root',
  'security', 'spam', 'sysadmin', 'tech', 'webadmin'
]);

const SPAM_TRAP_PATTERNS = [
  /^spam.*trap/i,
  /^trap.*spam/i,
  /^honey.*pot/i,
  /^pot.*honey/i,
  /^spamtrap/i,
  /^blackhole/i,
  /^drop.*box/i,
  /^abuse.*detect/i
];

const BLACKLISTED_DOMAINS = new Set([
  'tempmail.com', 'throwawaymail.com',
  'mailinator.com', '10minutemail.com',
  'guerrillamail.com', 'sharklasers.com',
  'yopmail.com', 'tempinbox.com',
  'fakeinbox.com', 'trash-mail.com'
]);

// Email validation constants
const MAX_EMAIL_LENGTH = 254;
const MIN_LOCAL_PART_LENGTH = 1;
const MAX_LOCAL_PART_LENGTH = 64;
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// Core validation patterns
const SUSPICIOUS_PATTERNS = {
  REPEATED_CHARS: /(.)\1{4,}/,  // e.g., 'aaaaa'
  SUSPICIOUS_DOTS: /\.{2,}|(?:\.[^@]*){4,}/,  // Multiple dots
  SPECIAL_CHARS: /[._%+-]{3,}/,  // Too many special chars
  NUMERIC_START: /^\d+/,  // Starts with numbers
  LENGTH_CHECK: /^.{1,4}@|^.{25,}@/  // Too short/long local part
};

// Interfaces
export interface ValidationResult {
  isValid: boolean;
  reason?: string;
}

export interface BatchValidationResult {
  valid: string[];
  invalid: string[];
  risky: string[];
  disposable: string[];
  spamTraps: string[];
  totalEmails: number;
}

export interface ValidationProgress {
  processed: number;
  total: number;
  currentBatch: number;
  totalBatches: number;
}

// Core validation function
const validateEmailFormat = (email: string): ValidationResult => {
  if (!email || typeof email !== 'string') {
    return { isValid: false, reason: 'Email cannot be empty' };
  }

  const [localPart, domain] = email.toLowerCase().split('@');

  // Basic format checks
  if (!EMAIL_REGEX.test(email)) {
    return { isValid: false, reason: 'Invalid email format' };
  }

  // Length checks
  if (email.length > MAX_EMAIL_LENGTH) {
    return { isValid: false, reason: 'Email too long' };
  }

  if (localPart.length < MIN_LOCAL_PART_LENGTH || localPart.length > MAX_LOCAL_PART_LENGTH) {
    return { isValid: false, reason: 'Invalid local part length' };
  }

  // Pattern checks
  if (SUSPICIOUS_PATTERNS.REPEATED_CHARS.test(localPart)) {
    return { isValid: false, reason: 'Too many repeated characters' };
  }

  if (SUSPICIOUS_PATTERNS.SUSPICIOUS_DOTS.test(localPart)) {
    return { isValid: false, reason: 'Invalid dot pattern' };
  }

  return { isValid: true };
};

// Main validation functions
export const validateEmail = async (email: string): Promise<ValidationResult> => {
  const formatCheck = validateEmailFormat(email);
  if (!formatCheck.isValid) {
    return formatCheck;
  }

  const [localPart, domain] = email.toLowerCase().split('@');

  // Synchronous checks first (these will be consistent)
  const isRoleBased = ROLE_BASED_PREFIXES.has(localPart);
  const isSpamTrap = SPAM_TRAP_PATTERNS.some(pattern => pattern.test(email));
  const isDisposable = disposableDomains.includes(domain) || BLACKLISTED_DOMAINS.has(domain);
  const hasTypo = SUSPICIOUS_PATTERNS.REPEATED_CHARS.test(localPart) || 
                 SUSPICIOUS_PATTERNS.SUSPICIOUS_DOTS.test(localPart);

  // If any of the basic checks fail, return early
  if (isRoleBased || isSpamTrap || isDisposable || hasTypo) {
    return { 
      isValid: false, 
      reason: isRoleBased ? 'Role-based email' :
              isSpamTrap ? 'Potential spam trap' :
              isDisposable ? 'Disposable email' :
              'Invalid format'
    };
  }

  try {
    // Only do deep validation for non-common domains
    if (!['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'].includes(domain)) {
      try {
        const deepValidation = await deepEmailValidator({
          email,
          validateRegex: true,
          validateMx: false,  // Disable MX checks in production
          validateTypo: true,
          validateDisposable: true,
          validateSMTP: false,
        });

        if (!deepValidation.valid) {
          return { isValid: false, reason: deepValidation.reason };
        }
      } catch (error) {
        console.warn('Deep validation failed, falling back to basic validation:', error);
      }
    }

    return { isValid: true };
  } catch (error) {
    console.warn('Validation error:', error);
    // Fall back to basic validation result
    return { isValid: !isRoleBased && !isSpamTrap && !isDisposable && !hasTypo };
  }
};

export const validateEmailBatch = async (
  emails: string[],
  onProgress?: (progress: ValidationProgress) => void
): Promise<BatchValidationResult> => {
  const result: BatchValidationResult = {
    valid: [],
    invalid: [],
    risky: [],
    disposable: [],
    spamTraps: [],
    totalEmails: emails.length
  };

  const BATCH_SIZE = 100;
  const batches = chunk(emails, BATCH_SIZE);
  const totalBatches = batches.length;
  let processedCount = 0;

  for (let batchIndex = 0; batchIndex < batches.length; batchIndex++) {
    const batch = batches[batchIndex];
    
    const batchResults = await Promise.all(
      batch.map(async (email) => {
        try {
          const validation = await validateEmail(email);
          processedCount++;

          if (onProgress) {
            onProgress({
              processed: processedCount,
              total: emails.length,
              currentBatch: batchIndex + 1,
              totalBatches
            });
          }

          const [localPart, domain] = email.toLowerCase().split('@');

          // More deterministic categorization
          if (SPAM_TRAP_PATTERNS.some(pattern => pattern.test(email))) {
            return { email, category: 'spamTraps' as const };
          } else if (disposableDomains.includes(domain) || BLACKLISTED_DOMAINS.has(domain)) {
            return { email, category: 'disposable' as const };
          } else if (ROLE_BASED_PREFIXES.has(localPart) || 
                    SUSPICIOUS_PATTERNS.LENGTH_CHECK.test(email) ||
                    !['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'].includes(domain)) {
            return { email, category: 'risky' as const };
          } else if (validation.isValid) {
            return { email, category: 'valid' as const };
          } else {
            return { email, category: 'invalid' as const };
          }
        } catch (error) {
          console.error(`Error validating email ${email}:`, error);
          return { email, category: 'invalid' as const };
        }
      })
    );

    batchResults.forEach(({ email, category }) => {
      if (category === 'valid') result.valid.push(email);
      else if (category === 'invalid') result.invalid.push(email);
      else if (category === 'risky') result.risky.push(email);
      else if (category === 'disposable') result.disposable.push(email);
      else if (category === 'spamTraps') result.spamTraps.push(email);
    });
  }

  return result;
};

export const validateEmailsWithRetry = async (
  emails: string[],
  maxRetries = 3,
  onProgress?: (progress: ValidationProgress) => void
): Promise<BatchValidationResult> => {
  let retries = 0;
  let error: Error | null = null;

  while (retries < maxRetries) {
    try {
      return await validateEmailBatch(emails, onProgress);
    } catch (err) {
      error = err as Error;
      retries++;
      await new Promise(resolve => setTimeout(resolve, 1000 * retries));
    }
  }

  throw error || new Error('Max retries exceeded');
};