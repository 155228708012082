import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';

export function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        // Get session from URL
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Auth callback error:', error);
          navigate('/login?error=Authentication failed');
          return;
        }

        if (!session) {
          navigate('/login?error=No session found');
          return;
        }

        // Check if user profile exists
        const { data: profile, error: profileError } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('id', session.user.id)
          .single();

        if (profileError && profileError.code !== 'PGRST116') { // PGRST116 is "not found"
          console.error('Profile fetch error:', profileError);
          navigate('/login?error=Profile fetch failed');
          return;
        }

        // If profile doesn't exist, create it
        if (!profile) {
          const { error: insertError } = await supabase
            .from('user_profiles')
            .insert({
              id: session.user.id,
              email: session.user.email,
              name: session.user.user_metadata?.full_name || session.user.email?.split('@')[0] || null,
              avatar_url: session.user.user_metadata?.avatar_url || null,
              credits: 0, // Set initial credits
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
            } as { id: string; email: string; name: string | null; avatar_url: string | null; credits: number; created_at: string; updated_at: string; });

          if (insertError) {
            console.error('Profile creation error:', insertError);
            navigate('/login?error=Profile creation failed');
            return;
          }
        }

        // Successful authentication, redirect to dashboard
        navigate('/', { replace: true });
      } catch (error) {
        console.error('Auth callback processing error:', error);
        navigate('/login?error=Authentication processing failed');
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Loader2 className="h-8 w-8 animate-spin" />
    </div>
  );
}
