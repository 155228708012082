import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';

export function AdminLoginForm() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Clear any existing sessions
      await supabase.auth.signOut();
      localStorage.clear();

      // Sign in attempt
      const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (authError) {
        throw new Error('Invalid credentials');
      }

      if (!authData.user || !authData.session) {
        throw new Error('No user data returned');
      }

      // Set the session immediately
      await supabase.auth.setSession({
        access_token: authData.session.access_token,
        refresh_token: authData.session.refresh_token,
      });

      // Verify admin status
      const { data: adminUser, error: adminError } = await supabase
        .from('admin_users')
        .select('role')
        .eq('id', authData.user.id)
        .single();

      if (adminError || !adminUser) {
        throw new Error('Unauthorized access');
      }

      if (!['super_admin', 'admin'].includes(adminUser.role)) {
        throw new Error('Insufficient privileges');
      }

      // Store admin session with timestamp and session info
      localStorage.setItem('adminSession', JSON.stringify({
        userId: authData.user.id,
        role: adminUser.role,
        timestamp: new Date().toISOString(),
        sessionId: authData.session.access_token,
        refreshToken: authData.session.refresh_token,
      }));

      toast({
        title: 'Login successful',
        description: 'Welcome to the admin dashboard',
      });

      // Small delay to ensure session is properly set
      setTimeout(() => {
        navigate('/admin', { replace: true });
      }, 100);

    } catch (error) {
      console.error('Login error:', error);
      
      // Clear any partial session data
      localStorage.removeItem('adminSession');
      await supabase.auth.signOut();

      toast({
        title: 'Login failed',
        description: error instanceof Error ? error.message : 'Invalid credentials or unauthorized access',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-full max-w-md">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl">Admin Login</CardTitle>
        <CardDescription>
          Enter your credentials to access the admin dashboard
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="admin@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="email"
              disabled={isLoading}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
              disabled={isLoading}
            />
          </div>
          <Button 
            className="w-full" 
            type="submit" 
            disabled={isLoading || !email || !password}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Signing in...
              </>
            ) : (
              'Sign in'
            )}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
} 